import WorkerSvg from "../InspectionFlags/icons/Worker";
import {
  Machine,
  MachineName,
  MachineCode,
  BottomInfo,
  Worker,
  WorkerName,
  Duration,
} from "../NavbarStyles";
import Active from "../InspectionFlags/icons/Active";

function MachineTile({ handleClick, machine }) {
  var className = machine.IsUrgent
    ? `${machine.EventState + "-U machine"}`
    : `${machine.EventState + " machine"}`;

  return (
    <Machine
      onClick={(event) => handleClick(event, machine)}
      key={machine.MachineId}
      className={className}
    >
      <Active />
      <MachineName>
        <MachineCode>{machine.MachineCode}</MachineCode>
        <p className="overWord">{machine.MachineName}</p>
      </MachineName>
      <BottomInfo>
        {machine.Worker !== null && (
          <Worker>
            <WorkerSvg />
            <WorkerName>{machine.Worker.FullName}</WorkerName>
          </Worker>
        )}
        <Duration>
          {machine.EventDuration}
          {machine.EventDuration !== 1 ? " days" : " day"}
        </Duration>
      </BottomInfo>
    </Machine>
  );
}

export default MachineTile;
