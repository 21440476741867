import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useSwipeable} from "react-swipeable";
// @ts-ignore
import btnLeft from '../../../../assets/icons/btn-left.svg';
// @ts-ignore
import btnRight from '../../../../assets/icons/btn-right.svg';
import {ArrowButton} from "../style/LogStyles.jsx"
import FullImageToolTip from "../../PostItem/components/FullImageToolTip";
import {LogContext} from "../../../../context/LogContext";
import {ColorRing} from "react-loader-spinner";
import ReactPlayer from 'react-player';
import {
    TransformWrapper,
    TransformComponent,
} from "react-zoom-pan-pinch";
import Drawer from "./SwipeableDrawer";
import FileType, {createFileUrl} from "../api/FileTypeEnum";
import {fileTypeEnum} from "../api/FileTypeEnum";
import { Document, Page } from 'react-pdf'
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import Button from "@mui/material/Button";
import {isMobile} from "react-device-detect";
const OpenFullImage = ({getAssociatedPost, imageData, onClose, loadNextImage, loadPreviousImage, getToolTipContent, scrollToPost}) => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
    const fullScreenRef = useRef(null);
    const swipeLeftRef = useRef(null);
    const swipeRightRef = useRef(null);
    const [isUIVisible, setIsUIVisible] = useState<boolean>(true);
    const [pdfPages, setPdfPages] = useState<number>(-1);
    const [pdfCurrentPage, setPdfCurrentPage] = useState<number>(-1);
    const mouseDidntMoveTimeout = useRef(undefined);
    const [event, setEvent] = useState<boolean>(false);
    const {isLoading, setIsLoading, id, getImageUrl, getVideoStreamUrl} = useContext(LogContext);
    const handlers = useSwipeable({
        onSwipedLeft: async () => await loadNextImage(),
        onSwipedRight: async () => await loadPreviousImage(),
        swipeDuration: 1000,
        preventScrollOnSwipe: true,
        trackMouse: true
    });
    const fileType = useMemo(() => {
        return fileTypeEnum(imageData.Content);
    }, [imageData.Content]);
    
    const [fileUrl, setFileUrl] = useState('');

    // Function to create an object URL from base64 image data
    const createObjectURLFromBase64 = (base64, type) => {
        try {
            const decodedData = window.atob(base64);
            const uInt8Array = new Uint8Array(decodedData.length);

            for (let i = 0; i < decodedData.length; ++i) {
                uInt8Array[i] = decodedData.charCodeAt(i);
            }

            const blob = new Blob([uInt8Array], { type });
            return URL.createObjectURL(blob);
        } catch (error) {
            console.error("Failed to decode base64 string:", error);
            return '';
        }
    };

    useEffect(() => {
        let fileUrl;
        if (imageData.ImageData) {
            fileUrl = createObjectURLFromBase64(imageData.ImageData, imageData.Content);
        } else {
            fileUrl = (fileType === FileType.Video) ? getVideoStreamUrl(imageData.Id) : getImageUrl(imageData.Id);
        }
        setFileUrl(fileUrl);
    }, [imageData]);
    
    const Image = useCallback(() => {

        return (
            <TransformWrapper
                wheel={{
                    step: 0.6
                }}
                pinch={{
                    step: 15
                }}
            >
                {({zoomIn, zoomOut, resetTransform, ...rest}) => (
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'end',
                            height: '100%',
                            margin: 'auto',
                        }}>
                            <TransformComponent wrapperStyle={{
                                margin: 'auto'
                            }} contentStyle={{
                                maxWidth: '550px'
                            }}>
                                    <img style={{
                                        maxWidth: '100%',
                                        height: 'auto',
                                    }} src={fileUrl}/>
                            </TransformComponent>
                        </div>
                )}
            </TransformWrapper>
        )
    }, [fileUrl]);

    const Video = useCallback(() => {
        return <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center', maxWidth: '550px', height: '100%'}}>
            <ReactPlayer
                style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center'}}
                url={fileUrl}
                type={imageData.Content}
                controls
                playing={true}
                width="85%"
                height="auto"
            />
        </div>

    }, [fileUrl]);

    const openPdfInNewTab = useCallback(() => {
        window.open(fileUrl, imageData.FileName);
    }, [fileUrl, imageData.FileName]);

    const onDocumentLoadSuccess = useCallback(({numPages}) => {
        setPdfPages(numPages);
        setPdfCurrentPage(1);
    }, [])

    const incrementPdfPage = useCallback(() => {
        const currentPage = pdfCurrentPage + 1;
        if (currentPage <= pdfPages)
            setPdfCurrentPage(currentPage);
    }, [pdfCurrentPage, pdfPages])

    const decrementPdfPage = useCallback(() => {
        const currentPage = pdfCurrentPage - 1;
        if (currentPage >= 1)
            setPdfCurrentPage(currentPage);
    }, [pdfCurrentPage]);

    const PdfControls = useCallback(() => {
        return <div style={{
            display: 'flex',
            flexDirection: 'row',
            width: '25vw',
            minWidth: '300px',
            justifyContent: 'space-around',
            alignItems: 'center',
            margin: '15px auto auto auto'
        }}>
            <Button sx={{backgroundColor: '#222529'}} variant='contained' onClick={() => decrementPdfPage()}>Previous
                page</Button>
            <Button sx={{backgroundColor: '#222529'}} variant='contained' onClick={() => incrementPdfPage()}>Next
                page</Button>
        </div>
    }, [decrementPdfPage, incrementPdfPage]);

    const PdfDocument = useCallback(() => {
        return <Document className={'mypdf-container-pages'} onClick={() => openPdfInNewTab()} file={fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
                {pdfPages && <Page scale={isMobile ? 0.6 : 1} pageNumber={pdfCurrentPage} />}
            </Document>
    }, [fileUrl, onDocumentLoadSuccess, openPdfInNewTab, pdfCurrentPage, pdfPages]);
    
    const Pdf = useCallback(() => {
        return <div style={{width: '75vw', height: '80vh', display: 'flex', flexDirection: 'column', alignContent: 'center'}}>
            <PdfDocument/>
            <PdfControls/>
        </div>
    }, [PdfControls, PdfDocument]);

    const handleMouseMove = useCallback((event) => {
        if (!isUIVisible) {
            setIsUIVisible(true);
            clearTimeout(mouseDidntMoveTimeout.current)
            mouseDidntMoveTimeout.current = setTimeout(() => {
                setIsUIVisible(false);
                clearTimeout(mouseDidntMoveTimeout.current)
                mouseDidntMoveTimeout.current = undefined
            }, 2500)
            return;
        }
        if (mouseDidntMoveTimeout)
        {
            clearTimeout(mouseDidntMoveTimeout.current);
            mouseDidntMoveTimeout.current = setTimeout(() => {
                setIsUIVisible(false);
                clearTimeout(mouseDidntMoveTimeout.current)
                mouseDidntMoveTimeout.current = undefined
            }, 2500);
        }
    }, [isUIVisible]);

    const handleEscapeKeyPress = useCallback((keyNumber: string) => {
        if (keyNumber === 'Escape') {
            onClose();
        }
    }, [onClose]);

    const handleLeftArrowPress = useCallback((keyNumber: string) => {
        if (keyNumber === 'ArrowLeft') {
            loadPreviousImage()
        }
    }, [loadPreviousImage]);

    const handleRightArrowPress = useCallback((keyNumber: string) => {
        if (keyNumber === 'ArrowRight') {
            loadNextImage()
        }
    }, [loadNextImage]);

    const BtnRight = useCallback(() => {
        return (
            <img src={btnRight} alt="Button Right"/>
        )
    }, [])

    const BtnLeft = useCallback(() => {
        return (
            <img src={btnLeft} alt="Button Left"/>
        )
    }, [])

    const RightButton = useCallback(() => {
            return <ArrowButton
                    className={`${isUIVisible ? "opacityVisible" : "opacityHidden"}`}
                    style={{alignSelf: 'end'}}
                    ref={swipeRightRef}
                    onClick={() => loadNextImage()}
                    tabIndex={-1}
                >
                    <BtnRight/>
                </ArrowButton>
    }, [BtnRight, isUIVisible, loadNextImage]);

    const LeftButton = useCallback(() => {
        return <ArrowButton
            className={`${isUIVisible ? "opacityVisible" : "opacityHidden"}`}
            style={{alignSelf: 'start'}}
            ref={swipeLeftRef}
            onClick={() => loadPreviousImage()}
            tabIndex={-1}
        >
            <BtnLeft/>
        </ArrowButton>
    }, [BtnLeft, isUIVisible, loadPreviousImage]);
    
    const HideableDrawer = useCallback(() => {
        return isUIVisible &&
            <Drawer scrollToPost={scrollToPost} key={id + 'fullImage'} isReadOnly={true} id={id} event={event}
                    setEvent={setEvent}/>
    }, [event, id, isUIVisible, scrollToPost]);

    useEffect(() => {
        if (mouseDidntMoveTimeout.current === undefined) {
            mouseDidntMoveTimeout.current = setTimeout(() => setIsUIVisible(false), 2500);
        }
    }, [mouseDidntMoveTimeout]);

    useEffect(() => {
        const eventKeysHandler = (event) => {
            handleRightArrowPress(event.key);
            handleLeftArrowPress(event.key);
            handleEscapeKeyPress(event.key);
        }

        const validateClickOutside = (event) => {
            const outsideFullScreenClick = fullScreenRef.current && !fullScreenRef.current.contains(event.target)
            const outsideSwipeLeftClick = swipeLeftRef.current && !swipeLeftRef.current.contains(event.target)
            const outsideSwipeRightClick = swipeRightRef.current && !swipeRightRef.current.contains(event.target)
            return outsideFullScreenClick && outsideSwipeLeftClick && outsideSwipeRightClick
        }

        const handleClickOutside = (event) => {
            if (validateClickOutside(event)) {
                onClose();
            }
        };

        document.addEventListener('keydown', eventKeysHandler);
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('mousemove', handleMouseMove)
        return () => {
            document.removeEventListener('keydown', eventKeysHandler);
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, [handleEscapeKeyPress, handleLeftArrowPress, handleRightArrowPress, onClose, loadNextImage, loadPreviousImage, handleMouseMove]);

    return (
        <div {...handlers}
             style={{
                 position: 'fixed',
                 top: 0,
                 left: 0,
                 width: '100%',
                 height: '100%',
                 backgroundColor: 'rgba(0, 0, 0, 0.8)',
                 display: 'flex',
                 alignItems: 'center',
                 justifyContent: 'start',
                 flexDirection: 'column',
                 zIndex: 10000,
                 overflow: 'hidden',
             }}
        >
            {!isMobile && <LeftButton/>}
            {!isMobile && <RightButton/>}
            {<div className={`${isUIVisible ? "opacityVisible" : "opacityHidden"} opacityTransition`}>
                <FullImageToolTip onClick={onClose} post={getAssociatedPost(imageData.Id)}
                                    getToolTipContent={getToolTipContent} onCloseClick={onClose}/>
            </div>}
            {!isLoading ? (
                <>
                    {fileType === FileType.Image && (
                        <Image/>
                    )}
                    {fileType === FileType.Video && (
                        <Video/>
                    )}
                    {fileType === FileType.Pdf && (
                        <Pdf/>
                    )}
                </>
            ) : (
                <ColorRing
                    visible={isLoading}
                    height="80"
                    width="80"
                    ariaLabel="color-ring-loading"
                    wrapperStyle={{}}
                    wrapperClass="color-ring-wrapper"
                    colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}/>
            )}
            <HideableDrawer/>
        </div>
    )
}

export default OpenFullImage;