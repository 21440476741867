import React,{useState} from "react";
import Draggable,{ DraggableEventHandler } from "react-draggable";
import { InputContainer } from "../style/LogStyles";
import { Icons } from "../icons/Icons";

interface Props {
  inputType: "comment" | "improvement" | "question";
  isReadOnly: boolean;
  setIsCommentVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setIsQuestionVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setIsImprovementVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const DraggableInput: React.FC<Props> = ({
  inputType,
  setIsCommentVisible,
  setIsQuestionVisible,
  setIsImprovementVisible,
  isReadOnly
}) => {
  const [dragged, setDragged] = useState(false);
  const onStop: DraggableEventHandler = (e, ui) => {
    setDragged(true);
    switch (inputType) {
      case "comment":
        setIsCommentVisible(true);
        break;
      case "improvement":
        setIsImprovementVisible(true);
        break;
      case "question":
        setIsQuestionVisible(true);
        break;
    }
  };



  return (
    <React.Fragment>
      {!dragged && (
        <Draggable onStop={onStop}>
          <InputContainer className={"draggable"} isReadOnly={isReadOnly}>
            <Icons
              value={inputType.toLowerCase()}
              height={30}
              width={35}
              color={"#ffffff"}
            />
          </InputContainer>
        </Draggable>
      )}
    </React.Fragment>
  );
};

export default DraggableInput;
