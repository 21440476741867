import axios, { AxiosResponse } from "axios";
import { AOO_API_HOST } from "../../../../env";
import {LogMachineData, PaginatedPost} from "../log.typing";
export const getDataById = async (id: string): Promise<PaginatedPost | undefined> => {
  try {
    const response = await axios.get(
      AOO_API_HOST + "/aoo-api/ProductionsLog/GetLogPost?PostId=" + id
    );
    return response.data as PaginatedPost;
  } catch (error) {
    console.error("Error fetching data:", error);
    return undefined;
  }
};

export const getLogById = async (id: string): Promise<PaginatedPost | undefined> => {
  try {
    const response = await axios.get(
      AOO_API_HOST + "/aoo-api/ProductionsLog/GetLogPost?PostId=" + id
    );
    return response.data as PaginatedPost;
  } catch (error) {
    console.error("Error fetching data:", error);
    return undefined;
  }
};

export const fetchMachineData = async (
  id: string
): Promise<LogMachineData | undefined> => {
  try {
    const response = await axios.get(
      AOO_API_HOST + "/aoo-api/ProductionsLog/LogMachineInfo?machineId=" + id
    );
    return response.data as LogMachineData;
  } catch (error) {
    console.error("Error fetching data:", error);
    return undefined;
  }
};
