import { MachineTab } from "./MachineTab";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { AOO_API_HOST } from "../../env";
import { IMachineMetrics } from "../../global.typing";
import {
  Title,
  Content,
  Container,
  Average,
  TitleMain,
  TabContainer,
  InnerContainer,
} from "./KPI_style";
const KpiContent = () => {
  const [data, setData] = useState<IMachineMetrics>({
    RedMetrics: [],
    CombinedMetrics: [],
  });

  const [average, setAverage] = useState<number>(0);
  const [aggregate, setAggregate] = useState<number>(0);

  useEffect(() => {
    const fetchData = async (url) => {
      try {
        const response = await axios.get(
          AOO_API_HOST + "/aoo-api/Productions/" + url
        );
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const fetchAverageData = async (url) => {
      try {
        const response = await axios.get(
          AOO_API_HOST + "/aoo-api/Productions/" + url
        );
        setAverage(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const fetchAggregateData = async (url) => {
      try {
        const response = await axios.get(
          AOO_API_HOST + "/aoo-api/Productions/" + url
        );
        setAggregate(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData("get-metrics");
    fetchAggregateData("AverageAvailability");
    fetchAverageData("GetMetricAverage");
  }, []);

  return (
    <Content>
      <TitleMain>5 Troublemakers </TitleMain>

      <InnerContainer>
        <Container>
          <Title>Availability</Title>
          <TabContainer>
            {data.RedMetrics.map((item: any, index: number) => (
              <MachineTab
                machine={item}
                type={"C"}
                isOrange={true}
                key={`${index}`}
              />
            ))}
          </TabContainer>
        </Container>
        <Container>
          <Title>Avg. Availability</Title>
          <Average>{aggregate}</Average>
        </Container>
        <Container>
          <Title>Avg. Repeat</Title>
          <Average>{average}</Average>
        </Container>
      </InnerContainer>
    </Content>
  );
};

export default KpiContent;
