import React, { useEffect, useState, useContext } from "react";
import { PostExpert } from "../log.typing";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { AOO_API_HOST } from "../../../../env";
import axios from "axios";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ActionButton, SimpleContainer } from "../style/LogStyles";
import { LogContext } from "../../../../context/LogContext";
import { useCookies } from "react-cookie";
import { Selected } from "../style/LogStyles";
export interface SimpleDialogProps {
  fetched: string;
  poster: PostExpert;
  setPoster: React.Dispatch<React.SetStateAction<PostExpert>>;
  isReadOnly: boolean;
}

export default function SimpleDialog(props: SimpleDialogProps) {
  const [users, setUsers] = useState<Array<PostExpert>>([]);
  const { anchorEl, setAnchorEl, setPoster, editMode } = useContext(LogContext);
  // const [cookies, setCookie] = useCookies();
  useEffect(() => {
    if ((editMode || props.isReadOnly) && props.fetched) {
      const findObjectById = (id) => {
        return users.find((obj) => obj.Id === id);
      };

      const foundObject = findObjectById(props.fetched);
      setPoster(foundObject);
    }
  }, [editMode, props.fetched]);


  useEffect(() => {
    const fetchData = async (url) => {
      try {
        const response = await axios.get(
          AOO_API_HOST + "/aoo-api/ProductionsLog/" + url
        );
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData("GetWorkers");
  }, []);

  const open = Boolean(anchorEl);
  const handleUserSelect = (event: React.MouseEvent<HTMLElement>) => {
    if (!props.isReadOnly)
      setAnchorEl(event.currentTarget);
  };

  const handleClick = (userName) => {
    setPoster(userName);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const ITEM_HEIGHT = 48;
  return (
    <SimpleContainer>
      <ActionButton
        type="button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleUserSelect}
      >
        {!props.poster && (
          <PersonAddIcon style={{ color: "white", fontSize: "35px" }} />
        )}

        {props.poster && (
          <div>
            <Selected style={{ padding: "0", margin: "0 10px 0 5px" }}>
              {props.poster?.Initials}
            </Selected>
          </div>
        )}
      </ActionButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          className: "scrollbar-hidden",
          style: {
            backgroundColor: "#222529",
            color: "white",
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        {users.map((option) => (
          <MenuItem   
            key={option.Id}
            onClick={() => {
              handleClick(option);
            }}
          >
            {option.FullName}
          </MenuItem>
        ))}
      </Menu>
    </SimpleContainer>
  );
}
