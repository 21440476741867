import React, {
  useContext,
  createContext,
  useCallback,
  useEffect,
  useState,
  useRef,
} from "react";
import {
  UrlObject,
  PostExpert,
  PostImage,
  PaginatedPost,
  PaginatedPostsParams,
} from "../Components/Widgets/Log/log.typing";
import axios, { CancelToken } from "axios";
import { AOO_API_HOST, CONTENT_API_HOST } from "../../src/env";
import { GlobalContext } from "./GlobalContext";
import { fabClasses } from "@mui/material";
export const initialPostData = {
  PostId: "",
  Symptoms: "",
  Observation: "",
  Hypothesis: "",
  Action: "",
  FollowUp: "",
  ImprovementProposition: "",
  Plan: "",
  Comment: "",
  Question: "",
  PartReplacement: false,
  Images: null,
  Poster: "",
  MachineStatusEventChange: "",
  PosterId: "",
  LogEntryId: "",
  Date: "",
  Time: "",
};
type ContextType = {
  reset: boolean; // Change 'any' to the appropriate type
  setReset: React.Dispatch<React.SetStateAction<boolean>>;
  poster: PostExpert; // Change 'any' to the appropriate type
  setPoster: React.Dispatch<React.SetStateAction<PostExpert>>;
  editMode: boolean; // Change 'any' to the appropriate type
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  alert: boolean; // Change 'any' to the appropriate type
  setAlert: React.Dispatch<React.SetStateAction<boolean>>;
  noUserSelect: boolean;
  backtoMenu: boolean;
  setBacktoMenu: React.Dispatch<React.SetStateAction<boolean>>;
  setNoUserSelect: React.Dispatch<React.SetStateAction<boolean>>;
  id: string; // Change 'any' to the appropriate type
  setId: React.Dispatch<React.SetStateAction<string>>;
  files: Array<File>; // Change 'any' to the appropriate type
  setFiles: React.Dispatch<React.SetStateAction<Array<File>>>;
  content: Array<PostImage>;
  setContent: React.Dispatch<React.SetStateAction<Array<PostImage>>>;
  deleted: Array<string>;
  setDeleted: React.Dispatch<React.SetStateAction<Array<string>>>;
  posts: Array<PaginatedPost>;
  setPosts: React.Dispatch<React.SetStateAction<Array<PaginatedPost>>>;
  postImages: Array<PostImage>;
  setPostsImages: React.Dispatch<React.SetStateAction<Array<PostImage>>>;
  postData: PaginatedPost;
  setPostData: React.Dispatch<React.SetStateAction<PaginatedPost>>;
  activePage: number;
  setActivePage: React.Dispatch<React.SetStateAction<number>>;
  totalPages: number;
  setTotalPages: React.Dispatch<React.SetStateAction<number>>;
  isLoading: boolean;
  PAGE_SIZE: React.MutableRefObject<number>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  anchorEl: null | HTMLElement;
  selectedImageData: any;
  setSelectedImageData: React.Dispatch<React.SetStateAction<any>>;
  setAnchorEl: React.Dispatch<React.SetStateAction<null | HTMLElement>>;
  addMoreFiles: (
    e: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>
  ) => void;
  fileToPostImage: (file: File) => PostImage;
  fetchData: (url: string, param: PaginatedPostsParams) => Promise<void>;
  getImageThumbnailUrl: (fileId: any) => any;
  getImageUrl: (fileId: any) => any;
  getVideoStreamUrl: (fileId: string) => string;
};

const LogContext = createContext<ContextType | undefined>(undefined);

const LogProvider = ({ children }) => {
  const PAGE_SIZE = useRef(5);
  const [poster, setPoster] = useState<PostExpert>();
  const [reset, setReset] = useState<boolean>(false);
  const [noUserSelect, setNoUserSelect] = useState<boolean>(false);
  const [backtoMenu, setBacktoMenu] = useState<boolean>(false);
  const [postData, setPostData] = useState<PaginatedPost>(initialPostData);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [alert, setAlert] = useState<boolean>(false);
  const [id, setId] = useState<string>("");
  const [files, setFiles] = useState<Array<File>>([]);
  const [deleted, setDeleted] = useState<Array<string>>([]);
  const [content, setContent] = useState<Array<PostImage>>([]);
  const [posts, setPosts] = useState<Array<PaginatedPost>>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [postImages, setPostsImages] = useState<Array<PostImage>>();
  const [activePage, setActivePage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchPostsParams, setFetchPostsParams] = useState<{ url: string, param: PaginatedPostsParams } | null>(null);
  const [selectedImageData, setSelectedImageData] = useState(null);
  const cancelTokenRef = useRef(null);

  const fetchData = useCallback(async (url: string, param: PaginatedPostsParams) => {
    if (param.MachineId && param.MachineId.length > 0) {
      setFetchPostsParams({ url, param });
    }
  }, []);

  useEffect(() => {
    if (fetchPostsParams) {
      setIsLoading(true);
      const fetchPosts = async () => {
        try {
          // Check if there is an ongoing request and cancel it
          if (cancelTokenRef.current) {
            cancelTokenRef.current.cancel("New request initiated");
          }
          // Create a new cancel token for the current request
          cancelTokenRef.current = axios.CancelToken.source();

          const response = await axios.get(
            AOO_API_HOST + "/aoo-api/ProductionsLog/" + fetchPostsParams.url,
            {
              params: fetchPostsParams.param,
              cancelToken: cancelTokenRef.current.token,
            }
          );

          if (response.data && Array.isArray(response.data.Result)) {
            const newItems = response.data.Result;

            if (newItems.length > 0) {
              setPosts((prevItems) => [...newItems.reverse(), ...prevItems]);
              setTotalPages(response.data.TotalPages);
              setActivePage(response.data.PageNumber);
            }
          } else {
            setPosts([]);
            setTotalPages(0);
            setActivePage(1);
          }
        } catch (error) {
          if (axios.isCancel(error)) {
            console.log("Request cancelled:", error.message);
          } else {
            // Handle other errors
            console.error("Error fetching data:", error);
          }
        } finally {
          setIsLoading(false);
          setFetchPostsParams(null);
        }
      }
      fetchPosts()
    }
  }, [fetchPostsParams]);

  useEffect(() => {
    setPostsImages(
      posts
        ?.filter((post) => post.Images?.length > 0)
        .flatMap((post) => post?.Images) ?? []
    );
  }, [posts]);

  // get file from Content-delivery service. 0.2 - scale param to get low-quality image (if a file is not image - return full file)
  const getImageThumbnailUrl = (fileId) => {
    return CONTENT_API_HOST + "/api/Download/GetMachineLogFile/" + fileId + "?height=100"
  }

  const getImageUrl = (fileId) => {
    return CONTENT_API_HOST + "/api/Download/GetMachineLogFile/" + fileId
  }

  const getVideoStreamUrl = (fileId) => {
    return CONTENT_API_HOST + "/api/Stream/MachineLog/" + fileId
  }

  const addMoreFiles = (
    e: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>
  ) => {
    let newFiles: FileList | null;
    if ("dataTransfer" in e) {
      newFiles = e.dataTransfer.files;
    } else {
      newFiles = e.target.files;
    }

    if (newFiles) {
      for (let i = 0; i < newFiles.length; i++) {
        setFiles((prevFiles) => [...prevFiles, ...newFiles]);
        setContent((prevFiles) => [...prevFiles, fileToPostImage(newFiles[i])]);
      }
    }
  };

  const fileToPostImage = (file: File): PostImage => {
    const reader = new FileReader();

    const postImage: PostImage = {
      Id: "",
      FileName: file.name,
      ImageData: new ArrayBuffer(0), // Placeholder, will be replaced after reading
      Content: file.type,
    };

    reader.onload = () => {
      if (reader.result instanceof ArrayBuffer) {
        postImage.ImageData = reader.result;
      }
    };

    reader.readAsArrayBuffer(file);

    return postImage;
  };

  return (
    <LogContext.Provider
      value={{
        backtoMenu,
        setBacktoMenu,
        poster,
        setPoster,
        postData,
        setPostData,
        reset,
        setReset,
        editMode,
        setEditMode,
        alert,
        setAlert,
        id,
        setId,
        files,
        setFiles,
        addMoreFiles,
        fileToPostImage,
        content,
        setContent,
        deleted,
        setDeleted,
        posts,
        setPosts,
        activePage,
        setActivePage,
        totalPages,
        setTotalPages,
        anchorEl,
        setAnchorEl,
        isLoading,
        setIsLoading,
        PAGE_SIZE,
        fetchData,
        noUserSelect,
        setNoUserSelect,
        postImages,
        setPostsImages,
        selectedImageData,
        setSelectedImageData,
        getImageThumbnailUrl,
        getImageUrl,
        getVideoStreamUrl,
      }}
    >
      {children}
    </LogContext.Provider>
  );
};

export { LogContext, LogProvider };
