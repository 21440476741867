import React, { useState, useContext } from "react";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { LogContext } from "../../../../context/LogContext";
import { Icons } from "../icons/Icons";
import { Menu, MenuMain } from "../style/HelperStyles";
import { isBrowser } from "react-device-detect";
interface MenuProps {
  id: string;
  isMouseOver: boolean;
  menu: boolean;
  setMenu: React.Dispatch<React.SetStateAction<boolean>>;
  onButtonClick: () => void;
}

const BasicMenu: React.FC<MenuProps> = ({
  id,
  isMouseOver,
  onButtonClick,
  menu,
  setMenu,
}) => {
  const { setAlert, setId, setEditMode,setNoUserSelect } = useContext(LogContext);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setMenu(true);
  };

  const handleEdit = (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    onButtonClick();
    setId(id);
    setEditMode(true);
    setAnchorEl(null);
  };


  const handleDelete = () => {
    setId(id);
    setAlert(true);
    setAnchorEl(null);
  };
  const handleChange = () => {
    setId(id);
    setEditMode(true);

    setNoUserSelect(true);
  };

  return (
    <MenuMain>
      {isMouseOver && !menu && (
        <IconButton
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <MoreVertIcon style={{ color: "white", fontSize: "35px" }} />
        </IconButton>
      )}
      {menu && (
        <Menu>
          <button onClick={handleChange}>
            <Icons value={"chUser"} height={20} width={25} color={"#ffffff"} />
          </button>
          <button onClick={(e) => handleEdit(e)}>
            <Icons value={"edit"} height={20} width={25} color={"#ffffff"} />
          </button>
          <button onClick={handleDelete}>
            <Icons value={"delete"} height={20} width={25} color={"#ffffff"} />
          </button>
        </Menu>
      )}
    </MenuMain>
  );
};

export default BasicMenu;
