import { ImageContainer, PictureSmall, ButtonPlus } from "../style/LogStyles";
import React, { useState, useEffect, useContext } from "react";
import { LogContext } from "../../../../context/LogContext";
import PostImageItem from "../../PostItem/components/PostImageItem";

const Image = ({ file, removeFileByName, isReadOnly }) => {

  const [fileType, setFileType] = useState(null);

  const handleFileTypeChange = (type) => {
    setFileType(type);
  };
  
  return (
    <PictureSmall fileType={fileType}>
      <PostImageItem postImage={file} onFileTypeChange={handleFileTypeChange}/>
       {!isReadOnly && <span className="removeFileButton" onClick={() => removeFileByName(file.FileName)}>&#10006;</span>}
   </PictureSmall>
  );
};
export const ImgContainer = ({content, removeFileByName, isReadOnly }) => {
  
  const { addMoreFiles } = useContext(LogContext);
  return (
    <ImageContainer>
      {content.map((file, index) => (
        <Image
          file={file}
          isReadOnly={isReadOnly}
          key={index}
          removeFileByName={removeFileByName}
        />
      ))}
      {content.length < 6 && !isReadOnly && (
        <ButtonPlus type="file" htmlFor="addmore">
          +
        </ButtonPlus>
      )}
      <input
        type="file"
        name="addmore"
        accept="image/*"
        id="addmore"
        multiple
        style={{ display: "none" }}
        onChange={addMoreFiles}
      />
    </ImageContainer>
  );
};
