import React, {useState, useContext, useCallback} from "react";
import { GlobalContext } from "../../context/GlobalContext";
import Workers from "./Options/Workers";
import Status from "./Options/Status";
import Reason from "./Options/Reason";
import {
  Content,
  MenuList,
  CloseButton,
  NavLinkMain,
  NavLink,
} from "./MobileOptionsStyles";

const MobileOptions = ({ status, setInput, machine, setRemove, setCover }) => {
  const { setIsActive, setLog, setEventOption } = useContext(GlobalContext);

  const closeMenu = useCallback(() => {
    document.documentElement.style.overflow = "";
    document.body.scroll = "yes";
    setIsActive((current) => !current);
  }, [setIsActive]);
  const title =
    machine.DaysToFix != null ? "Edit days to fix" : "Set days to fix";

  const [nav, setNav] = useState(true);
  const [content, setContent] = useState("");
  const [days, setDays] = useState(false);
  return (
    <Content>
      <CloseButton onClick={closeMenu}>
        <span></span>
      </CloseButton>

      {nav === true && (
        <MenuList>
          {(status.color === "R" || status.color === "Y") && (
            <NavLinkMain
              onClick={() => {
                setEventOption({ color: "G", isUrgent: true });
                setLog(true);
                setIsActive(false);
              }}
            >
              Mark as fixed
            </NavLinkMain>
          )}
          <NavLinkMain
            onClick={(e) => {
              setContent("worker");
              setNav(false);
            }}
          >
            Assign a worker
          </NavLinkMain>
          <NavLinkMain
            onClick={(e) => {
              setContent("status");
              setNav(false);
            }}
          >
            Change event status
          </NavLinkMain>
          <NavLinkMain
            onClick={(e) => {
              setContent("reason");
              setNav(false);
            }}
          >
            Assign a reason
          </NavLinkMain>

          <NavLinkMain
            onClick={(e) => {
              setCover((current) => !current);
              closeMenu();
            }}
          >
            Add cover picture
          </NavLinkMain>

          <NavLinkMain
            onClick={() => {
              closeMenu();
              setLog(true);
            }}
          >
            Log
          </NavLinkMain>
          <NavLinkMain
            onClick={(e) => {
              setDays((current) => !current);
            }}
          >
            Days to fix
            <span style={{ marginLeft: "20px", position: "absolute" }}>
              {days ? "-" : "+"}
            </span>
          </NavLinkMain>
          {days && (
            <li>
              {machine.DaysToFix != null && (
                <NavLink
                  onClick={() => {
                    setRemove(true);
                    closeMenu();
                  }}
                >
                  Remove days
                </NavLink>
              )}
              <NavLink
                onClick={(e) => {
                  closeMenu();
                  setInput(true);
                }}
              >
                {title}
              </NavLink>
            </li>
          )}
        </MenuList>
      )}

      {nav === false && (
        <div style={{ width: "100%", padding: "200px 0px", height: "100%" }}>
          <NavLinkMain
            onClick={(e) => {
              setContent("");
              setNav(true);
            }}
          >
            Back to menu
          </NavLinkMain>
          {content === "worker" && <Workers id={machine.Id} />}
          {content === "status" && (
            <Status id={machine.Id} status={status} machine={machine} />
          )}
          {content === "reason" && <Reason id={machine.Id} />}
        </div>
      )}
    </Content>
  );
}

export default MobileOptions;
