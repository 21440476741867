import React, { useContext, useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { GlobalContext } from "../../../context/GlobalContext";
import { LogContext } from "../../../context/LogContext";
import DisplayIcon from "../FailureIcon";
import WorkerSvg from "../../Navbar/InspectionFlags/icons/Worker";
import { CONTENT_API_HOST } from "../../../../src/env";
import {
  WorkerMain,
  Widget,
  Name,
  WidgetInfo,
  NumberOfDays,
  NameDays,
  CodeSmall,
  CountOfEntries,
  SmallName,
  MachineCode,
  MachineName,
  Worker,
  NameBlock,
  ReasonIcon,
  ToolTip,
} from "./MachineStyles";

function Machine({ machine, background }) {
  const [hasPicture, setHasPicture] = useState(false);
  const [img, setImg] = useState();
  const [repeat, setRepeat] = useState(true);
  const {
    setMachine,
    setGlobalStatus,
    setGlobalPoints,
    setShow,
    setIsActive,
    setMachineId,
  } = useContext(GlobalContext);

  const handleMenu = (machine, e) => {
    e.preventDefault();
    if (
      document.querySelector(".open-sub-menu") !== null &&
      document.querySelector(".open-sub-menu") !== undefined
    ) {
      document
        .querySelector(".open-sub-menu")
        .classList.remove("open-sub-menu");
    }
    setShow(true);
    setGlobalPoints({ x: e.pageX, y: e.pageY });
    setGlobalStatus({ color: machine.EventState, isUrgent: machine.IsUrgent });
    setMachine(machine);
    setMachineId(machine.Id);
    if (isMobile) {
      document.documentElement.style.overflow = "hidden";
      document.body.scroll = "no";
    }
  };

  const MachineMenu = (machine) => {
    if (true) {
      setIsActive((current) => !current);
      setGlobalStatus({
        color: machine.EventState,
        isUrgent: machine.IsUrgent,
      });
      document.documentElement.style.overflow = "hidden";
      document.body.scroll = "no";
      setMachine(machine);
      setMachineId(machine.Id);
    }
  };

  useEffect(() => {
    if (machine.Cover !== undefined && machine.Cover !== null) {
      setRepeat(false);
      setHasPicture(true);
      if(machine.Cover.ImageData){
        setImg(`data:${machine.Cover.Content};base64,${machine.Cover.ImageData}`);
      }
      else
      { // URL got not from LogContext because I counldn't use it in JS file
        setImg(CONTENT_API_HOST + "/api/Download/GetMachineLogFile/" + machine.Cover.ContentId)
      }
      //setImg(`data:${machine.Cover.Content};base64,${machine.Cover.ImageData}`);
    } else {
      setHasPicture(false);
      setRepeat(true);
      setImg(null);
    }
  }, [machine]);

  const GetTabColor = (background) => {
    const isMachineUrgent =
      machine.IsUrgent === true || machine.IsUrgent === null;

    const isEventState = machine.EventState;
    
    const gradientColors = {
      Y: !isMachineUrgent
        ? "linear-gradient(87deg, #F0ECAE 0%, #F0ECAE 28%, rgba(255,253,199,1) 45%, rgba(250,248,202,1) 49%, rgba(255,255,255,0) 65%);"
        : "linear-gradient(87deg, #f2d846 0%, #f2d846 28%, rgba(255,247,126,1) 49%, rgba(236,244,249,0) 60%, rgba(255,255,255,0) 100%)",
      R: !isMachineUrgent
        ? "linear-gradient(87deg, #F0CCDB 0%, #F0CCDB 28%,  rgba(245,215,240,1) 49%, rgba(255,255,255,0) 65%);"
        : "linear-gradient(87deg, #DD8CAE 25%, #DD8CAE 39%, rgba(242,193,234,1) 49%, rgba(236,244,249,0) 60%, rgba(255,255,255,0) 100%);",
      B: !isMachineUrgent
        ? "linear-gradient(87deg,  #9DBEF4 0%, #9DBEF4 28%, rgba(197,229,254,1) 37%, rgba(197,229,254,1) 49%, rgba(255,255,255,0) 65%);"
        : "linear-gradient(87deg, #599eff 25%, #599eff 39%, rgba(225,237,246,1) 49%, rgba(236,244,249,0) 60%, rgba(255,255,255,0) 100%)",
    };

    const colors = {
      Y: !isMachineUrgent ? "#F0ECAE" : background,
      R: !isMachineUrgent ? "#F0CCDB" : background,
      B: !isMachineUrgent ? "#9DBEF4" : background,
    };

    if (hasPicture) {
      return gradientColors[isEventState];
    }
    return colors[isEventState];
  };

  function fixDays() {
    if (machine.DaysToFix != null) {
      return `${machine.DaysToFix + 1} dtf`;
    }
    return null;
  }

  let days = fixDays();
  function NameTrim(name) {
    if (
      (window.innerWidth < 560) &
      (machine.MachineReason != null) &
      (machine.Name.length > 15)
    ) {
      return name.substring(0, 15) + "...";
    } else {
      return name;
    }
  }
  const borderStyle = !repeat
    ? {
        borderImage:
          "linear-gradient(to right, #000 50%, transparent 50%) 100% 1",
      }
    : {};
  return (
    <Widget
      background={GetTabColor(background)}
      className="card-has-bg"
      style={{ backgroundImage: `url(${img})` }}
      // src={"./1.PNG"}
      onContextMenu={(e) => {
        handleMenu(machine, e);
      }}
      onDoubleClick={(e) => {
        MachineMenu(machine);
      }}
    >
      <div className="card-img-overlay">
        <MachineName style={borderStyle}>
          <NameBlock>
            <MachineCode>{machine.MachineCode}</MachineCode>
            {machine.Name.length > 25 ? (
              <SmallName>{NameTrim(machine.Name)}</SmallName>
            ) : (
              <Name>{machine.Name}</Name>
            )}
          </NameBlock>
          {repeat && (
            <ReasonIcon>
              {window.innerWidth < 560 ? (
                <DisplayIcon
                  value={machine.MachineReason}
                  height={35}
                  width={30}
                  color={"#363636"}
                />
              ) : (
                <DisplayIcon
                  value={machine.MachineReason}
                  height={45}
                  width={40}
                  color={"#363636"}
                />
              )}
              {machine.MachineReason != null ? (
                <ToolTip>{machine.MachineReason}</ToolTip>
              ) : (
                <></>
              )}
            </ReasonIcon>
          )}
        </MachineName>
        <WidgetInfo space={repeat ? "space-between" : "flex-start"}>
          <NumberOfDays margin={!repeat ? "20px" : "0px"}>
            <NameDays>{machine.EventDuration}</NameDays>
            {machine.EventDuration !== 1 ? " days" : " day"}
          </NumberOfDays>
          <div>
            <WorkerMain>
              {(() => {
                if (days != null) {
                  if (machine.DaysToFix <= 0)
                    return <CodeSmall color={"red"}>{days}</CodeSmall>;
                  else return <CodeSmall color={"#363636"}>{days}</CodeSmall>;
                }
              })()}

              {machine.Workers.length > 0 && (
                <Worker>
                  <WorkerSvg />
                  {machine?.Workers[0].Initials}
                </Worker>
              )}
            </WorkerMain>
          </div>
          {repeat && (
            <CountOfEntries>{machine.EventsCount + "x R"}</CountOfEntries>
          )}
          {!repeat && (
            <CountOfEntries style={{ marginLeft: "10px" }}>
              {machine.EventsCount + "x"}
            </CountOfEntries>
          )}
        </WidgetInfo>
      </div>
    </Widget>
  );
}

export default Machine;
