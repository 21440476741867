import React from 'react';
import {FullImagePostToolTipWrapper} from "./PostItemStyles/FullImageToolTipStyled";
import {PaginatedPost} from "../../Log/log.typing";
import {isVisible} from "@testing-library/user-event/dist/utils";

type FullImageToolTipProps = {
    post: PaginatedPost,
    getToolTipContent: (post: PaginatedPost, onCloseClick: () => any) => {},
    onClick: () => any,
    onCloseClick: () => any,
}


const FullImageToolTip: React.FC<FullImageToolTipProps> = ({
                                                               post,
                                                               getToolTipContent,
                                                               onClick,
                                                               onCloseClick,
                                                           }): React.ReactElement => {

    return (
        <FullImagePostToolTipWrapper onClick={() => onClick()}>
            {getToolTipContent(post, onCloseClick)}
        </FullImagePostToolTipWrapper>
    );
}

export default FullImageToolTip;