import React from "react";
import styled, {keyframes} from "styled-components";
export const FullImagePostToolTipWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 40vh;
    min-width: 375px;
    max-width: 700px;
    align-items: center;
    height: auto;
    min-height: 75px;
    margin-bottom: 15px;
    margin-top: 5px;
    z-index: 10000;
`