import React, {  useContext } from "react";
import { GlobalContext } from "../../../context/GlobalContext";
import Dialog from "@mui/material/Dialog";
import Uploader from "./Uploader";

import {
  Title,
  CloseButton,
  Div,
} from "./UploaderStyles";

const CoverUploader = ({ setCover, remove }) => {

  const {machine} = useContext(GlobalContext);

  return (
    <Dialog open={remove} maxWidth={"lg"}>
      <Div>
        <CloseButton
          onClick={(e) => {
            setCover(false);
          }}
        >
          &#10006;
        </CloseButton>
        <Title>Please upload cover picture for :</Title>
        <Title>
          <span>[{machine.MachineCode}]</span> {machine.Name}
        </Title>
        <Uploader setCover={setCover} />
      
      </Div>
    </Dialog>
  );
};

export default CoverUploader;
