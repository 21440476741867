import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { postAction } from "../../../Widgets/WidgetActions";

import {
  WorkerFamily,
  WorkerW,
  Title,
  Confirm,
  Machine,
  Confirmation,
  OKConfirm,
  MachineName,
  MachineCode,
  BottomInfo,
  MultiSelect,
  FlagCount,
  WorkerFullName,
  Phone,
  NavLink,
  Duration,
} from "../../NavbarStyles";
import { AOO_API_HOST } from "../../../../env";

function Result() {
  let navigate = useNavigate();
  const { state } = useLocation();
  // const [result, setResult] = useState(state);
  const [scrollHeight, setScroll] = useState(0);
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    if (height > 600) {
      setScroll(45);
    } else {
      setScroll(2);
    }
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  },[height]);

  const updateWidthAndHeight = () => {
    setHeight(window.innerHeight);
  };

  let saveResult = () => {
    let url;
    if (state.type === "W") {
      url = AOO_API_HOST + `/aoo-api/Productions/post-new-flag`;
    } else {
      url = AOO_API_HOST + `/aoo-api/Productions/post-machine-inspector`;
    }
    const data = {
      MachinesId: state.machine.map((item) => item.id),
      WorkerId: state.worker.WorkerId,
    };
    postAction(url, data);
  };

  let confirmChoice = (e) => {
    saveResult();
    navigate("/main");
  };

  // WorkerType: state.type

  var prevstate = {
    group: state.group,
    machine: state.machine,
    type: state.type,
  };
  return (
    <WorkerFamily>
      <MultiSelect>
        <NavLink
          onClick={() => {
            navigate("/main/setflag/worker", { state: prevstate });
          }}
        >
          Back to workers
        </NavLink>
      </MultiSelect>
      <Title>Confirm your choice </Title>
      <div
        style={{
          maxHeight: scrollHeight + "vh",
          overflowY: "scroll",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <WorkerW>
          <WorkerFullName>
            {state.worker.FullName}
            <FlagCount>{state.worker.FlagsCount}</FlagCount>
          </WorkerFullName>
          <BottomInfo>
            <Phone> {state.worker.PhoneNumber}</Phone>
          </BottomInfo>
        </WorkerW>

        {state.machine.map((machine, key) => (
          <Machine key={machine.id} className={machine.state}>
            <MachineName>
              <MachineCode>{machine.code}</MachineCode>
              <p className="overWord">{machine.machineName}</p>
            </MachineName>
            <BottomInfo>
              <Duration>
                {machine.duration}
                {machine.EventDuration !== 1 ? " days" : " day"}
              </Duration>
            </BottomInfo>
          </Machine>
        ))}
      </div>

      <div style={{}}>
        <Confirmation>
          Сlick
          <OKConfirm onClick={(event) => confirmChoice(event)}>OK</OKConfirm> to
          confirm your selection, or Press Back to correct it.
        </Confirmation>

        <Confirm onClick={(event) => confirmChoice(event)}>Ok</Confirm>
      </div>
    </WorkerFamily>
  );
}

export default Result;
