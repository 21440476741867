import React from "react";


function Active() {
    return (
        <svg className="icon-select" width="55" height="54" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <ellipse cx="24.4999" cy="23.7159" rx="19.6" ry="19.796" fill="#227CD1"/>
        <path d="M13.5487 21.1045C13.8726 20.7806 14.3118 20.5987 14.7699 20.5987C15.2279 20.5987 15.6671 20.7806 15.991 21.1045L22.0986 27.212L34.3102 14.9986C34.4706 14.8381 34.661 14.7108 34.8706 14.6239C35.0801 14.537 35.3048 14.4923 35.5317 14.4922C35.7585 14.4921 35.9832 14.5367 36.1928 14.6235C36.4025 14.7102 36.5929 14.8374 36.7534 14.9978C36.9139 15.1581 37.0412 15.3485 37.1281 15.5581C37.215 15.7677 37.2598 15.9923 37.2599 16.2192C37.2599 16.446 37.2153 16.6707 37.1286 16.8803C37.0418 17.09 36.9147 17.2805 36.7543 17.441L22.0986 32.0967L13.5487 23.5468C13.2249 23.2229 13.043 22.7836 13.043 22.3256C13.043 21.8676 13.2249 21.4284 13.5487 21.1045Z" fill="white"/>
        </svg>
        
    )
}

export default Active;