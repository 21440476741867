import React from "react";

function RightCompleted() {
  return (
    <svg
      width="55"
      height="55"
      viewBox="0 0 55 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.75 27.5001C13.75 28.1079 13.9915 28.6908 14.4213 29.1206C14.851 29.5503 15.4339 29.7918 16.0417 29.7918H42.5907L39.6298 32.7549C39.2353 33.1495 38.9982 33.6745 38.9632 34.2315C38.9282 34.7884 39.0976 35.339 39.4396 35.7799L39.6298 35.9953C40.0244 36.3899 40.5495 36.6269 41.1064 36.6619C41.6633 36.697 42.2139 36.5276 42.6548 36.1855L42.8703 35.9953L49.7453 29.1203C50.1398 28.7257 50.3769 28.2007 50.4119 27.6438C50.4469 27.0868 50.2775 26.5362 49.9355 26.0953L49.7453 25.8799L42.8703 19.0049C42.4578 18.5939 41.9045 18.3553 41.3225 18.3375C40.7405 18.3197 40.1736 18.5241 39.7369 18.9092C39.3002 19.2943 39.0264 19.8312 38.9712 20.4108C38.916 20.9904 39.0835 21.5693 39.4396 22.0299L39.6298 22.2453L42.5907 25.2084H16.0417C15.4339 25.2084 14.851 25.4499 14.4213 25.8797C13.9915 26.3094 13.75 26.8923 13.75 27.5001ZM4.58338 27.5001C4.58165 30.1339 5.48732 32.6879 7.14794 34.7322C8.80856 36.7765 11.1227 38.1863 13.701 38.7243C16.2792 39.2623 18.9641 38.8957 21.3038 37.6862C23.6434 36.4767 25.4949 34.4981 26.5467 32.0834H22.6234C21.6476 33.4848 20.2501 34.5381 18.6342 35.0902C17.0183 35.6423 15.2684 35.6642 13.6392 35.1529C12.0099 34.6415 10.5864 33.6236 9.57581 32.2471C8.5652 30.8707 8.02023 29.2077 8.02023 27.5001C8.02023 25.7925 8.5652 24.1295 9.57581 22.7531C10.5864 21.3767 12.0099 20.3587 13.6392 19.8474C15.2684 19.336 17.0183 19.358 18.6342 19.91C20.2501 20.4621 21.6476 21.5154 22.6234 22.9168H26.5467C25.4931 20.5044 23.6412 18.5282 21.3022 17.3204C18.9632 16.1127 16.2797 15.7469 13.7028 16.2847C11.1259 16.8224 8.81267 18.2309 7.15192 20.2733C5.49118 22.3158 4.58418 24.8677 4.58338 27.5001Z"
        fill="#11AE3B"
      />
    </svg>
  );
}

export default RightCompleted;
