import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import MachineTile from "../Components/Machine";
import SearchBar from "../Components/SearchBar";

import {
  Title,
  MachineFamily,
  Content,
  NavLink,
  MultiSelect,
} from "./../../Navbar/NavbarStyles";
import { AOO_API_HOST } from "../../../env";

function MachineOption() {
  let navigate = useNavigate();
  let { state } = useLocation();
  let [groupId, setGroup] = useState(state);
  let [result, setResult] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setLoading] = useState(false);
  let url = AOO_API_HOST;
  if ((groupId === " ") || (groupId === undefined)) {
    url += "/aoo-api/Productions/all-machines";
  } else {
    url += "/aoo-api/Productions/group-machines?groupId=" + groupId;
  }

  useEffect(() => {
    if (groupId !== state.group) {
      setGroup(state.group);
      setLoading(false);
    }
    if (!isLoading) {
      axios.get(url).then((response) => {
        setResult(response.data);
        setSearchResults(response.data);
      });
      setLoading(true);
    }
  }, [state, isLoading]);

  let handleClick = (e, machine) => {
    navigate("/main/remote/final", {
      state: { machine: machine, group: state },
    });
  };
  return (
    <MachineFamily>
      <MultiSelect>
        <NavLink
          style={{ borderBottom: "none" }}
          onClick={() => {
            navigate("/main/remote/group");
          }}
        >
          Back to groups
        </NavLink>
      </MultiSelect>
      <Title>
        <SearchBar machines={result} setSearchResults={setSearchResults} />
      </Title>
      <Title>Select machine</Title>

      <Content style={{ width: "100%" }}>
        {searchResults.map((machine, key) => (

      <MachineTile handleClick={handleClick} machine={machine}  key={machine.MachineId}/>
        ))}
      </Content>
    </MachineFamily>
  );
}

export default MachineOption;
