import React, { useEffect, useState } from "react";
import axios from "axios";
import useSWR from "swr";
import { AOO_API_HOST } from "../../../env";
import { NavLink, MenuSubList } from "../MobileOptionsStyles";
import { postAction } from "../../Widgets/WidgetActions";
import Inspection from "../../Navbar/InspectionFlags/Components/Inspection";
export default function Workers({ id }) {
  const [data, setData] = useState();
  const [isChecked, setIsChecked] = useState(false);

  const fetcher = (url) =>
    axios
      .request({
        url: url,
        method: "get",
        baseURL: AOO_API_HOST,
        responseType: "json",
      })
      .then((res) => setData(res.data));

  const { result } = useSWR("/aoo-api/Productions/get-workers", fetcher, {
    refreshInterval: 10000,
  });

  function urlSetter(isChecked) {
    return isChecked
      ? `/aoo-api/Productions/post-machine-inspector`
      : `/aoo-api/Productions/post-new-flag`
  }
  useEffect(() => {
    if (result !== undefined) {
      setData(data);
    }
  }, [result, data]);
  return (
    <MenuSubList>
      <Inspection isChecked={isChecked} setIsChecked={setIsChecked} />
      {data === undefined
        ? null
        : data.map((worker, key) => (
            <li key={worker.WorkerId}>
              <NavLink
                onClick={() => {
                  postAction(
                    AOO_API_HOST + urlSetter(isChecked),
                    {
                      MachinesId: [id],
                      WorkerId: worker.WorkerId,
                    }
                  );
                }}
              >
                {worker.FullName}
              </NavLink>
            </li>
          ))}
    </MenuSubList>
  );
}
