import React, {useEffect, useMemo} from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { postAction } from "../WidgetActions";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

import DialogTitle from "@mui/material/DialogTitle";
import { AOO_API_HOST } from "../../../env";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default function FormDialog({ input, handleClose, machine }) {
  const [checked, setChecked] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [text, setText] = React.useState("");

  let label = checked ? "Turn off" : "Turn on";
  const title =
      useMemo(() => {
        return machine?.DaysToFix != null ? "Edit days to fix" : "Set days to fix";
      }, [machine])
  useEffect(() => {
    const shouldCheck = machine?.DaysToFix != null;
    setChecked(shouldCheck);
    setValue(machine?.DaysToFix);
  }, [machine?.DaysToFix]);

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleDialogAgree = () => {
    SaveDays();
    setOpen(false);
  };

  const handleDialogDisagree = () => {
    setOpen(false);
  };
  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  function setDays() {
    if (checked) {
      SaveDays();
    } else {
      setOpen(true);
    }
  }
  function SaveDays() {
    postAction(AOO_API_HOST + "/aoo-api/Productions/post-fix-days", {
      EventId: machine.EventId,
      DaysCount: text,
    });
  }
  return (
    <div>
      <Dialog open={input} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="dayCount"
            label="Days"
            defaultValue={value + 1}
            type="number"
            fullWidth
            variant="standard"
            onBlur={handleTextChange}
          />
        </DialogContent>
        <DialogActions>
          {machine?.DaysToFix != null && (
            <>
              <FormControlLabel
                control={
                  <IOSSwitch
                    sx={{ m: 1 }}
                    onChange={handleCheckboxChange}
                    checked={checked}
                  />
                }
                label={label}
              />

              <Button
                onClick={() => {
                  handleClose();
                  setDays();
                }}
              >
                Close
              </Button>
            </>
          )}
          {machine?.DaysToFix == null && (
            <Button
              onClick={() => {
                handleClose();
                SaveDays();
              }}
            >
              Save
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Dialog
        open={open}
        onClose={handleDialogDisagree}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Your changes won`t be saved
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to save them?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogDisagree}>Disagree</Button>
          <Button onClick={handleDialogAgree} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
