import React, { useState,useRef  } from "react";
import { TextArea } from "../style/LogStyles";
import { isMobile } from "react-device-detect";
function MultilineInput({placeholder, value,handleChange,name, isReadOnly}) {
  const [height, setHeight] = useState("40px");
  const textareaRef = useRef(null);
  const handleFocus = () => {
    isMobile? setHeight("80px") : setHeight("40px");
  };

  const handleBlur = () => {
    setHeight("40px");
    if (textareaRef.current) {
      textareaRef.current.scrollTop = 0; // Scroll to first line
    }
  };
  return (
    <TextArea
      className="multiline-input"
      ref={textareaRef}
      style={{ height: height }}
      name={name}
      type="text"
      onChange={handleChange}
      value={value}
      placeholder={placeholder}
      onFocus={handleFocus}
      onBlur={handleBlur}
      readOnly={isReadOnly}
    />
  );
}

export default MultilineInput;
