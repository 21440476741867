import styled from "styled-components";

export const Pop = styled.div`
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.9);
  overflow-y: auto;
  box-shadow: 0px 6px 30px rgba(0, 0, 0, 4.4);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: all 0.3s;
  z-index: 10;
  background-color: white;
  padding: 20px;
  &.open {
    visibility: visible;
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  ul {
    width: 100%;
  }
  .menu-button {
    color: #3e4146;
    display: flex;
    justify-content: space-between;
    padding: 10px 10px;
    font-size: 20px;
    cursor: pointer;
    &:hover {
      color: orange;
    }
  }
  @media (max-width: 560px) {
    width: 80%;
    top: 40%;
  }
`;

export const Close = styled.a`
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 20px;
    letter-spacing: 0.05rem;
    color: gray;
    cursor: pointer;
  }
  `;
export const Detail = styled.p`
padding: 0 25px;
`
export const Title = styled.div`
  font-size: 20px;
  color: #3e4146;
  line-height: 130%;
  letter-spacing: 0.07rem;
  margin-bottom: 5px;
  margin-bottom: 10px;
  span {
    color: orange;
  }

  @media (max-width: 560px) {
    font-size: 1rem;
  }
`;

export const Input = styled.input`
  width: 300px;
  padding: 10px;
  font-size: 20px;
`;
