import React, { useState, useContext, useRef } from "react";
import axios from "axios";

import { AOO_API_HOST } from "../../../env";
import {
  MainInput,
  Form,
  Label,
  Btn,
  Title,
  ActionBtn,
  Container,
} from "./UploaderStyles";

import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
  convertToPixelCrop,
} from "react-image-crop";
import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";
import "react-image-crop/dist/ReactCrop.css";
import { GlobalContext } from "../../../context/GlobalContext";
function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const Uploader = ({ setCover }) => {
  const [isGood, setIsGood] = useState(false);
  const [imgSrc, setImgSrc] = useState("");
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [scale, setScale] = useState(1);
  const [active, setActive] = useState(true);
  const [aspect, setAspect] = useState<number | undefined>(16 / 9);
  var { machine } = useContext(GlobalContext);
  const fileInputRef = useRef(null);
  var classActive = active ? "active" : "";

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }
  function fileDragHover(e: React.ChangeEvent<HTMLInputElement>) {
    var fileDrag = document.getElementById("file-drag");

    e.stopPropagation();
    e.preventDefault();

    if (e.type === "dragover") fileDrag.classList.add("hover");
    else {
      fileDrag.classList.add("modal-body");
      fileDrag.classList.add("file-upload");
    }
  }

  function handleToggleAspectClick(e) {
    e.stopPropagation();
    e.preventDefault();
    if (aspect) {
      setAspect(undefined);
      setActive(false);
    } else {
      setAspect(16 / 9);
      setActive(true);
      if (imgRef.current) {
        const { width, height } = imgRef.current;
        const newCrop = centerAspectCrop(width, height, 16 / 9);
        setCrop(newCrop);
        // Updates the preview
        setCompletedCrop(convertToPixelCrop(newCrop, width, height));
      }
    }
  }

  function blobToFile(blob: Blob, fileName: string): File {
    const file = new File([blob], fileName, { type: blob.type });
    return file;
  }

  function postAction(url, data) {
    try {
      axios.post(url, data).then((response) => response);
    } catch (error) {
      console.error("Error posting data:", error);
    }
  }
  async function onDownloadCropClick(e) {
    e.stopPropagation();
    e.preventDefault();
    const image = imgRef.current;
    const previewCanvas = previewCanvasRef.current;
    if (!image || !previewCanvas || !completedCrop) {
      throw new Error("Crop canvas does not exist");
    }
  
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
  
    const offscreen = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY
    );
    const ctx = offscreen.getContext("2d");
  
    if (!(ctx instanceof CanvasRenderingContext2D)) {
      throw new Error("Failed to get 2D rendering context");
    }
  
    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height
    );
  
    // Convert offscreen canvas to ImageBitmap
    const imageBitmap = await offscreen.transferToImageBitmap();
  
    // Create a normal canvas and draw the ImageBitmap onto it
    const tempCanvas = document.createElement('canvas');
    tempCanvas.width = offscreen.width;
    tempCanvas.height = offscreen.height;
    const tempCtx = tempCanvas.getContext('2d');
    
    if (!tempCtx) {
      throw new Error("Failed to get 2D context on temp canvas");
    }
  
    tempCtx.drawImage(imageBitmap, 0, 0);
  
    // Convert the normal canvas to a Blob
    const blob = await new Promise<Blob | null>((resolve) => {
      tempCanvas.toBlob(resolve);
    });
  
    if (!blob) {
      throw new Error("Failed to create Blob from canvas");
    }
  
    const fileName = `${machine.MachineCode}-image.png`;
    const file = blobToFile(blob, fileName);
  
    let data = new FormData();
    data.append("MachineId", machine.Id);
    data.append("Image", file);
    postAction(AOO_API_HOST + "/aoo-api/Productions/post-cover-picture", data);
    setCover(false);
  }
  

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
     
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          0
        );
      }
    },
    100,
    [completedCrop, scale, 0]
  );

  function coverSelectHandler(e) {
    if (e.target.files && e.target.files.length > 0) {
      var files = e.target.files || e.dataTransfer.files;

      setIsGood(
        /\.(?=gif|jpg|png|jpeg|GIF|JPG|JPEG|PNG)/gi.test(files[0]?.name)
      );

      setCrop(undefined);
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(files[0]);
    }
    fileDragHover(e);
  }
  return (
    <div className="Crop-Controls">
      <Form
        id="cover-upload-form"
        className="uploader"
        encType="multipart/form-data"
        onSubmit={(e) => onDownloadCropClick(e)}
      >
        <MainInput
          id="cover-upload"
          type="file"
          name="coverUpload"
          accept="image/*"
          ref={fileInputRef}
          onChange={coverSelectHandler}
        />
        <Label
          htmlFor="cover-upload"
          id="file-drag"
          onDragOver={(e) => fileDragHover(e)}
          onDragLeave={(e) => fileDragHover(e)}
          onDrop={(e) => coverSelectHandler(e)}
        >
          <div id="start">
            <i className="fa fa-download" aria-hidden="true"></i>
            <Title>Select a file or drag here</Title>
            <div id="notimage" className="hidden">
              Please select an image
            </div>
            <Btn id="file-upload-btn" className="btn">
              Select a file
            </Btn>
          </div>
        </Label>
        <Container>
          <div style={{ position: "relative" }}>
            {!!imgSrc && (
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(c) => setCompletedCrop(c)}
                aspect={aspect}
                // minWidth={100}
                minHeight={200}
              >
                <img
                  ref={imgRef}
                  alt="Crop me"
                  src={imgSrc}
                  style={{
                    transform: `scale(${scale}) rotate(${0}deg)`,
                    maxHeight: "40vh",
                    minHeight: "250px",
                  }}
                  onLoad={onImageLoad}
                />
              </ReactCrop>
            )}
            {!!imgSrc && (
              <div className={"doors"}>
                <button
                  type="button"
                  className={"miniDoor "}
                  onClick={(e) => setScale(scale === 1 ? scale : scale - 0.1)}
                >
                  -
                </button>
                <button
                  type="button"
                  className={"miniDoor "}
                  onClick={(e) => setScale(scale === 2 ? scale : scale + 0.1)}
                >
                  +
                </button>
                <button
                  type="button"
                  className={`miniDoor ${classActive}`}
                  onClick={(e) => handleToggleAspectClick(e)}
                >
                  <img src={require("./icon/1.png")} alt="aspect" />
                </button>
              </div>
            )}
          </div>

          {!!completedCrop && (
            <canvas
              ref={previewCanvasRef}
              style={{
                border: "1px solid black",
                objectFit: "contain",
                display: "none",
                width: completedCrop.width,
                height: completedCrop.height,
              }}
            />
          )}
        </Container>

        {isGood && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ActionBtn type="submit" background={"#03A9F4"}>
              Save
            </ActionBtn>
            <ActionBtn
              type="button"
              background={"#E91E63"}
              onClick={(e) => {
                setCover(false);
              }}
            >
              Cancel
            </ActionBtn>
          </div>
        )}
      </Form>
    </div>
  );
};

export default Uploader;
