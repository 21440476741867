import React from "react";

function Cancel(){
    return(
        <svg style={{marginRight:10}} width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.39333 0.333252L4.66667 4.05992L0.94 0.333252L0 1.27325L3.72667 4.99992L0 8.72658L0.94 9.66658L4.66667 5.93992L8.39333 9.66658L9.33333 8.72658L5.60667 4.99992L9.33333 1.27325L8.39333 0.333252Z" fill="#F12020"/>
</svg>

    )
}

export default Cancel;