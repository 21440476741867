import React from "react";
import { MenuData } from "../Data/MenuData";
import { NavLink } from "./NavbarStyles";
import { useNavigate } from "react-router-dom";

function Main() {
  let navigate = useNavigate();
  return (
    <>
      <ul>
        {MenuData[0].sections.map((val, key) => {
          return (
            <li key={key}>
              <NavLink
                key={key}
                onClick={() => {
                  navigate(val.link);
                }}
              >
                {val.title}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </>
  );
}

export default Main;
