import React, {createContext, useEffect, useState} from "react";

type Point = {
  x: Number;
  y: Number;
};

const Worker: Array<WorkerDefault> = [
  {
    FullName: "Worker",
    Initials: "W",
  },
];
interface WorkerDefault {
  FullName: string;
  Initials: string;
}
const MachineDefault: Machine = {
  Id: "",
  EventId: "",
  Name: "laveuse_peinture(e)",
  EventDuration: 1,
  EventsCount: 1,
  MachineCode: "c1",
  Workers: Worker,
  MachineReason: "",
  IsUrgent: true,
  EventState: "R",
  DaysToFix: null,
  Cover: null,
};
const LogDefault: LogMachine = {
  Id: "",
  MachineCode: "c1",
  MachineName: "",
  LastEntryDate: null,
  isPinned: false,
  IsUrgent: false,
  EventState: "R",
};
interface CoverImage {
  ImageData: Uint8Array;
  Content: string;
}
export interface LogMachine {
  Id: String;
  MachineCode: String;
  MachineName: String;
  LastEntryDate: Date;
  isPinned: Boolean;
  IsUrgent: Boolean;
  EventState: String;
}
interface Machine {
  Id: string;
  EventId: string;
  Name: string;
  EventDuration: number;
  EventsCount: number;
  MachineCode: string;
  Workers: Array<WorkerDefault>;
  MachineReason: string;
  IsUrgent: boolean | null;
  EventState: string;
  DaysToFix: number | null;
  Cover: CoverImage | null;
}
type optionsType = {
  color: string;
  isUrgent: boolean;
};
type GlobalContextType = {
  isNavActive: boolean;
  setisNavActive: React.Dispatch<React.SetStateAction<boolean>>;
  menuClicked: boolean;
  setMenuClicked: React.Dispatch<React.SetStateAction<boolean>>;
  machineId: string;
  setMachineId: React.Dispatch<React.SetStateAction<string>>;
  machine: Machine; // Change 'any' to the appropriate type
  setMachine: React.Dispatch<React.SetStateAction<Machine>>;
  logmachine: LogMachine; // Change 'any' to the appropriate type
  setLogMachine: React.Dispatch<React.SetStateAction<LogMachine>>;
  globalStatus: any; // Change 'any' to the appropriate type
  setGlobalStatus: React.Dispatch<React.SetStateAction<any>>;
  globalEvent: any; // Change 'any' to the appropriate type
  setGlobalEvent: React.Dispatch<React.SetStateAction<any>>;
  globalPoints: Point; // Change 'any' to the appropriate type
  setGlobalPoints: React.Dispatch<React.SetStateAction<Point>>;
  log: boolean;
  setLog: React.Dispatch<React.SetStateAction<boolean>>;
  show: boolean; // Change 'any' to the appropriate type
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  isActive: boolean;
  setIsActive: React.Dispatch<React.SetStateAction<boolean>>;
  eventOption: optionsType;
  setEventOption: React.Dispatch<React.SetStateAction<optionsType>>;
  backtoMenu: boolean;
  setBacktoMenu: React.Dispatch<React.SetStateAction<boolean>>;
};

const GlobalContext = createContext<GlobalContextType | undefined>(undefined);

const GlobalContextProvider = ({ children }) => {
  const [backtoMenu, setBacktoMenu] = useState<boolean>(false);
  const [isNavActive, setisNavActive] = useState(false);
  const [machine, setMachine] = useState<Machine>(MachineDefault);
  const [menuClicked, setMenuClicked] = useState(false);
  const [logmachine, setLogMachine] = useState<LogMachine>(LogDefault);
  const [globalStatus, setGlobalStatus] = useState<any>({});
  const [globalEvent, setGlobalEvent] = useState<any>({});
  const [globalPoints, setGlobalPoints] = useState<Point>({ x: 0, y: 0 });
  const [show, setShow] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [log, setLog] = useState<boolean>(false);
  const [machineId, setMachineId] = useState<string>("");
  const [eventOption, setEventOption] = useState({
    color: "",
    isUrgent: false,
  });
  return (
    <GlobalContext.Provider
      value={{backtoMenu, setBacktoMenu,
        isNavActive,
        setisNavActive,
        menuClicked,
        setMenuClicked,
        machineId,
        setMachineId,
        machine,
        setMachine,
        logmachine,
        setLogMachine,
        globalStatus,
        setGlobalStatus,
        globalEvent,
        setGlobalEvent,
        globalPoints,
        setGlobalPoints,
        log,
        setLog,
        show,
        setShow,
        isActive,
        setIsActive,
        eventOption,
        setEventOption,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export { GlobalContext, GlobalContextProvider };
