import styled from "styled-components";

const Title = styled.h1`
  display: block;
  color: aliceblue;
  padding: 14px 0px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
`;

const MainConfirm = styled.div`
  position: relative;
  margin: 0 auto;
  display: none;
`;

const TextConfirm = styled.div`
  width: 205px;
  height: 32px;
  left: 31px;
  top: 177px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  margin-top: 25px;
`;

const WordOk = styled.div`
  display: inline;
  font-weight: 700;
  color: #43a4ff;
`;

const WordCancel = styled.div`
  display: inline;
  font-weight: 700;
`;
const Content = styled.div`
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  height: 60vh;
  
`;

const ResolvedContent = styled.div`
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  height: 60vh;
`;

const Widget = styled.div`
  @media (max-width: 560px) {
    width: 84vw;
    padding: 8px 0px 0px;
    position: relative;
    border-radius: 10px;
    height: unset;
    min-height: 72px;
    margin: 0 auto;
  }
`;
const Name = styled.p`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  text-align: left;
  color: #363636;
  padding-bottom: 5px;
  display: flex;
  width: 100%;

  @media (max-width: 560px) {
    font-size: 14px;
    line-height: 14px;
    align-self: center;
  }
`;

const SmallName = styled.p`
  @media (max-width: 560px) {
    font-size: 12px;
  }
`;

const WidgetWorker = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  background: #bed9eb;
  border-radius: 0 0 10px 10px;
  font-family: "Open Sans";
  font-style: normal;
  width: 100%;
  height: 35px;
`;
const NameWorker = styled.p`
  font-weight: 600;
  line-height: 36px;
  margin: 0 auto;

  @media (max-width: 560px) {
    font-size: 18px;
  }
`;

const ListOfMachines = styled.div`
margin-bottom: 19px;
min-width: 0;
}
`;

const MachineName = styled.div`
  display: flex;
  border-bottom: 1px solid #363636;
  align-items: end;
  margin-left: 9px;
  margin-right: 9px;
`;

const MachineCode = styled.span`
  color: black;
  background: #ffffff;
  border: 0.6px solid #363636;
  border-radius: 3px;
  padding: 3px 5px;
  text-align: center;
  margin-right: 13px;
  margin-bottom: 6px;

  @media (max-width: 560px) {
    font-size: 14px;
    font-weight: 700;
    height: 22px;
    width: 36px;
    line-height: 120%;
  }
`;
const Confirm = styled.div`
  display: none;
  position: fixed;
  width: 85%;
  left: 50%;
  transform: translateX(-50%);
`;

const ButtonOk = styled.div`
  height: 54px;
  background: #1a5e9e;
  border: 1px solid #28dfdf;
  border-radius: 9px;
  margin: 22px 0 15px;
  text-align: center;
  padding-top: 15px;
  color: #ffffff;
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 18px;
  text-transform: uppercase;
`;

const Button = styled.div`
  height: 100px;
  background: #1a5e9e;
  border: 1px solid #28dfdf;
  border-radius: 9px;
  margin: 22px 0 15px;
  text-align: center;
  padding-top: 15px;
  color: #ffffff;
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 25px;
  text-transform: uppercase;
`;

const ButtonCancel = styled.div`
  height: 54px;
  background: #1a5e9e;
  border: 1px solid #28dfdf;
  border-radius: 9px;
  text-align: center;
  padding-top: 15px;
  color: #ffffff;
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 18px;
  text-transform: uppercase;
`;
const General = styled.div``;

export {
  Widget,
  Name,
  MachineCode,
  SmallName,
  WidgetWorker,
  NameWorker,
  ListOfMachines,
  MachineName,
  Title,
  MainConfirm,
  Confirm,
  TextConfirm,
  WordOk,
  WordCancel,
  Content,
  General,
  ResolvedContent,
  ButtonOk,
  ButtonCancel,
};
