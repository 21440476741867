import React, {useState, useRef, useEffect, forwardRef, useCallback, useContext} from "react";
import { Date } from "../style/LogStyles";
import Post from "./Post";
import {initialPostData, LogContext} from "../../../../context/LogContext";


const formatDateLabel = (dateString) => {
  
  // const date = new Date(dateString);
  // const today = new Date();
  // const yesterday = new Date(today);
  // yesterday.setDate(yesterday.getDate() - 1);

  // const options = { month: 'short', day: '2-digit', year: 'numeric' };
  // const formattedDate = date.toLocaleDateString('en-US', options);

  // if (
  //   date.getDate() === today.getDate() &&
  //   date.getMonth() === today.getMonth() &&
  //   date.getFullYear() === today.getFullYear()
  // ) {
  //   return "Today";
  // } else if (
  //   date.getDate() === yesterday.getDate() &&
  //   date.getMonth() === yesterday.getMonth() &&
  //   date.getFullYear() === yesterday.getFullYear()
  // ) {
  //   return "Yesterday";
  // } else {
  //   return formattedDate;
  // }
  return dateString;
};

const Grouped = forwardRef(({ posts, date, getMap}, ref) => {
    const [requestedScrollPostId, setRequestedScrollPostId] = useState(undefined);
    const {setPostData, setSelectedImageData} = useContext(LogContext);
    const scrollToPost = useCallback((postId) => {
        setPostData(initialPostData);
        setSelectedImageData(undefined);
        setRequestedScrollPostId(postId);
    }, [setPostData, setSelectedImageData]);


    useEffect(() => {
        if (requestedScrollPostId && requestedScrollPostId.length > 0) {
            console.log(requestedScrollPostId);
            const map = getMap();
            const node = map.get(requestedScrollPostId);
            console.log(node);
            node.scrollIntoView(true);
            setRequestedScrollPostId(undefined)
        }
    }, [getMap, requestedScrollPostId]);
    return (
    <div
      style={{ display: "flex", flexDirection: "column", position: "relative" }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          position: "sticky",
          top: 0,
          zIndex: 1, 
          padding: "8px 0",
        }}
      >
        <Date>{date}</Date>
      </div>
      {posts.map((post, index) => (
        <Post scrollToPost={scrollToPost} ref={(node) => {
            const map = getMap();
            if (node) {
                map.set(post.PostId, node);
            } else {
                map.delete(post.PostId);
            }
        }} key={index} post={post} />
      ))}
    </div>
  );
});

export default Grouped;
