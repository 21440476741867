import React, { useContext, useEffect } from "react";
import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import LaunchIcon from '@mui/icons-material/Launch';
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import FormComponent from "./Form";
import { LogContext, initialPostData } from "../../../../context/LogContext";
import {isMobile} from "react-device-detect";
import {Container} from "@mui/material";


const drawerBleeding = 56;

interface Props {
  window?: () => Window;
  id?: string;
  option?: any; // Change 'any' to the specific type if possible
  event?: any; // Change 'any' to the specific type if possible
  setEvent?: React.Dispatch<React.SetStateAction<any>>; // Change 'any' to the specific type if possible
  isReadOnly: boolean,
  scrollToPost: (postId: string) => void | undefined
}

const StyledBox = styled("div")(({ theme }) => ({
  backgroundColor: "#222529",
}));

const Puller = styled("div")(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: "#6c7075",
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

const Drawer = (props: Props) => {
  const { editMode, postData } = useContext(LogContext);
  const { window } = props;
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };
  useEffect(() => {
    if (editMode) {
      setOpen(true); // Open the drawer if editMode is true
    }
  }, [editMode]);
  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <>
      <CssBaseline />
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: `calc(80% - ${drawerBleeding}px)`,
            overflow: "visible",
            backgroundColor: "gray",
          },
        }}
      />

      <SwipeableDrawer

        container={container}
        anchor="bottom"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        disableSwipeToOpen={false}
        swipeAreaWidth={drawerBleeding}
        ModalProps={{
            keepMounted: true,
        }}
      >
        <StyledBox
            onClick={toggleDrawer(!open)}
          sx={{
            pointerEvents: 'all',
            position: "absolute",
            top: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: "visible",
            right: 0,
            left: 0,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: !open ? 'flex-start' : 'flex-end',
          }}
        >
          <Puller  />
           <Container sx={{ p: 2, color: "white", minHeight: drawerBleeding, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: 1 }}>
               <Typography>
                   Tap to {open ? 'close' : 'open'} a form
               </Typography>
               {props.isReadOnly && open && (
                   <div onClick={() => props.scrollToPost ? props.scrollToPost(postData.PostId) : undefined}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            color: 'white',
                            marginRight: '15px',
                            alignItems: 'center',
                            cursor: 'pointer'}}>
                       <Typography>Go to post</Typography>
                       <LaunchIcon sx={{ml: 1}}/>
                   </div>
               )}
           </Container>
        </StyledBox>
          <StyledBox
              sx={{
                  px: 2,
                  pb: 2,
            height: "100%",
            overflow: "auto",
          }}
        >
          <FormComponent
            id={props.id}
            event={props.event}
            setEvent={props.setEvent}
            isReadOnly={props.isReadOnly}
          />
        </StyledBox>
      </SwipeableDrawer>
    </>
  );
}
export default Drawer;
