import React from "react";


function WorkerSvg(props) {
    return (
        <svg  style={{ marginRight: 10 }} width="25" height="26"  viewBox="0 0 32 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.01574 9.20656H5.13081C5.05307 9.20656 4.97673 9.20073 4.9016 9.19189C4.88432 9.47134 4.9018 9.78975 4.96146 10.1242C5.12358 11.0329 5.53222 11.7354 5.91186 11.7882C6.3922 13.083 7.15458 14.341 8.09696 15.2598C8.62914 15.7814 9.22016 16.1936 9.84934 16.4441C10.2823 16.6147 10.7347 16.7111 11.2004 16.7111C11.6634 16.7111 12.1158 16.6161 12.5488 16.4441C13.2005 16.1843 13.8114 15.7522 14.358 15.206C15.2771 14.2887 16.0218 13.0498 16.4915 11.7776C16.8555 11.6711 17.2352 10.9913 17.3907 10.1228C17.4489 9.79394 17.467 9.48098 17.4513 9.20573C17.4453 9.20573 17.4399 9.20633 17.4343 9.20633H16.3848C16.4427 9.35821 16.4732 9.56372 16.4686 9.80095C16.4658 9.94117 16.4515 10.093 16.423 10.2501C16.4164 10.2919 16.4055 10.3277 16.3957 10.3677C16.3028 10.7934 16.1325 11.1419 15.9511 11.2962C15.9105 11.3316 15.8661 11.3565 15.8249 11.3699C15.7858 11.3826 15.7478 11.3884 15.7094 11.3818C15.6676 11.375 15.6279 11.3527 15.5919 11.3195C15.1553 12.6773 14.3232 14.0288 13.2738 14.8516C13.1183 14.972 12.9584 15.0823 12.7949 15.1783C12.2969 15.4718 11.7599 15.644 11.2008 15.644C10.473 15.644 9.7827 15.3555 9.16715 14.8846C8.098 14.0661 7.25005 12.696 6.80807 11.3197C6.77211 11.3531 6.73153 11.3744 6.68874 11.382C6.59854 11.3979 6.50231 11.3521 6.41111 11.2619C6.22468 11.0759 6.05633 10.7014 5.97617 10.251C5.97075 10.2213 5.96693 10.1931 5.96372 10.1634C5.96553 9.85063 5.97979 9.54524 6.00068 9.24392C6.00631 9.23047 6.01113 9.21901 6.01575 9.20656L6.01574 9.20656Z"   fill="#363636" />
            <path d="M22.4828 21.7501C22.0844 20.373 21.5826 19.4255 21.0653 18.759C20.0603 17.4606 19.0036 17.2521 18.5531 17.1655C18.4979 17.1555 18.4517 17.1468 18.4145 17.1382C17.9125 17.0205 17.3354 16.9863 16.8271 16.957C16.4179 16.9329 16.0275 16.9118 15.7097 16.8479C15.3399 17.8261 13.5232 18.537 11.2857 18.537C9.04713 18.537 7.23185 17.8259 6.86218 16.8479C6.54256 16.9118 6.15201 16.9341 5.7432 16.9576C5.23434 16.9863 4.65857 17.0205 4.15653 17.1382C4.11876 17.1466 4.07235 17.1555 4.01751 17.1655C3.59242 17.2479 2.62652 17.4337 1.6735 18.5456C1.09914 19.2164 0.529212 20.2223 0.0860521 21.7477C-0.0246376 22.1284 -0.025044 22.5294 0.0647575 22.9026C0.106142 23.0732 0.160384 23.2413 0.241944 23.3962C1.22469 25.2565 6.58463 25.92 11.2713 25.92H11.2974C15.9855 25.92 21.3425 25.2565 22.3273 23.396C22.4227 23.2172 22.4796 23.0209 22.5208 22.8203C22.5893 22.4703 22.5839 22.1031 22.4826 21.7501L22.4828 21.7501Z"   fill="#3636361" />
            <path d="M5.13065 8.12658H17.4349C17.9145 8.12658 18.3032 7.73805 18.3032 7.25852C18.3032 6.77898 17.9145 6.39107 17.4349 6.39107H17.1991C17.1991 4.08501 15.8786 2.08897 13.9543 1.11296L13.4639 3.89252C13.4253 4.11249 13.2338 4.26758 13.0175 4.26758C12.9914 4.26758 12.9649 4.26558 12.9385 4.26075C12.6916 4.21636 12.5269 3.98191 12.5713 3.73521L13.1304 0.560109C12.9207 0.224013 12.5484 0 12.1221 0H10.4431C10.0178 0 9.64553 0.223997 9.43601 0.560109L9.9955 3.73521C10.0393 3.9821 9.87476 4.21655 9.62807 4.26075C9.60135 4.26558 9.57504 4.26758 9.54892 4.26758C9.33296 4.26758 9.1409 4.1127 9.10213 3.89252L8.61196 1.11337C6.6876 2.08912 5.36753 4.08496 5.36753 6.39097H5.13108C4.65155 6.39097 4.26343 6.77909 4.26343 7.25841C4.26343 7.73794 4.65175 8.12668 5.13087 8.12668L5.13065 8.12658Z"   fill="#363636" />
            <path fillRule="evenodd" clipRule="evenodd" d="M31.1696 8.20536C31.2743 8.30982 31.3574 8.43391 31.4141 8.57052C31.4708 8.70714 31.5 8.8536 31.5 9.00151C31.5 9.14942 31.4708 9.29587 31.4141 9.43249C31.3574 9.56911 31.2743 9.6932 31.1696 9.79765L24.4215 16.5446C24.317 16.6494 24.1929 16.7324 24.0562 16.7891C23.9196 16.8458 23.7731 16.875 23.6252 16.875C23.4772 16.875 23.3308 16.8458 23.1941 16.7891C23.0575 16.7324 22.9334 16.6494 22.8289 16.5446L19.4548 13.1711C19.2436 12.96 19.125 12.6736 19.125 12.375C19.125 12.0764 19.2436 11.79 19.4548 11.5789C19.666 11.3677 19.9524 11.2491 20.2511 11.2491C20.5498 11.2491 20.8362 11.3677 21.0474 11.5789L23.6252 14.1585L29.577 8.20536C29.6815 8.10064 29.8056 8.01756 29.9422 7.96087C30.0789 7.90418 30.2254 7.875 30.3733 7.875C30.5212 7.875 30.6677 7.90418 30.8044 7.96087C30.941 8.01756 31.0651 8.10064 31.1696 8.20536Z"   fill="#363636" />
        </svg>
    )
}

export default WorkerSvg;