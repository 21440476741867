import React, { useEffect, useState, useRef, useContext, useCallback, useMemo } from "react";
import axios from "axios";
import Done from "./Machine/donesvg";
import MobileOptions from "../Mobile/MobileOptions";
import { prepWidgetData } from "../Data/MachinesData";
import { useMachinesContext } from "../../context/MachinesContext";
import { ContextMenuItem } from "./ContextMenu/ContextMenu";
import { AOO_API_HOST } from "../../env";
import { useCookies } from "react-cookie";
import { postAction, postQueryAction } from "./WidgetActions";
import FilterState from "./Filter";
import MachineList from "./Machine/MachineList";
import Mark from "./ContextMenu/Mark";
import EventMenuItem from "./ContextMenu/EventMenuItem";
import CoverUploader from "./CoverUploader/CoverUploader";
import Log from "./Log/components/Log";
import { GlobalContext } from "../../context/GlobalContext";
import { LogProvider } from "../../context/LogContext";

import {
  BlocksGrid,
  Months,
  MainInfo,
  Separator,
  ListOfMachines,
  WidgetRedEmpty,
  WidgetYellowEmpty,
  WidgetBlueEmpty,
  MainContainer,
} from "./Machine/MachineStyles";
import DisplayIcon from "./FailureIcon";
import PopUp from "./Alert/popup";
import Dialog from "./Alert/Dialog";
import DaysToFix from "./Alert/DayToFix";
function Widgets() {
  let MachinesContext = useMachinesContext();
  const [data, setData] = useState([]);
  const [event, setEvent] = useState({});
  const [root, setRoot] = useState();
  const [subStatusRoot, setsubStatusRoot] = useState();
  const [subWorkerRoot, setsubWorkerRoot] = useState();
  const [subReasonRoot, setsubReasonRoot] = useState();
  const [subFixRoot, setsubFixRoot] = useState();
  const [reason, setReason] = useState([]);
  const [alert, setAlert] = useState(false);
  const [cover, setCover] = useState(false);
  const [input, setInput] = useState(false);
  const [remove, setRemove] = useState(false);
  // const [eventOption, setEventOption] = useState({});
  const wrapperRef = useRef(null);
  const {
    machine,
    globalStatus,
    globalPoints,
    show,
    setShow,
    isActive,
    log, setLog, setEventOption
  } = useContext(GlobalContext);
  const coverTitle = useMemo(() => {
    return machine?.Cover != null ? "Edit cover picture" : "Add cover picture"
  }, [machine]);

  const closeMenu = useCallback(() => {
    document.documentElement.style.overflow = "";
    document.body.scroll = "yes";
  }, []);

  useEffect(() => {
    const fetchWorkers = async () => {
      const workerUrl = AOO_API_HOST + "/aoo-api/Productions/get-workers";
      const workerResponse = await axios.get(workerUrl);
      setData(workerResponse.data);
    };

    const fetchFailureReasons = async () => {
      const reasonUrl =
        AOO_API_HOST + "/aoo-api/Productions/get-failure-reasons";
      const reasonResponse = await axios.get(reasonUrl);
      setReason(reasonResponse.data);
    };

    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShow(false);
        if (
          document.querySelector(".open-sub-menu") !== null &&
          document.querySelector(".open-sub-menu") !== undefined
        ) {
          document
            .querySelector(".open-sub-menu")
            .classList.remove("open-sub-menu");
        }
      }
    };
    fetchWorkers();
    fetchFailureReasons();
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, [setShow]);

  const openMenu = (e) => {
    const sub = e.currentTarget.querySelector(".menu-sub-list");
    sub.classList.add("open-sub-menu");
  };

  const handleClose = () => {
    setInput(false);
  };

  const handleRemoveDisagree = () => {
    setRemove(false);
  };
  const handleRemoveAgree = () => {
    setRemove(false);
    postAction(
      AOO_API_HOST + `/aoo-api/Productions/remove-fix-days`,
      machine.EventId
    );
  };

  const screenW = window.innerWidth;
  const screenH = window.innerHeight;
  const rootW = root === undefined || root === null ? 0 : root?.offsetWidth;
  const rootH = root === undefined || root === null ? 0 : root?.offsetHeight;
  const right = screenW - globalPoints?.x > rootW;
  const left = screenW - globalPoints?.x - 200 < rootW;
  const top = screenH - globalPoints?.y > rootH;
  const bottom = !top;
  let offset = 0;

  const bodyRect = document.body.getBoundingClientRect().bottom;

  const elemRect =
    wrapperRef.current === undefined || wrapperRef.current === null
      ? 0
      : wrapperRef.current.getBoundingClientRect().bottom;

  if (root !== undefined && root !== null) {
    if (right) {
      root.style.left = `${globalPoints?.x + 5}px`;
    }
    if (left) {
      root.style.left = `${globalPoints?.x - rootW - 5}px`;
    }
    if (top) {
      root.style.top = `${globalPoints?.y + 5}px`;
    }
    if (bottom) {
      root.style.top = `${globalPoints?.y - rootH - 5}px`;
    }
  }

  function HeightSet(sub) {
    const height = sub === undefined || sub === null ? 0 : sub.offsetHeight;

    if (sub !== undefined && sub !== null) {
      if (bodyRect - elemRect < height) {
        offset = height - (bodyRect - elemRect);
      }
      sub.style.top = window.innerWidth > 560 ? `${-offset}px` : 0;
      if (left) {
        sub.style.left = `${-100}%`;
      }
      if (right) {
        sub.style.left = `${100}%`;
      }
    }
  }
  HeightSet(subStatusRoot);
  HeightSet(subWorkerRoot);
  HeightSet(subReasonRoot);
  HeightSet(subFixRoot);

  const [cookies] = useCookies();
  const [cookieValue, setCookieValue] = useState("A");
  const title = useMemo(() => {
    return machine?.DaysToFix != null ? "Edit days to fix" : "Set days to fix";
  }, [machine])

  useEffect(() => {
    if (cookies.preferredState) {
      setCookieValue(cookies.preferredState);
    }
  }, [cookies.preferredState]);

  return (
    <MainContainer>
      <LogProvider>
        <Log />
      </LogProvider>
      {cover && <CoverUploader setCover={setCover} remove={cover} />}
      {alert && (
        <PopUp
          setAlert={setAlert}
          machine={machine}
          reason={reason}
          event={event}
        />
      )}

      <DaysToFix input={input} handleClose={handleClose} machine={machine} />
      {isActive && (
        <MobileOptions
          id={machine.Id}
          status={globalStatus}
          machine={machine}
          setInput={setInput}
          setRemove={setRemove}
          setCover={setCover}
        />
      )}
      <Dialog
        machine={machine}
        remove={remove}
        handleRemoveDisagree={handleRemoveDisagree}
        handleRemoveAgree={handleRemoveAgree}
      />
      {window.innerWidth < 560 && <FilterState />}
      <BlocksGrid>
        {(() => {
          if (
            (window.innerWidth < 560 &&
              (cookieValue === "R" || cookieValue === "A")) ||
            window.innerWidth > 560
          ) {
            return (
              <Separator>
                <MainInfo>
                  <Months>Down</Months>
                </MainInfo>
                {(() => {
                  if (
                    prepWidgetData(MachinesContext.propData).redMachines
                      .length === 0
                  ) {
                    return (
                      <ListOfMachines>
                        <WidgetRedEmpty>
                          No Machines are stopped
                          <Done />
                        </WidgetRedEmpty>
                      </ListOfMachines>
                    );
                  } else {
                    return (
                      <MachineList
                        key={"list-red"}
                        data={
                          prepWidgetData(MachinesContext.propData).redMachines
                        }
                        background={"#DD8CAE"}
                      // setIsActive={setIsActive}
                      />
                    );
                  }
                })()}
              </Separator>
            );
          }
        })()}
        {(() => {
          if (
            (window.innerWidth < 560 &&
              (cookieValue === "Y" || cookieValue === "A")) ||
            window.innerWidth > 560
          ) {
            return (
              <Separator>
                <MainInfo>
                  <Months>Faulty</Months>
                </MainInfo>

                {(() => {
                  if (
                    prepWidgetData(MachinesContext.propData).yellowMachines
                      .length === 0
                  ) {
                    return (
                      <ListOfMachines>
                        <WidgetYellowEmpty>
                          No Machines are slowed
                          <Done />
                        </WidgetYellowEmpty>
                      </ListOfMachines>
                    );
                  } else {
                    return (
                      <MachineList
                        data={
                          prepWidgetData(MachinesContext.propData)
                            .yellowMachines
                        }
                        key={"list-yellow"}
                        background={"#f2d846"}
                      // setIsActive={setIsActive}
                      />
                    );
                  }
                })()}
              </Separator>
            );
          }
        })()}
        {(() => {
          if (
            (window.innerWidth < 560 &&
              (cookieValue === "B" || cookieValue === "A")) ||
            window.innerWidth > 560
          ) {
            return (
              <Separator>
                <MainInfo>
                  <Months>Needs a hug</Months>
                </MainInfo>

                {(() => {
                  if (
                    prepWidgetData(MachinesContext.propData).blueMachines
                      .length === 0
                  ) {
                    return (
                      <ListOfMachines>
                        <WidgetBlueEmpty>
                          No Machines are flagged
                          <Done />
                        </WidgetBlueEmpty>
                      </ListOfMachines>
                    );
                  } else {
                    return (
                      <MachineList
                        data={
                          prepWidgetData(MachinesContext.propData).blueMachines
                        }
                        background={"#599eff"}
                        key={"list-blue"}
                      // setIsActive={setIsActive}
                      />
                    );
                  }
                })()}
              </Separator>
            );
          }
        })()}
      </BlocksGrid>

      {show ? (
        <ContextMenuItem
          ref={(ref) => {
            setRoot(ref);
          }}
        >
          <ul>
            <Mark
              machine={machine}
              setsubStatusRoot={setsubStatusRoot}
              setEventOption={setEventOption}
              color={globalStatus.color}
            />
            <EventMenuItem
              setsubStatusRoot={setsubStatusRoot}
              setEventOption={setEventOption}
            />
            <li
              className={"menu-item worker"}
              ref={wrapperRef}
              onClick={(e) => {
                openMenu(e);
              }}
            >
              Assign a worker
              <ul
                className={"menu-sub-list worker"}
                ref={(ref) => {
                  setsubWorkerRoot(ref);
                }}
              >
                {data.map((worker, key) => (
                  <li
                    className={"menu-button"}
                    key={[worker.WorkerId]}
                    onClick={() => {
                      postAction(
                        AOO_API_HOST + `/aoo-api/Productions/post-new-flag`,
                        {
                          MachinesId: [machine.Id],
                          WorkerId: worker.WorkerId,
                          WorkerType: "W",
                        }
                      );
                      setShow(false);
                      //closeMenu();
                    }}
                  >
                    {worker.FullName}
                  </li>
                ))}
              </ul>
            </li>
            <li
              className={"menu-item worker"}
              ref={wrapperRef}
              onClick={(e) => {
                openMenu(e);
                //closeMenu();
              }}
            >
              Assign inspector
              <ul
                className={"menu-sub-list worker"}
                ref={(ref) => {
                  setsubFixRoot(ref);
                }}
              >
                {data.map((worker) => (
                  <li
                    className={"menu-button"}
                    key={[worker.WorkerId]}
                    onClick={() => {
                      postAction(
                        AOO_API_HOST +
                        `/aoo-api/Productions/post-machine-inspector`,
                        {
                          MachinesId: [machine.Id],
                          WorkerId: worker.WorkerId,
                        }
                      );
                      setShow(false);
                      //closeMenu();
                    }}
                  >
                    {worker.FullName}
                  </li>
                ))}
              </ul>
            </li>
            <li
              className={"menu-item reason"}
              ref={wrapperRef}
              onClick={(e) => {
                openMenu(e);
                //closeMenu();
              }}
            >
              Assign a status
              <ul
                className={"menu-sub-list reason"}
                ref={(ref) => {
                  setsubReasonRoot(ref);
                }}
              >
                {reason.map((reason, key) => (
                  <li
                    className={"menu-button"}
                    key={[reason.Id]}
                    onClick={() => {
                      postAction(
                        AOO_API_HOST +
                        `/aoo-api/Productions/post-failure-reason`,
                        {
                          EventId: machine.EventId,
                          ReasonId: reason.Id,
                          Status: machine.EventState,
                        }
                      );
                      setShow(false);
                      //closeMenu();
                    }}
                  >
                    {reason.Name}
                    <DisplayIcon
                      value={reason.Name}
                      height={20}
                      width={25}
                      color={"#363636"}
                    />
                  </li>
                ))}
              </ul>
            </li>
            <li
              className={"menu-item fix"}
              ref={wrapperRef}
              onClick={(e) => {
                openMenu(e);
                //closeMenu();
              }}
            >
              Days to fix
              <ul className={"menu-sub-list fix"}>
                <li
                  className={"menu-button"}
                  onClick={(e) => {
                    setInput(true);
                    //closeMenu();
                  }}
                >
                  {title}
                </li>
                {machine.DaysToFix != null && (
                  <li
                    className={"menu-button"}
                    onClick={() => {
                      setRemove(true);
                      //closeMenu();
                    }}
                  >
                    Remove days
                  </li>
                )}
                {globalStatus.isUrgent === true && <Mark machine={machine} 
                  setsubStatusRoot={setsubStatusRoot}
                  setEventOption={setEventOption}
                  color={globalStatus.color}/>}
              </ul>
            </li>
            <li
              className={"menu-item cover"}
              ref={wrapperRef}
              onClick={(e) => {
                openMenu(e);
                //closeMenu();
              }}
            >
              Cover picture
              <ul className={"menu-sub-list cover"}>
                <li
                  className={"menu-button"}
                  onClick={(e) => {
                    setCover(true);
                    //closeMenu();
                  }}
                >
                  {coverTitle}
                </li>
                {machine.Cover != null && (
                  <li
                    className={"menu-button"}
                    onClick={() => {
                      postQueryAction(
                        "RemoveCoverPicture?MachineId",
                        machine.Id
                      );
                      setShow(false);
                      //closeMenu()
                    }}
                  >
                    Remove cover picture
                  </li>
                )}
              </ul>
            </li>
            <li
              className={"menu-item log"}
              ref={wrapperRef}
              onClick={(e) => {
                //closeMenu();
                setLog(true);
              }}
            >
              Log
            </li>
          </ul>
        </ContextMenuItem>
      ) : closeMenu()}
    </MainContainer>
  );
}

export default Widgets;
