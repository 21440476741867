import React from "react";
import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import "swiper/css";
import LeftCancelled from "./icons/LeftCancell";
import RightCompleted from "./icons/RightComplete";
// import { TextConfirm, WordOk, WordCancel } from "./InspectionFlags_styles";
import { AOO_API_HOST } from "../../../env";
import {
  General,
  Widget,
  Name,
  WidgetWorker,
  NameWorker,
  SmallName,
  MachineCode,
  MachineName,
  ListOfMachines,
  Confirm,
  ButtonOk,
  ButtonCancel,
  TextConfirm,
  WordOk,
  WordCancel,
} from "./InspectionFlags_styles";
import { BrowserView, MobileView } from "react-device-detect";
import axios from "axios";

function Widgets(props) {
  const { data } = props;
  const [swiper, setSwiper] = useState(null);

  const swipe = (swiperCore) => {
    const { activeIndex } = swiperCore;
    if (activeIndex === 1) return;

    const machineId = swiperCore.el.id;

    document.querySelectorAll(".swiper").forEach((el) => {
      if (el.id !== machineId) el.classList.add("hidden");
    });

    let t = document.querySelector("#confirm_" + machineId);
    if (t) t.style.display = "block";
  };

  const okConfirm = (e) => {
    const machineId = e.target.id.substring(e.target.id.lastIndexOf("_") + 1);

    document.querySelector("#confirm_" + machineId).style.display = "none";

    axios
      .put(
        AOO_API_HOST +
          "/aoo-api/Productions/remove-inspector?machineId=" +
          machineId
      )
      .then(() => {
        swiper.slideTo(1, 50);
        document.querySelectorAll(".swiper").forEach((el) => {
          el.classList.remove("hidden");
        });
      });
    document.querySelectorAll(".swiper").forEach((el) => {
      el.classList.remove("hidden");
    });
  };

  const cancelConfirm = (e) => {
    const machineId = e.target.id.substring(e.target.id.lastIndexOf("_") + 1);
    let t = document.querySelector("#confirm_" + machineId);
    if (t) {
      t.style.display = "none";
      swiper.slideTo(1, 50);

      document.querySelectorAll(".swiper").forEach((el) => {
        if (el.id !== machineId) el.classList.remove("hidden");
      });
    }
  };

  return (
    <div>
      <General>
        <ListOfMachines>
          <Swiper
            onSwiper={setSwiper}
            id={data.MachineId}
            modules={[Navigation]}
            mavigation={"true"}
            speed={500}
            slidesPerView={"auto"}
            initialSlide={1}
            spaceBetween={25}
            centeredSlides={true}
            onSlideChange={swipe}
          >
            {MobileView && (
              <SwiperSlide>
                <div className="completeAc">
                  <span className="action">Done</span>
                  <RightCompleted />
                </div>
              </SwiperSlide>
            )}

            <SwiperSlide>
              <div className="borderGradient">
                <Widget className={data.EventState}>
                  <MachineName>
                    <MachineCode>{data.MachineCode}</MachineCode>
                    <Name>
                      {data.MachineName.length > 35 ? (
                        <SmallName>{data.MachineName}</SmallName>
                      ) : (
                        data.MachineName
                      )}
                    </Name>
                  </MachineName>

                  <WidgetWorker>
                    <NameWorker>
                      {data.Worker != null && data.Worker.FullName}
                    </NameWorker>
                  </WidgetWorker>
                </Widget>
              </div>
            </SwiperSlide>
            {MobileView && (
              <SwiperSlide>
                <div className="cancelAc">
                  <LeftCancelled />
                  <span className="action">Cancel</span>
                </div>
              </SwiperSlide>
            )}
          </Swiper>
        </ListOfMachines>
      </General>

      <Confirm id={"confirm_" + data.MachineId}>
        <TextConfirm>
          Click <WordOk>OK</WordOk> to confirm your selection, or Press
          <WordCancel>Cancel</WordCancel> to correct it.
        </TextConfirm>
        <ButtonOk onClick={okConfirm} id={"ok_" + data.MachineId}>
          Ok
        </ButtonOk>
        <ButtonCancel onClick={cancelConfirm} id={"cancel_" + data.MachineId}>
          Cancel
        </ButtonCancel>
      </Confirm>
    </div>
  );
}

export { Widgets };
