import React from "react";


function LeftCancel() {
    return (
        <svg width="55" height="55" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M41.25 27.4999C41.25 26.8921 41.0085 26.3092 40.5787 25.8794C40.149 25.4497 39.5661 25.2082 38.9583 25.2082L12.4093 25.2082L15.3702 22.2451C15.7647 21.8505 16.0018 21.3255 16.0368 20.7685C16.0718 20.2116 15.9024 19.661 15.5604 19.2201L15.3702 19.0047C14.9756 18.6101 14.4505 18.3731 13.8936 18.3381C13.3367 18.303 12.7861 18.4724 12.3452 18.8145L12.1297 19.0047L5.25474 25.8797C4.86017 26.2743 4.62315 26.7993 4.58813 27.3562C4.5531 27.9132 4.7225 28.4638 5.06453 28.9047L5.25474 29.1201L12.1297 35.9951C12.5421 36.4061 13.0955 36.6447 13.6775 36.6625C14.2595 36.6803 14.8264 36.4759 15.2631 36.0908C15.6998 35.7057 15.9736 35.1688 16.0288 34.5892C16.084 34.0096 15.9165 33.4307 15.5604 32.9701L15.3702 32.7547L12.4093 29.7916L38.9583 29.7916C39.5661 29.7916 40.149 29.5501 40.5787 29.1203C41.0085 28.6906 41.25 28.1077 41.25 27.4999ZM50.4166 27.4999C50.4183 24.8661 49.5127 22.3121 47.8521 20.2678C46.1914 18.2235 43.8773 16.8137 41.299 16.2757C38.7208 15.7377 36.0359 16.1043 33.6962 17.3138C31.3566 18.5233 29.5051 20.5019 28.4533 22.9166H32.3766C33.3524 21.5152 34.7499 20.4619 36.3658 19.9098C37.9817 19.3577 39.7316 19.3358 41.3608 19.8471C42.9901 20.3585 44.4136 21.3764 45.4242 22.7529C46.4348 24.1293 46.9798 25.7923 46.9798 27.4999C46.9798 29.2075 46.4348 30.8705 45.4242 32.2469C44.4136 33.6233 42.9901 34.6413 41.3608 35.1526C39.7316 35.664 37.9817 35.642 36.3658 35.09C34.7499 34.5379 33.3524 33.4846 32.3766 32.0832H28.4533C29.5069 34.4956 31.3588 36.4718 33.6978 37.6796C36.0368 38.8873 38.7203 39.2531 41.2972 38.7153C43.8741 38.1776 46.1873 36.7691 47.8481 34.7267C49.5088 32.6842 50.4158 30.1323 50.4166 27.4999Z" fill="#F12020"/>
        </svg>
        
        
    )
}

export default LeftCancel;