import { Content } from "../../NavbarStyles";
import MachineTile from "../../Components/Machine";
import { BrowserView, MobileView } from "react-device-detect";

function MachineList({ handleClick, height, searchResults }) {

  const myStyle = {
    height: !MobileView ? height + "vh" : "60"+"vh",
    marginBottom: !MobileView ? "20px": "",
    width: "100%" 
  };

  const results = searchResults.map((machine, index) => (
    <MachineTile
      handleClick={handleClick}
      machine={machine}
      key={machine.MachineId + index}
    />
  ));


  const content = results?.length ? (
    results
  ) : (
    <article>
      <p>No Matching Machines</p>
    </article>
  );

  return (
    <Content style={myStyle}>
      {content}
    </Content>
  );
} 

export default MachineList;
