import React, { useContext } from "react";
import { Burger } from "./NavbarStyles";
import { GlobalContext } from "../../context/GlobalContext";

const Menu = ({ handleClick }) => {
  const { menuClicked, setMenuClicked } = useContext(GlobalContext);

  const activateMenu = () => {
    setMenuClicked(!menuClicked);
    handleClick();
  };

  return (
    <Burger className={`menu ${menuClicked ? "menu-clicked" : ""}`}>
      <div
        className={`menu ${menuClicked ? "menu-clicked" : ""}`}
        onClick={activateMenu}
      >
        <div
          className={`menu-global menu-top ${
            menuClicked ? "menu-top-click" : ""
          }`}
        ></div>
        <div
          className={`menu-global menu-middle ${
            menuClicked ? "menu-middle-click" : ""
          }`}
        ></div>
        <div
          className={`menu-global menu-bottom ${
            menuClicked ? "menu-bottom-click" : ""
          }`}
        ></div>
      </div>
    </Burger>
  );
};

export default Menu;
