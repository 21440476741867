const enum FileType {
    Image,
    Video,
    Pdf,
    Audio,
    Other
}

const fileTypeEnum = (fileType: string) => {
    switch (fileType) {
        case "video/mp4":
        case "video/3gpp":
            return FileType.Video;
        case "image/jpeg":
        case "image/jpg":
        case "image/png":
        case "image/heic":
        case "image/svg+xml":
            return FileType.Image;
        case "audio/mp3":
            return FileType.Audio;
        case "application/pdf":
            return FileType.Pdf;
        default:
            return FileType.Other;
    }
}
export {fileTypeEnum};

const createFileUrl = (fileContent: string | ArrayBuffer, fileType: string) => {
    let resultingBlob: Blob;
    if (typeof fileContent === "string") {
        fileContent = window.atob(fileContent);
        // Create UNIT8ARRAY of size same as row data length
        const uInt8Array = new Uint8Array(fileContent.length);

        // Insert all character code into uInt8Array
        for (let i = 0; i < fileContent.length; ++i) {
            uInt8Array[i] = fileContent.charCodeAt(i);
        }
        resultingBlob = new Blob([uInt8Array], {type: fileType });
    }
    else {
        resultingBlob = new Blob([fileContent], {type: fileType});
    }

    return URL.createObjectURL(resultingBlob);
}

export {createFileUrl};

const downloadFileByUrl = (url: string, fileName: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
        'download',
        fileName,
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
}
export {downloadFileByUrl};
export default FileType;