import React, {useCallback, useEffect, useState} from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import {ReasonStyled, StatusLink} from "../NavbarStyles";
import { postAction } from "../../Widgets/WidgetActions";
import { AOO_API_HOST } from "../../../env";

type ReasonProps = {
  onReasonClick: (reasonId: string) => {} | undefined
}

const Reason: React.FC<ReasonProps> = ({onReasonClick = undefined}) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [reason, setReason] = useState([]);
  const [event, setEvent] = useState<any | undefined>(undefined);

  const onReasonClickCallback = useCallback(async (reasonId: string) => {
   if (onReasonClick)
     return onReasonClick(reasonId);
    postAction(
        AOO_API_HOST + `/aoo-api/Productions/post-failure-reason`,
        {
          EventId: event.Id,
          ReasonId: reasonId,
          Status: event.EventState,
        });
    navigate("/main");
  }, [event, navigate, onReasonClick]);
  
  useEffect(() => {
    const url = AOO_API_HOST + "/aoo-api/Productions/get-failure-reasons";
    axios.get(url).then((response) => setReason(response.data));
  }, []);

  useEffect(() => {
    if (event !== state?.event) {
      setEvent(state?.event);
    }
  }, [state?.event, event]);
  return (
    <ReasonStyled>
      {reason.map((res, key) => {
        return (
            <StatusLink
                key={key}
                onClick={() => {
                  onReasonClickCallback(res.Id);
                }}
            >
              {res.Name}
            </StatusLink>
        );
      })}
    </ReasonStyled>
  );
}

export default Reason;
