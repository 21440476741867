
import styled from "styled-components";

export const CloseButton = styled.a`
  position: absolute;
  padding: 1.5px 7px;
  right: 0px;
  margin-top: -25px;
  border-radius: 50%;
  color: rgb(69 77 81 / 65%);
  cursor: pointer;
  font-size: 31px;
  top: 23px;
  @media (max-width: 480px) {
    font-size: 25px;
  }
`;
export const Title = styled.div`
  font-size: 26px;
  color: #3e4146;
  line-height: 130%;
  letter-spacing: 0.07rem;
  margin-bottom: 5px;
  margin-bottom: 10px;
  span {
    color: orange;
  }

  @media (max-width: 560px) {
    font-size: 18px;
  }
  @media (max-width: 480px) {
    font-size: 12px;
  }

`;
export const MainInput = styled.input`
  display: none;
`;

export const Form = styled.form`
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
 border: 3px solid #eee;
  #image-container {
    display: flex;
    justify-content: center;
  }
`;

export const Container = styled.div`
display: flex;
justify-content: center;
`

export const Label = styled.label`
  width: 100%;

  text-align: center;
  background: #fff;
  border-radius: 7px;
  // border: 3px solid #eee;
  transition: all 0.2s ease;
  user-select: none;

  // &:hover {
  //   border-color: orange;
  //   border: 3px solid orange;
  //   box-shadow: inset 0 0 0 6px #eee;
  // }

  @media (min-width: 560px) {
    padding: 10px;
  }

  @media (max-width: 560px) {
    #start{font-size: 18px;}
    #response{
      font-size:18px;
  
      }
  }
  @media (max-width: 480px) {
    #start{font-size: 15px};
    #response{
      font-size:15px;
  
      }
  }
  @media (max-width: 360px) {
    #start{font-size: 13px};
    #response{
      font-size:13px;
  
      }
  }

  }
`;

export const Div = styled.div`
  padding: 33px;
  @media (max-width: 560px) {
    padding: 10px;
  }
`;

export const Btn = styled.span`
  display: inline-block;
  margin: 0.5rem 0.5rem 1rem 0.5rem;
  font-family: inherit;
  font-weight: 700;
  font-size: 14px;
  text-decoration: none;
  text-transform: initial;
  border: none;
  border-radius: 0.2rem;
  outline: none;
  padding: 0 1rem;
  height: 36px;
  line-height: 36px;
  color: #fff;
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;
  background: orange;
  border-color: orange;
  cursor: pointer;
`;

export const ActionBtn = styled.button`
  display: inline-block;
  margin: 10px;
  width: 100px;
  border: none;
  border-radius: 0.2rem;
  outline: none;
  padding: 0 1rem;
  height: 40px;
  line-height: 40px;
  color: #fff;
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;
  background-color: ${(props) => props.background};
  border-color: orange;
  cursor: pointer;
`;
