import React, {useCallback, useContext, useEffect, useState} from "react";
import {useZxing} from "react-zxing";
import { useMediaDevices } from "react-media-devices";
import {ColorRing} from "react-loader-spinner";
import {QrCodeScannerComponentWrapper, NavLink, Title, Content} from "../NavbarStyles";
import {FormControlLabel, Radio, RadioGroup, Select, SelectChangeEvent} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {GlobalContext} from "../../../context/GlobalContext";
import {useNavigate, useParams, useLocation} from "react-router-dom";
import {AOO_API_HOST} from "../../../env";
import {
    postAction
} from "../../Widgets/WidgetActions";
import axios from "axios";
import Reason from "./Reason";
import FinalOption from "./FinalOption";
import {useMachinesContext} from "../../../context/MachinesContext";

import SearchBar from "../Components/SearchBar";
import MachineTile from "../Components/Machine";

export const ScanQrCode = () =>  {
    const constraints: MediaStreamConstraints = {
        video: true,
        audio: false,
    };

    const enum PromptResult {
        NoAction,
        ChangeColor,
        GoToLog,
    }
    const machineIdPrefix = "MACHINE-";
    const navigate = useNavigate();
    const machinesContext = useMachinesContext();
    const {machineId} = useParams();
    const [result, setResult] = useState("");
    const [isError, setIsError] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [isPermissionGranted, setIsPermissionGranted] = useState(false);
    const [isScannerReady, setIsScannerReady] = useState(false);
    const [currentCameraDevice, setCurrentCameraDevice] = useState<MediaDeviceInfo | undefined>(undefined);
    const [isPromptActionOpened, setIsPromptActionOpened] = useState<boolean>(false);
    const [promptResultValue, setPromptResultValue] = useState<PromptResult>(PromptResult.NoAction);
    const [selectedMachine, setSelectedMachine] = useState({
        EventId: undefined,
        EventState: undefined
    });

    // new entry
    const [searchResults, setSearchResults] = useState([]);
    const [fetched, setFetched] = useState("");
    const [isLoading, setLoading] = useState(false);
    let { state } = useLocation();
    const [isSearchEmpty, setIsSearchEmpty] = useState(true);

    const handleSearchChange = (inputValue) => {
        const trimmedInputValue = inputValue.trim();
        const isInputEmpty = trimmedInputValue === '';
        setIsSearchEmpty(isInputEmpty);
        if (isInputEmpty){           
            //console.log(devices);
            //setCurrentCameraDevice(devices[0]);                
        }
    };

    let url = AOO_API_HOST;    
    url += "/aoo-api/Productions/all-machines";

    let handleClick = (e, machine) => {
        setResult(machine.MachineId);
        loadMachineInspection(machine.MachineId);
        setCurrentCameraDevice(undefined);
        setIsPromptActionOpened(true);
        console.log("Clicked");
    };
    //end of new entry
    
    const { devices, loading } = useMediaDevices({constraints});
    const {
        setMachineId,
        setLog,
        setMenuClicked,
        setisNavActive,
        isNavActive,
        setBacktoMenu,
        eventOption,
        setEventOption,
        setMachine,
    } = useContext(GlobalContext);
    

    const {ref}  = useZxing({
        paused: !currentCameraDevice?.deviceId || !isNavActive || !isSearchEmpty,
        deviceId: currentCameraDevice?.deviceId,
        onDecodeResult(result) {
            const resultText = result.getText().replace(machineIdPrefix, "");
            setResult(resultText);
            loadMachineInspection(resultText);
            setCurrentCameraDevice(undefined);
            setIsPromptActionOpened(true);
        },
    });


    const getLoadingSpinner = useCallback(() => {
        return (
            <ColorRing
                visible={loading && !currentCameraDevice}
                height="80"
                width="80"
                ariaLabel="color-ring-loading"
                wrapperStyle={{}}
                wrapperClass="color-ring-wrapper"
                colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}/>
        )
    }, [currentCameraDevice, loading]);

    const getAvailableCameraDevices = useCallback(() => {
        return devices && devices?.filter(device => device.kind === "videoinput")?.map((device, i) =>
            <MenuItem key={i} value={device.deviceId}>{device.label}</MenuItem>)


    }, [devices]);

    const handleCameraDeviceChange = useCallback((e:  SelectChangeEvent) => {
        const selectedDevice = devices?.find(device => device.deviceId === e.target.value);
        if (selectedDevice !== undefined) {
            setCurrentCameraDevice(selectedDevice);
        }
        else
        {
            setIsError(true);
            setErrorMessage("No camera devices is currently available. Ensure camera is connected.")
        }
    }, [devices]);

    const openLog = useCallback(() => {
        if (result && result.length > 0) {
            setMachineId(result);
            setLog(true);
            setMenuClicked(false);
            setisNavActive(false);
            setBacktoMenu(true);
            navigate("/main/log");
        }
    }, [result, navigate, setMachineId, setLog, setMenuClicked, setisNavActive, setBacktoMenu]);

    const checkCameraPermission = useCallback(async () => {
        const permissionName = "camera" as PermissionName;
       try {
           const result = await navigator.permissions.query( { name: permissionName });
           if (result.state === "granted" && !isPermissionGranted)
           {
               setIsPermissionGranted(true);
               setIsError(false);
               setErrorMessage("");
               return true;
           }
           else
           {
               setIsError(true);
               setErrorMessage("Camera permission is not granted. Ensure permission is allowed both for website and for browser.")
               return false;
           }
       }
       catch (exception)
       {
           setIsError(true);
           setErrorMessage(exception.toString());
       }
    }, [isPermissionGranted]);

    const getMachineFromContext = useCallback(async(machineColor: string, machineId: string, retryCount: number = 3) => {
        let result;
        switch (machineColor)
        {
            case "Y":
                result = machinesContext.propData.YellowMachines.find(x => x.Id === machineId);
                break;
            case "R":
                result = machinesContext.propData.RedMachines.find(x => x.Id === machineId);
                break;
            case "B":
                result = machinesContext.propData.BlueMachines.find(x => x.Id === machineId);
                break;
            case "G":
                result = machinesContext.propData.GreenMachines.find(x => x.Id === machineId);
                break;
        }
        return result;
    }, [machinesContext.propData.BlueMachines, machinesContext.propData.GreenMachines, machinesContext.propData.RedMachines, machinesContext.propData.YellowMachines]);

    const loadMachineInspection = useCallback(async (machineId: string, retry: number = 3) => {
        const url = AOO_API_HOST + "/aoo-api/Productions/GetMachine?machineId=" + machineId;
        const  response = await axios.get(url);
        if (response.status === 200) {
            const machineFromContext = await getMachineFromContext(response.data.EventState, machineId);
            if (!machineFromContext) {
                return setTimeout(() => {loadMachineInspection(machineId, retry - 1 )}, 450)
            }
            setMachine(machineFromContext);
            setSelectedMachine(response.data);
            setIsError(false);
            setErrorMessage("");
        }
        else
        {
            setIsError(true);
            setErrorMessage("Machine retrieval went wrong, ensure you are using correct QR code.")
        }
    }, [setMachine, getMachineFromContext]);
    
    const onFinalOptionClick = useCallback(async (option) => {
        setEventOption({color: option.color, isUrgent: option.isUrgent});
    }, [setEventOption]);

    const onReasonClick = useCallback(async (reasonId: string) => {
        postAction(
            AOO_API_HOST + `/aoo-api/Productions/post-failure-reason`,
            {
                EventId: selectedMachine.EventId,
                ReasonId: reasonId,
                Status: selectedMachine.EventState,
            });

        openLog();
    }, [openLog, selectedMachine]);

    const setUserPromptResult = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const userPromptResult: PromptResult = parseInt(event.target.value);
        if (promptResultValue !== userPromptResult)
        {
            setPromptResultValue(userPromptResult);
            setIsPromptActionOpened(false);
        }
    }, [promptResultValue]);

    useEffect(() => {
        const permissionName = "camera" as PermissionName;
        try {
            navigator.permissions.query( { name: permissionName })
                .then(permission =>
                {
                    permission.onchange = (ev) => {
                        const state = permission.state === "granted";
                        if (state !== isPermissionGranted)
                            setIsPermissionGranted(state);
                        setIsError(!state);
                    }
                });

        }
        catch (exception)
        {
            setIsError(true);
            setErrorMessage(exception.toString());
        }
    }, [isPermissionGranted]);

    useEffect(() => {
        if (!isPermissionGranted)
        {
            checkCameraPermission().then(r => {
                if (r)
                    console.debug("Camera permission granted")
            });
        }
    }, [isPermissionGranted, checkCameraPermission]);


    useEffect(() => {
        if (devices && !loading && isPermissionGranted && !isScannerReady && isSearchEmpty)
        {            
            const availableCamera = devices.filter(device => device.kind === "videoinput")?.[0];
            if (availableCamera !== undefined)
            {
                setCurrentCameraDevice(availableCamera);
                setIsScannerReady(true);
            }
        }
    }, [devices, loading, isPermissionGranted, isSearchEmpty, isLoading, isScannerReady]);

    useEffect(() => {
        if (promptResultValue !== PromptResult.NoAction && result?.length > 0)
        {
            if (promptResultValue === PromptResult.GoToLog)
            {
                openLog();
            }
        }
    }, [result, PromptResult.NoAction, promptResultValue, loadMachineInspection, openLog, PromptResult.GoToLog]);

    useEffect(() => {
        if (isError)
        {
            //setMachine(undefined);
            setEventOption({color: null, isUrgent: false});
            setMachineId(null);
        }
    }, [isError, setMachine, setEventOption, setMachineId]);

    useEffect(() => {
        if (machineId && machineId.length > 0)
        {
            setResult(machineId);
            loadMachineInspection(machineId);
            setCurrentCameraDevice(undefined);
            setIsPromptActionOpened(true);
            setisNavActive(true);
        }
    }, [loadMachineInspection, machineId, setisNavActive]);

    useEffect(() => {
        if (!isLoading) {
          axios.get(url).then((response) => {            
            setFetched(response.data);  
            setSearchResults(response.data);
          });
          setLoading(true);
        }
      }, [state, isLoading, url]);

    return (
        <>
          <>
              <NavLink
                  onClick={() => {
                      navigate("/main/");
                  }} to={"/main/remote"}
              >
                  Back to menu
              </NavLink>
              <Title>
                 <SearchBar machines={fetched} setSearchResults={setSearchResults} onSearchChange={handleSearchChange} />
             </Title>
             { isSearchEmpty ? (
                <>
                    {isError ? (
                    <>
                        <p style={{color: 'red'}}>{errorMessage}</p>
                    </>
                    ) : (
                        <QrCodeScannerComponentWrapper>
                            {!isPromptActionOpened && !result && (
                                <div style={{paddingTop: "15px", display: 'inline'}}>
                                    {getLoadingSpinner()}
                                    <p>Scan QR Code to open associated log page</p>
                                    <label htmlFor={"camera-device-choose-select"}>Choose camera for scanning</label>                                    
                                    <Select
                                        id="camera-device-choose-select"
                                        value={currentCameraDevice?.deviceId ?? ''}
                                        onChange={handleCameraDeviceChange}
                                        style={{color: 'white', maxWidth: '300px'}}
                                        variant="outlined"
                                    >
                                        {getAvailableCameraDevices()}
                                    </Select> 
                                    <video ref={ref}/>                                    
                                </div>
                            )}
                            {isPromptActionOpened && (
                                <div style={{display: 'flex', flexDirection: 'column', color: 'white', paddingTop: '15px'}}>
                                    <p>What do you want to do with scanned machine?</p>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue={PromptResult.NoAction}
                                        value={promptResultValue}
                                        onChange={setUserPromptResult}
                                        name="radio-buttons-group"
                                    >
                                        <FormControlLabel value={PromptResult.ChangeColor} control={<Radio />} label="Change color" />
                                        <FormControlLabel value={PromptResult.GoToLog} control={<Radio />} label="Go to log" />
                                    </RadioGroup>
                                </div>
                            )}
                            {
                                promptResultValue === PromptResult.ChangeColor && selectedMachine !== undefined && eventOption?.color === '' && (
                                    <FinalOption onFinalClick={onFinalOptionClick} machine={selectedMachine} includeBackButton={false}/>
                                )}
                            {eventOption?.color?.length > 0 && (
                                <Reason onReasonClick={onReasonClick}/>
                            )}
                        </QrCodeScannerComponentWrapper>
                    )}
                </>
             ) : ( 
                <>
                <Title>Select machine</Title>
                <Content style={{
                    //height: "100%",
                    width: "750px",
                    flexDirection:"column",
                    flexWrap:"unset",
                    justifyContent:"space-between"
                }}>
                    {searchResults.map((machine, key) => (
                        <MachineTile handleClick={handleClick} machine={machine} key={key} value={machine.MachineId}/>
                    ))}
                </Content>                
                <QrCodeScannerComponentWrapper>
                    {isPromptActionOpened && (
                        <div style={{display: 'flex', flexDirection: 'column', color: 'white', paddingTop: '15px'}}>
                            <p>What do you want to do with scanned machine?</p>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue={PromptResult.NoAction}
                                value={promptResultValue}
                                onChange={setUserPromptResult}
                                name="radio-buttons-group"
                            >
                                <FormControlLabel value={PromptResult.ChangeColor} control={<Radio />} label="Change color" />
                                <FormControlLabel value={PromptResult.GoToLog} control={<Radio />} label="Go to log" />
                            </RadioGroup>
                        </div>
                    )}
                    {
                        promptResultValue === PromptResult.ChangeColor && selectedMachine !== undefined && eventOption?.color === '' && (
                            <FinalOption onFinalClick={onFinalOptionClick} machine={selectedMachine} includeBackButton={false}/>
                        )}
                    {eventOption?.color?.length > 0 && (
                        <Reason onReasonClick={onReasonClick}/>
                    )}
                </QrCodeScannerComponentWrapper>
                </>
            )}
          </>            
        </>
    );
}