import styled from "styled-components";

type ContextMenuProps = {
  offset: number;
};

export const ContextMenuItem = styled.div<ContextMenuProps>`
  border-radius: 4px;
  box-sizing: border-box;
  position: absolute;
  width: 200px;
  background-color: #fff;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.1);

  @media (max-width: 560px) {
    width: 140px;
  }
  ul {
    list-style-type: none;
    box-sizing: border-box;
    margin: 0;
    padding: 5px;
  }
  ul li {
    padding: 8px 12px;
    border-radius: 4px;
    color: gray;
  }

  ul li:hover {
    cursor: pointer;
    color: black;
  }

  .menu-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .menu-sub-list {
    visibility: hidden;
    display: flex;
    background-color: #fff;
    padding: 8px;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgb(64 64 64 / 15%);
    position: absolute;
    left: 100%;
    z-index: 100;
    width: 200px;
    flex-direction: column;
  }
  .menu-sub-list.worker {
    top: 0px;
  }
  .menu-sub-list.status {
    top: 0px;
  }
  .menu-item {
    position: relative;
  }
  .menu-item:hover .menu-sub-list {
    visibility: visible;
  }
  .open-sub-menu {
    visibility: visible;
  }
  .menu-button {
    display: flex;
    align-items: center;
    justify-content:space-between;
  }

  .circle {
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 10px;
  }
  .c-r {
    background-color: red;
  }
  .c-g {
    background-color: green;
  }
  .c-y {
    background-color: yellow;
  }

  @media (max-width: 560px) {
    .menu-button {
      font-size: 11px;
    }

    ul li {
      padding: 4px 5px;
      font-size: 12px;
    }

    .menu-sub-list {
      width: 140px;
    }
  }
`;
