import styled from "styled-components";

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(8px);
  background-color: #000000db;
  width: 100%;
  height: 100vh;
  position: fixed;
  top:0;
  z-index: 99999999;
`;
const NavLinkMain = styled.a`
  display: block;
  color: aliceblue;
  padding: 20px 30px;
  border-bottom: 1px solid #ffffff;
  font-size: 20px;
  margin: 0 0px;
  width: 100%;
  cursor: pointer;
`;
const NavLink = styled.a`
  display: flex;
  justify-content: space-between;
  color: aliceblue;
  padding: 20px 30px;
  border-bottom: 1px solid #ffffff;
  font-size: 20px;
  margin: 0 0px;
  width: 100%;
  cursor: pointer;
`;
const MenuList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style-type: none;
`;

const MenuSubList = styled.ul`
  overflow: scroll;
  height: 50vh;
`;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  input {
    background: none;
    border: none;
    font-size: 20px;
    color: white;
    padding: 20px;
    border-bottom: 2px solid white;
    outline: none;
    &:focus-visible {
      border: none;
      border-bottom: 2px solid white;
    }
  }
`;

const Button = styled.button`
  font-size: 20px;
`;
const CloseButton = styled.div`
  position: absolute;
  top: 26px;
  right: 45px;
  width: 50px;
  height: 50px;
  span {&:before{
    content: '';
    position: absolute;
    width: 50px;
    height: 3px;
    background-color: white;
    top: 10px;
    transform: translateY(10px) rotate(45deg) scale(.9);
}
&:after{
  content: '';
  position: absolute;
  width: 50px;
  height: 3px;
  background-color: white;
  top: 10px;
  transform: translateY(10px) rotate(135deg) scale(.9);
}
  }
  }
`;

export {
  Content,
  MenuList,
  CloseButton,
  NavLink,
  MenuSubList,
  NavLinkMain,
  Div,
  Button,
};
