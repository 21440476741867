import styled from "styled-components";

export const MainContainer = styled.div`
  height: 100%;
  @media (max-width: 560px) {
    width: 100%;
  }
`;
export const BlocksGrid = styled.div`
  filter: drop-shadow(10px 10px 50px rgba(0, 0, 0, 0.6));
  display: flex;
  justify-content: space-between;
  @media (max-width: 560px) {
    padding: 15px 0;
    max-height: 100%;
    flex-wrap: wrap;
  }
  @media (min-width: 560px) {
    height: 100%;
  }
`;

export const Filter = styled.div`
  color: #ffffff;
  padding: 20px 10px;
  font-size: 20px;
  margin: 10px;
  display: flex;
  justify-content: space-between;
  @media (min-width: 360px) {
    padding: 5px 10px;
  }
`;

export const State = styled.div`
  color: white;
  font-size: 13px;
  padding: 10px;
  border-radius: 12px;
  display: flex;
  justify-content: space-evenly;
  width: 95px;
  align-items: center;
  &.state-active {
    border: 1px solid white;
  }
`;
export const Widget = styled.div`
margin-top: 15px;
  // padding: 10px 34px;
  width: 100%;
  background-image: url(${(props) => props.src})
  position: relative;
  border-radius: 20px;
  height: 120px;
  &.card-has-bg {
    background-size: 64%;
    background-repeat: no-repeat;
    background-position: center right;
    filter: contrast(130%);
   
  }
  .card-img-overlay {
    transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
     background: ${(props) => props.background};
    border-radius: 20px;
    padding: 12px 34px;
     height:120px;
   }


  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 560px) {
    width: 95%;
    padding: 0;
    height: unset;
    min-height: 88px;

    .card-img-overlay {
      padding: 16px 16px;
      height:unset;
     }
  }

  @media (max-width: 380px) {
    width: 85%;
    // padding: 10px 15px;
    height: unset;
    min-height: 90px;
  }

  @media (max-width: 280px) {
    min-height: 52px;
    .card-img-overlay {
      padding: 2px 16px;
     }
  }
`;

export const WidgetRedEmpty = styled.div`
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 10px 34px;
  display: flex;
  width: 97%;
  height: 136px;
  position: relative;
  background: linear-gradient(82.69deg, #f0c2db 74.53%, #ffffff 98.93%);
  border-radius: 20px;

  font-size: 30px;

  @media (max-width: 560px) {
    width: 95%;
    padding: 10px 15px;
    height: unset;
    min-height: 90px;
    font-size: 25px;
  }
`;
export const WidgetYellowEmpty = styled.div`
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 10px 34px;
  width: 97%;
  height: 136px;
  display: flex;
  position: relative;
  background: linear-gradient(87.57deg, #f0ecc2 54.25%, #ffffff 104.4%);
  border-radius: 20px;

  font-size: 30px;
  @media (max-width: 560px) {
    width: 95%;
    padding: 10px 15px;
    height: unset;
    min-height: 90px;
    font-size: 25px;
  }
`;
export const WidgetBlueEmpty = styled.div`
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 10px 34px;
  width: 97%;
  height: 136px;
  display: flex;
  position: relative;
  background: linear-gradient(87deg, #bed9eb 0%, #ffffff 100%);
  border-radius: 20px;

  font-size: 30px;
  @media (max-width: 560px) {
    width: 95%;
    padding: 10px 15px;
    height: unset;
    min-height: 90px;
    font-size: 25px;
  }
`;
export const Name = styled.span`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 120%;
  text-align: left;
  color: #363636;
  padding-bottom: 5px;
  display: flex;
  overflow-wrap: anywhere;
  width: 100%;

  @media (max-width: 560px) {
    font-size: 14px;
    line-height: unset;
    align-items: center;
    line-height: 14px;
    align-self: center;
  }
`;

export const SmallName = styled.span`
  font-size: 20px;
  font-weight: 700;
  color: #363636;
  @media (max-width: 560px) {
    font-size: 14px;
  }
`;

export const WidgetInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.space};

  color: #363636;
  font-family: "Open Sans";
  font-style: normal;
  align-items: flex-end;
  @media (max-width: 560px) {
    align-items: center;
  }
`;

export const NumberOfDays = styled.p`
  font-weight: 700;
  font-size: 30px;
  line-height: 83.4%;
  margin-right: ${(props) => props.margin};
  @media (max-width: 560px) {
    font-size: 15px;
  }
`;

export const Worker = styled.p`
  display: flex;
  align-items: center;
  font-size: 30px;
  @media (max-width: 560px) {
    font-size: 15px;
  }
`;

export const WorkerName = styled.p`
  font-size: 18px;
`;

export const WorkerMain = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;
export const NameDays = styled.span`
  font-weight: 700;
  font-size: 35px;
  line-height: 120%;
  @media (max-width: 560px) {
    font-size: 15px;
  }
`;

export const CodeSmall = styled.p`
  font-weight: 600;
  font-size: 30px;
  padding-bottom: 3px;
  line-height: 120%;
  display: flex;
  margin-right: 10px;
  flex-direction: column-reverse;
  align-items: center;
  color: ${(props) => props.color};
  @media (max-width: 560px) {
    font-size: 15px;
    margin-right: 20px;
  }
`;
export const Code = styled.p`
  font-weight: 600;
  font-size: 35px;
  line-height: 120%;
  text-transform: uppercase;
  display: flex;
  color: ${(props) => props.color};
  flex-direction: column-reverse;
  align-items: center;
  @media (max-width: 560px) {
    font-size: 15px;
  }
`;
export const WorkerAdd = styled.p`
  border: 1px solid #363636;
  border-radius: 50%;
  width: 27px;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  margin-left: 10px;
  @media (max-width: 560px) {
    font-size: 15px;
  }
`;

export const Months = styled.p`
  text-align: right;
  color: white;
  text-transform: uppercase;
  font-size: 35px;
  font-weight: 600;
  font-family: Open Sans;
  margin-right: 10px;

  @media (max-width: 560px) {
    font-size: 25px;
  }
  @media (max-width: 350px) {
    font-size: 25px;
    text-align: center;
  }
`;

export const ListOfMachines = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 98%;
  height: 100%;
  overflow-y: scroll;
`;

export const ListOfFlags = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  @media (max-width: 560px) {
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding: 0 10px;
  }
`;

export const Detail = styled.span`
  font-size: 23px;
  font-weight: 600;
  font-family: Open Sans;
  color: white;
  display: none;

  @media (max-width: 560px) {
    display: inline-block;
  }
`;

export const DurationAll = styled.span`
  font-weight: 700;
  font-size: 34px;
  line-height: 120%;
  @media (max-width: 560px) {
    font-size: 15px;
  }
`;
export const CountOfEntries = styled.p`
  font-weight: 700;
  font-size: 34px;
  line-height: 120%;
  @media (max-width: 560px) {
    font-size: 15px;
  }
`;

export const MainInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 97%;
  margin-bottom: 5px;
  @media (max-width: 560px) {
    min-width: 80%;
    flex-direction: column-reverse;
    justify-content: center;
  }
`;
export const Separator = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  @media (max-width: 560px) {
    width: 100%;
    &:nth-child(2) {
      margin-top: 10px;
    }
  }
  @media (min-width: 560px) {
    margin: 0 5px;
  }
`;

export const MachineName = styled.div`
  display: flex;
  border-bottom: 2px solid #363636;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
`;

export const NameBlock = styled.div`
  display: flex;
  align-items: center;
`;

export const ToolTip = styled.span`
  position: absolute;
  top: 0;
  font-size: 14px;
  background: #ffffff;
  color: black;
  padding: 5px 8px;
  border-radius: 5px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
  width: 100px;

  &::before {
    position: absolute;
    content: "";
    height: 8px;
    width: 8px;
    background: #ffffff;
    top: -3px;
    left: 50%;
    transform: translate(-50%) rotate(45deg);
    transition: all 0.3s;
  }
`;
export const ReasonIcon = styled.div`
  position: relative;
  width: 45px;
  height: 45px;
  &:hover ${ToolTip} {
    top: 30px;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    left: -39px;
  }

  @media (max-width: 560px) {
    width: 35px;
    height: 35px;
  }
`;
export const MachineCode = styled.span`
  color: #363636;
  font-size: 24px;
  border: 2px solid #363636;
  border-radius: 4px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  padding: 3px 5px;
  text-align: center;
  margin-right: 10px;
  margin-bottom: 5px;
  min-width: 65px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 560px) {
    font-size: 13px;
    height: 25px;
    min-width: 35px;
  }
`;
export const Block = styled.div`
  padding-top: 50px;
  height: 100%;
  @media (max-width: 560px) {
    width: 100%;
  }
`;
