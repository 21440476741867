import React, { useContext } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { LogContext } from "../../../../context/LogContext";
import { GlobalContext } from "../../../../context/GlobalContext";
import { AOO_API_HOST } from "../../../../env";
import axios from "axios";
export default function AlertDialog({ postId }) {
  const { alert, setAlert } = useContext(LogContext);
  const { machine } = useContext(GlobalContext);

  const handleClose = () => {
    setAlert(false);
  };
  const deletePost = async (postId) => {
    const params = {
      MachineId: machine?.Id,
      PostId: postId,
    };
    try {
      await axios.post(
        AOO_API_HOST + "/aoo-api/ProductionsLog/RemovePostById",
        params
      );
      setAlert(false);
    } catch (error) {
      console.error("Error :", error);
    }
  };
  return (
    <React.Fragment>
      <Dialog
        open={alert}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ backgroundColor: "#383838",color:"white" }}
        >
          {"Do you want to delete post?"}
        </DialogTitle>
        <DialogContent style={{ backgroundColor: "#383838" }}>
          <DialogContentText id="alert-dialog-description"></DialogContentText>
        </DialogContent>
        <DialogActions  style={{backgroundColor : "#383838"}}>
          <Button onClick={handleClose}>Disagree</Button>
          <Button
            onClick={() => {
              deletePost(postId);
            }}
            autoFocus
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
