import React, { useRef, useEffect } from "react";
import Widget from "./Machine";
import { ListOfMachines } from "./MachineStyles";
function MachineList({ background, data }) {
  const listRef = useRef(null);
  var cycle;
  useEffect(() => {
    let addr = new URL(window.location.href);
    addr.searchParams.entries();
    for (let key of addr.searchParams.keys()) {
      if (key === "cycle") {
        cycle = addr.searchParams.get(key);
        if (cycle !== undefined && cycle != null) {
          cycle = cycle * 1.0;
        }
      }
    }

    var smoothScroll = {
      timer: null,

      stop: function () {
        clearTimeout(this.timer);
      },

      scrollToTop: function () {
        var settings = {
          duration: cycle * 1000, // Adjust duration based on cycle duration
          easing: {
            outQuint: function (x, t, b, c, d) {
              return c * ((t = t / d - 1) * t * t * t * t + 1) + b;
            },
          },
        };

        var percentage;
        var startTime;
        var node = listRef.current.firstElementChild;
        var nodeHeight = node.offsetHeight;
        var height = listRef.current.scrollHeight;
        var windowHeight = listRef.current.clientHeight;
        var offset = listRef.current.scrollTop;
        var delta = 0 - offset;
        var bottomScrollableY = height - windowHeight;
        var targetY =
          bottomScrollableY < delta
            ? bottomScrollableY - (height - nodeHeight + offset)
            : delta;

        startTime = Date.now();
        percentage = 0;

        if (this.timer) {
          clearInterval(this.timer);
        }

        function step() {
          var yScroll;
          var elapsed = Date.now() - startTime;

          if (elapsed > settings.duration) {
            clearTimeout(this.timer);
          }

          percentage = elapsed / settings.duration;

          if (percentage > 1) {
            clearTimeout(this.timer);
          } else {
            yScroll = settings.easing.outQuint(
              0,
              elapsed,
              offset,
              targetY,
              settings.duration
            );
            listRef.current.scrollTo(0, yScroll);
            this.timer = setTimeout(step, 10);
          }
        }

        this.timer = setTimeout(step, 10);
      },
      scrollToBottom: function () {
        var settings = {
          duration: cycle * 1000,
          easing: {
            outQuint: function (x, t, b, c, d) {
              return c * ((t = t / d - 1) * t * t * t * t + 1) + b;
            },
          },
        };
        var percentage;
        var startTime;
        var node = listRef.current.lastElementChild;
        var nodeTop = node.offsetTop;
        var nodeHeight = node.offsetHeight;
        var height = listRef.current.scrollHeight;
        var windowHeight = listRef.current.clientHeight;
        var offset = listRef.current.scrollTop;
        var delta = nodeTop + nodeHeight - windowHeight - offset;
        var bottomScrollableY = height - windowHeight;
        var targetY =
          bottomScrollableY < delta ? bottomScrollableY : delta + offset;

        startTime = Date.now();
        percentage = 0;

        if (this.timer) {
          clearInterval(this.timer);
        }

        function step() {
          var yScroll;
          var elapsed = Date.now() - startTime;

          if (elapsed > settings.duration) {
            clearTimeout(this.timer);
          }

          percentage = elapsed / settings.duration;

          if (percentage > 1) {
            clearTimeout(this.timer);
          } else {
            yScroll = settings.easing.outQuint(
              0,
              elapsed,
              offset,
              targetY,
              settings.duration
            );
            listRef.current.scrollTo(0, yScroll);
            this.timer = setTimeout(step, 10);
          }
        }

        this.timer = setTimeout(step, 10);
      },
    }; //
    var position = true;
    if (cycle !== undefined && cycle != null) {
      const interval = setTimeout(() => {
        if (position) {
          smoothScroll.scrollToBottom();
          position = false;
        } else {
          smoothScroll.scrollToTop();
          position = true;
        }
      }, 2000);
      return () => clearInterval(interval);
    }
  }, []);



  return (
    <ListOfMachines ref={listRef}>
      {data?.map((machine, index) => (
        <Widget
          background={background}
          key={machine.Id}
          machine={machine}
          index={index}
        />
      ))}
    </ListOfMachines>
  );
}

export default MachineList;
