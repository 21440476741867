import React from "react";

const Clip = () => {
  return (
    <label htmlFor="file-upload" style={{ marginRight: "15px" }}>
      <svg
        width="33"
        height="31"
        viewBox="0 0 43 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1028_1270)">
          <path
            d="M17.4072 37.3635C24.6197 30.5325 30.2616 25.072 36.5371 19.2103C38.6089 17.1495 39.9211 15.1324 40.4736 13.1592C41.4405 9.43566 39.9539 6.21123 37.3659 3.688C34.8796 1.3202 32.2208 0.202064 29.3893 0.333631C26.5578 0.465197 23.7609 1.8245 20.9984 4.41153L3.38775 21.2492L6.28834 23.9459L23.8989 7.17393C25.706 5.5377 27.8564 3.9951 30.3216 4.21416C34.0777 4.71636 37.3948 9.0947 36.5372 12.1726C35.4497 14.9034 33.9505 16.1489 31.8755 18.125C25.5636 24.121 20.6421 28.7578 14.5067 34.601C12.2009 36.6076 10.7097 37.1509 8.56747 35.2587C7.46249 34.2063 6.97907 33.1759 7.11718 32.1674C7.27044 30.9907 7.96988 30.1768 8.7746 29.405L24.8658 14.08C25.6009 13.3786 27.275 12.0325 27.9735 12.633C28.5094 13.817 27.1293 14.9472 26.4543 15.5928L11.6752 29.6681L14.5066 32.4305L29.3547 18.3553C31.9122 15.8431 33.7496 12.7733 30.8741 9.9364C27.6939 7.3368 24.3437 9.0723 21.9652 11.3176L5.87401 26.6426C4.21659 28.2211 3.27271 29.9312 3.04255 31.7728C2.86097 34.3546 4.0832 36.4271 5.7359 38.0212C7.28938 39.4926 8.96766 40.3077 11.0537 40.3232C13.5616 40.2276 16.0276 38.6544 17.4072 37.3635Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_1028_1270">
            <rect
              width="42"
              height="40"
              fill="white"
              transform="translate(0.897705 0.323242)"
            />
          </clipPath>
        </defs>
      </svg>
    </label>
  );
};

export default Clip;
