import React, { useState, useEffect,useContext} from "react";
import { LogContext } from "../../../../context/LogContext";
import { PostExpert } from "../log.typing";
import { AOO_API_HOST } from "../../../../env";
import axios from "axios";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";



export function SelectUserDialog() {
  const [users, setUsers] = useState<Array<PostExpert>>([]);
  const {  setPoster,noUserSelect, setNoUserSelect } = useContext(LogContext);
  useEffect(() => {
    const fetchData = async (url) => {
      try {
        const response = await axios.get(
          AOO_API_HOST + "/aoo-api/ProductionsLog/" + url
        );
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData("GetWorkers");
  }, []);

  const handleClose = () => {
    setNoUserSelect(false)
  };

  
  const handleListItemClick = (user) => {
    setPoster(user)
    setNoUserSelect(false)
  };

  return (
    <Dialog onClose={handleClose} open={noUserSelect}  >
      <DialogTitle style={{ background: 'gray', color:"white" }}>Set user to post</DialogTitle>
      <List sx={{ pt: 0 }} style={{ background: 'gray' }}>
        {users.map((user) => (
          <ListItem key={user.Id} style={{ background: 'gray', color:"white", padding:"0",  paddingBottom:"5px" }} className="custom-menu-item ">
            <ListItemButton
              autoFocus
              onClick={() => handleListItemClick(user)}
            > {user.FullName}</ListItemButton>
           
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}
