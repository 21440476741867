import Routes from "./Routes";
import {MenuDataType} from "./NavbarTypes";
export const MenuData: MenuDataType = [
  {
    title: "Main",
    link: Routes.Main,
    id: "",
    sections: [
      {
        title: "Inspection Flags",
        link: Routes.Inspection,
        id: "",
        section: [
          {
            title: "Set new Inspection flag",
            link: Routes.SetFlag,
            id: "",
            sections: [
              {
                title: "Select group",
                link: "main/setflag/group",
                id: "",
              },
              {
                title: "Select machine",
                link: "main/setflag/machine",
                id: "",
              },
              {
                title: "Select worker",
                link: "main/setflag/worker",
                id: "",
              },
              {
                title: "View result",
                link: "main/setflag/result",
                id: "",
              },
            ],
          },
        ],
      },
      {
        title: "Resolved Flag History",
        link: Routes.Resolved,
        id: "",
      },
      {
        title: "Remote Scan",
        id: "",
        link: Routes.Scan,
        sections: [
          {
            title: "Browse Machines",
            link: Routes.BrowseMachines,
            id: "",
            section: [
              {
                title: "Select group",
                link: Routes.SelectGroup,
                id: "",
              },
              {
                title: "Select machine",
                link: Routes.SelectMachine,
                id: "",
              },
            ],
          },
          {
            title: "Scan QR Code",
            link: Routes.ScanQrCode,

            id: ""
          }
        ]
      },
      {
        title: "KPI Machine Exclusion list",
        link: Routes.Availability,
        id: "",
        section: [
          {
            title: "Manage Exception List ",
            link: Routes.ManageAvailability,
            id: "",
          },
        ],
      },
      {
        title: "Machine Log Entry",
        link: Routes.MachineLog,
        id: "",
        section: [
          {
            title: "Log options ",
            link: Routes.LogOptions,
            id: "",
          },
        ],
      },
      {
        title: "Machine Break Down Report",
        link: Routes.MachineBreakdownReport,
        id: "",
        section: [
          {
            title: "Log options ",
            link: Routes.LogOptions,
            id: "",
          },
        ],
      },
    ],
  },
];
