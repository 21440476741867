import styled from "styled-components";

export const Menu = styled.div`
  //   width: 120px;
  margin: 0 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border: 1px solid #303336;
  background-color: #1a1d21;
  border-radius: 7px;
  padding: 10px;
  //   height: 50px;

  button {
    background: none;
    border: none;
    margin: 0 3px;
    cursor:pointer;
  }
`;

export const MenuMain = styled.div`
  background-color: "#222529";
  position: absolute;
  right: 0;
  top: 10px;
`;
