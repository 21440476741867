import React, {useCallback, useEffect, useState} from "react";
import useSWR from "swr";
import axios from "axios";
import { AOO_API_HOST } from "../env";
interface WorkerDataItem {
  FullName: string;
  Initials: string;
}

interface CoverImage {
  ImageData: Uint8Array;
  Content: string;
}
interface MachinesData {
  RedMachines: Array<MachinesDataItem>;
  YellowMachines: Array<MachinesDataItem>;
  BlueMachines: Array<MachinesDataItem>;
  GreenMachines: Array<MachinesDataItem>;
}

interface MachinesDataItem {
  Id: string;
  EventId: string;
  Name: string;
  EventDuration: number;
  EventsCount: number;
  MachineCode: string;
  Workers: WorkerDataItem[];
  MachineReason: string;
  IsUrgent: boolean | null;
  EventState: string;
  DaysToFix: number | null;
  Cover: CoverImage | null;
}

const Item: Array<WorkerDataItem> = [
  {
    FullName: "Worker",
    Initials: "W",
  },
];
//default object TODO default
const DataItem = {
  Id: "",
  EventId: "",
  Name: "laveuse_peinture(e)",
  EventDuration: 1,
  EventsCount: 1,
  MachineCode: "c1",
  Workers: Item,
  MachineReason: "",
  IsUrgent: true,
  EventState: "R",
  DaysToFix: null,
  Cover: null,
};

const Data: MachinesData = {
  RedMachines: new Array<MachinesDataItem>(DataItem),
  YellowMachines: new Array<MachinesDataItem>(DataItem),
  BlueMachines: new Array<MachinesDataItem>(DataItem),
  GreenMachines: new Array<MachinesDataItem>(DataItem),
};

const MachinesContext = React.createContext({
  propData: { ...Data },
  isBeingFetched: false,
});

function MachinesProvider({ children }) {
  const [isBeingFetched, setIsBeingFetched] = useState(false);
  let [fetched, setFetched] = useState({ ...Data }); //initialize state

  const fetcher = useCallback(async (url) => {
    setIsBeingFetched(true);
    try {
      const res = await axios
          .request({
            url: url,
            method: "get",
            baseURL: AOO_API_HOST,
            params: {},
            //data:{},
            responseType: "json",
          });
      return res.data;
    }
    finally {
      setIsBeingFetched(false);
    }
    }, []);

  const { data } = useSWR(
    "/aoo-api/Productions/get-machines-status-events",
    fetcher,
    { refreshInterval: 10000 }
  ); //fetch data every 10 seconds

  useEffect(() => {
    //if data has changed, then update state
    if (data !== undefined) {
      setFetched(data);
    }
  }, [data]);
  return (
    <MachinesContext.Provider
      value={{
        propData: fetched,
        isBeingFetched: isBeingFetched
      }}
    >
      {children}
    </MachinesContext.Provider>
  );
}

function useMachinesContext() {
  const context = React.useContext(MachinesContext);
  if (context === undefined) {
    throw new Error("Error...");
  }
  return context;
}

export { MachinesProvider, useMachinesContext, MachinesContext, MachinesData };
