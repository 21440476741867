import styled from "styled-components";

const Navs = styled.div`
  border-bottom: 1px solid #ffffff;
  position: fixed;
  background-color: #000000;
  padding: 5px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index:99;
  width:100%;
  height: 10%;
  @media (min-width:561px) {
      display: none!important;
  }
`;

export const QrCodeScannerIconWrapper = styled.div`
width: 45px;
height: 45px;
`;

export const QrCodeScannerComponentWrapper = styled.div`
  display: flex;
  color: white;
  flex-direction: column;
  width: 100%;
  height: 90%;

  label {
    color: white;
  }
  
  video {
    width: 95%;
    max-height: 1080px;
  }
`;

export const ReasonStyled = styled.div`
  padding-top: 2%;
  height: 90%;
  width: 100%;
`;

export const Burger = styled.div`
  z-index: 100;
  position: fixed;
  width: 50px;
  margin-top: 1.35%;
  margin-left: 90.5vw;
  .menu {
    display:grid;
    grid-template-rows: 1fr 1fr;
    cursor: pointer;
    width: 50px;
    height: 40px;
  }
  .menu-clicked {
  }
  .menu-global {
    backface-visibility: hidden;
    border-top: solid white;
    transition: all 0.55s ease 0s;
    height: 3px;
    background: white;
    border-radius: 5px;
  }
  .menu-top {
    //top: 0;

  }
  .menu-middle {
   // top: 18px;

  }
  .menu-bottom {
  //  top: 36px;
  }
  .menu-top-click {

    backface-visibility: hidden;
    transform: rotate(45deg) translateX(13px) translateY(13px);
    transition: 0.55s 0.5s;
  }
  .menu-middle-click {
    opacity: 0;
  }
  .menu-bottom-click {
    backface-visibility: hidden;
    transform: rotate(-45deg) translateX(13px) translateY(-13px);
    transition: 0.55s 0.5s;
  }

  @media (max-width: 560px) {
    margin-top: 2vh;
    margin-left: 85%;
  }
`;

const MiddleBar = styled.span`
  width: 35px;
  height: 2px;

  background-color: white;
  position: absolute;
  opacity: 1;
  top: 24px;

  @media (max-width: 560px) {
    width: 35px;
  }
`;

const NavItems = styled.div`
  background-color: #000000;
  z-index: 1;
  transition: all 1s;
  padding: 0 20px;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  transform: translateY(-100%);
`;

export const NavUl = styled.ul`
  margin-top: 25px;
  @media (max-width: 560px) {
    margin-top: none;
  }
`;
const NavLink = styled.a`
  display: block;
  color: aliceblue;
  padding: 20px 30px;
  border-bottom: 1px solid #ffffff;
  font-size: 28px;
  margin: 0 0px;
  width: 100%;
  cursor: pointer;
  @media (max-width: 560px) {
    font-size: 20px;
  }
  @media (max-width: 360px) {
    padding: 20px;
  }
`;
const StatusLink = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: aliceblue;
  padding: 20px 30px;
  border-bottom: 1px solid #ffffff;
  font-size: 20px;
  margin: 0 0px;
  width: 100%;
  cursor: pointer;
`;
const Content = styled.div`
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  justify-content: center;

  @media (max-width: 560px) {
    // flex-direction: column;
  }
`;
const ContentLog = styled.div`
  overflow-y: scroll;
  margin-bottom: 10px;
`;
const Confirmation = styled.p`
  color: white;
  text-align: center;
  margin-bottom: 5px;
`;
const OKConfirm = styled.span`
  color: #1a5e9e;
`;

const Title = styled.div`
  color: #ffffff;
  padding: 20px 30px;
  font-size: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 360px) {
    padding: 10px;
  }
`;
const Switch = styled.div`
  color: #ffffff;
  padding: 20px 30px 0 30px;
  font-size: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 720px) {
    padding: 10px;
  }

  input[type="checkbox"] {
    height: 0;
    width: 0;
    visibility: hidden;
   
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 80px;
    height: 40px;
    background: grey;
    display: block;
    border-radius: 100px;
    position: relative;
  }

  label:after {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    width: 30px;
    height: 30px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
  }

  input:checked + label {
    background: #1a5e9e;
    border: 1px solid #28dfdf;
  }

  input:checked + label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
  }

  label:active:after {
    width: 30px;
  }
`;

const Filter = styled.div`
  color: #ffffff;
  padding: 20px 10px;
  font-size: 20px;
  margin: 10px;
  display: flex;
  justify-content: space-between;
  @media (min-width: 360px) {
    padding: 5px 10px;
  }
`;
const MachineGroup = styled.div`
  background-color: #1a5e9e;
  border: 1px solid #28dfdf;
  padding: 10px 20px;
  min-width: calc(100% / 2 - 20px);
  margin: 10px;
  text-align: center;
  border-radius: 6px;
  font-size: 24px;
  color: #ffffff;
  cursor: pointer;
  min-height: 55px;
`;
const GroupFamily = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const MachineFamily = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  @media (max-width: 560px) {
    padding-bottom: 206px;
  }
`;

const Machine = styled.div`
  margin-bottom: 15px;
  padding: 6px 15px;
  width: 48%;
  position: relative;
  border-radius: 20px;
  border: 3.4px solid #161616;
  position: relative;
  height: 90px;
  margin: 0 50px;
  margin-bottom: 5px;
  @media (max-width: 560px) {
    margin: 0 0px;
  }
`;
const MachineName = styled.div`
  display: flex;
  border-bottom: 1px solid #161616;
  align-items: center;
  margin-bottom: 5px;
  font-size: 25px;
  font-weight: 600;
  @media (max-width: 560px) {
    font-size: 15px;
  }
`;

const MachineCode = styled.span`
  color: black;
  font-size: 24px;
  border: 1px solid #161616;
  padding: 5px 5px;
  text-align: center;
  margin-right: 10px;
  margin-bottom: 5px;
  min-width: 55px;
  height: 35px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 560px) {
    min-width: 40px;
    height: 23px;
    font-size: 14px;
  }
`;

const FlagCount = styled.span`
  color: black;
  font-size: 21px;
  border: 1px solid #161616;
  padding: 3px 5px;
  text-align: center;
  margin-bottom: 5px;
  width: 45px;
  justify-content: center;
  height: 30px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
`;

const BottomInfo = styled.div`
  display: flex;
  align-items: center;
  height: 34px;
`;

const Worker = styled.div`
  display: flex;
  lign-items: center;
  font-weight: 700;
`;
const WorkerFamily = styled.div`
  margin: 0 10px;
`;
const WorkerW = styled.div`
  margin-bottom: 15px;
  background: linear-gradient(87.57deg, #bdd9f2 54.25%, #ffffff 104.4%);
  height: 106px;
  padding: 8px 15px;
  width: 92%;
  position: relative;
  border-radius: 20px;
  border: 3.4px solid #161616;
`;

const MultiSelect = styled.div`
  border-bottom: 1px solid #ffffff;
  display: flex;
  width: 100%;
`;

const Confirm = styled.button`
  width: 100%;
  height: 64px;
  background: #1a5e9e;
  border: 1px solid #28dfdf;
  border-radius: 9px;
  font-size: 25px;
  text-transform: uppercase;
  font-weight: 600;
  color: #ffffff;
  top: 85%;
`;
const WorkerName = styled.p`
  width: 10px;
  font-size: 12px;
  font-weight: 600;
`;
const Duration = styled.span`
  font-weight: 700;
  position: absolute;
  left: 42%;
`;
const WorkerFullName = styled.div`
  display: flex;
  border-bottom: 1px solid #161616;
  align-items: center;
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: 600;
  justify-content: space-between;
`;

const CancelMulti = styled.a`
  border-bottom: 1px solid #ffffff;
  color: white;
  width: 66%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Selected = styled.p`
  width: 100px;
  color: #83c3ff;
`;

const CancelSelected = styled.p`
  color: #f12020;
  font-size: 13px;
`;

const Phone = styled.span`
  font-weight: 700;
  position: absolute;
  left: 32%;
  font-size: 18px;
`;
const State = styled.div`
  color: white;
  font-size: 13px;
  padding: 10px;
  border-radius: 12px;
  display: flex;
  justify-content: space-evenly;
  width: 95px;
  align-items: center;
  &.state-active {
    border: 1px solid white;
  }
`;

const Button = styled.div`
  height: 100px;
  background: #1a5e9e;
  border: 1px solid #28dfdf;
  border-radius: 9px;
  margin: 22px 0 15px;
  text-align: center;
  padding: 15px 0;
  color: #ffffff;
  font-family: "Open Sans";
  font-weight: 600;
  font-size: 25px;
  text-transform: uppercase;
  line-height: 80px;
`;

export {
  Button,
  State,
  Navs,
  MiddleBar,
  NavItems,
  NavLink,
  CancelSelected,
  OKConfirm,
  Content,
  Title,
  Filter,
  MachineGroup,
  GroupFamily,
  MachineFamily,
  Machine,
  Phone,
  MachineName,
  FlagCount,
  MachineCode,
  BottomInfo,
  Worker,
  WorkerW,
  Confirm,
  Confirmation,
  WorkerName,
  WorkerFamily,
  Duration,
  WorkerFullName,
  MultiSelect,
  CancelMulti,
  Selected,
  StatusLink,
  Switch,
  ContentLog,
};
