import { useEffect, useState } from "react";
import {HubConnectionBuilder, HubConnectionState, LogLevel} from "@microsoft/signalr";

export const useSignalRConnection = (url, machineId) => {
  const [connection, setConnection] = useState(null);

  useEffect(() => {
    if (machineId) {
      if (!connection)
      {
        const newConnection = new HubConnectionBuilder()
            .withUrl(url)
            .configureLogging(LogLevel.Information)
            .build();

        setConnection(newConnection);

        return () => {
          if (newConnection && newConnection.state === HubConnectionState.Connected) {
            newConnection.stop();
          }
        };
      }
      return () => {
        if (connection && connection.state === HubConnectionState.Connected) {
          connection.stop();
        }
      }
    }
  }, [connection, url, machineId]);

  return connection;
};
