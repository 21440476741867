import { AOO_API_HOST } from "../../env";
import axios from "axios";

let setStatus = (status, id) => {
  let url =
    AOO_API_HOST +
    `/aoo-api/Productions/post-status-event?machineId=` +
    id +
    "&status=" +
    status;

  axios.post(url).then((response) => response);
};

function postAction(url, data) {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  axios
    .post(url, JSON.stringify(data), customConfig)
    .then((response) => response);
}

function postWaitAction(url, data) {
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  // Return the axios promise
  return axios.post(url, JSON.stringify(data), customConfig);
}

function postQueryAction(action, data) {
  var url = AOO_API_HOST + `/aoo-api/Productions/${action}=` + data;
  axios.post(url).then((response) => response);
}
function UrgencyOption(current) {
  const list = [
    { color: "Y", Title: "Turn to Light Yellow", isUrgent: false },
    { color: "Y", Title: "Turn to Dark Yellow", isUrgent: true },
    { color: "R", Title: "Turn to Light Red", isUrgent: false },
    { color: "R", Title: "Turn to Dark Red", isUrgent: true },
    { color: "B", Title: "Turn to Dark Blue", isUrgent: true },
    { color: "B", Title: "Turn to Light Blue", isUrgent: false },
  ];

  const updatedList = current.color === "B"
    ? [
        ...list,
        {
          color: "G",
          Title: "Turn to Green",
          isUrgent: true,
          alert: true,
        },
      ]
    : list;

  const filteredItems = updatedList.filter(
    (option) =>
      !(option.color === current.color && option.isUrgent === current.isUrgent)
  );

  return filteredItems;
}

const getMachineStatus = (machineId) => {
  let url = AOO_API_HOST + `/aoo-api/Productions/GetMachineStatusAfterFix/` + machineId
  
  return axios.get(url)
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error fetching machine status:", error);
      return null;
    });
}

export { setStatus, postAction, UrgencyOption, postWaitAction, postQueryAction, getMachineStatus };
