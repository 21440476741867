import { Switch } from "../../NavbarStyles";
import React from "react";

function Inspection({setIsChecked,isChecked}) {

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  return (
    <>
      <Switch>
        Add as an inspector
        <input
          type="checkbox"
          id="switch"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <label htmlFor="switch">Toggle</label>
      </Switch>
    </>
  );
}

export default Inspection;
