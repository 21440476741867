import React, { useState, useRef, useEffect, useContext } from "react";
import {
  MainInput,
  GeneralLabel,
  ContainerDiv,
  Btn,
  EvenDiv,
} from "../style/UploaderStyles";
import { Icons } from "../icons/Icons";
import { isBrowser } from "react-device-detect";
import { PostImage, UrlObject } from "../log.typing";
import { ImgContainer } from "./ImagePreview";
import { LogContext } from "../../../../context/LogContext";
import {createFileUrl} from "../api/FileTypeEnum";

const Uploader = ({ editMode, editImages, isReadOnly }) => {
  const fileInputRef = useRef(null);
  const [images, setImages] = useState([]);
  const [show, setShow] = useState(false);
  const {
    setFiles,
    content,
    setContent,
    setDeleted,
    deleted,
    reset,
    setReset,
    fileToPostImage,
  } = useContext(LogContext);

  function fileDragHover(e) {
    e.stopPropagation();
    e.preventDefault();
    const fileDrag = document.getElementById("file-drag");
    if (e.type === "dragover") fileDrag.classList.add("hover");
    else {
      fileDrag.classList.add("modal-body");
      fileDrag.classList.add("file-upload");
    }
  }

  useEffect(() => {
    if (reset) {
      setImages([]);
      setFiles([]);
      setContent([]);
      setShow(false);
      setReset(false);
    }
  }, [reset, setContent, setFiles, setReset]);

  useEffect(() => {
    if (editImages && editImages.length > 0 && editMode) {
      setShow(true)
    }
    else{
      setShow(false)
    }
  }, [editMode, setShow, editImages]);
  
  useEffect(() => {
    if (editImages !== null && editImages !== undefined) {
      setContent(editImages);
    }
  }, [editImages, setContent]);

  function fileSelectHandler(e) {
    const fileInput = e.target;
    const maxFiles = 6;

    if (fileInput.files.length <= maxFiles) {
      const targetFiles = e.target.files || e.dataTransfer.files;
      const newFiles = Array.from(targetFiles);
      setImages([...images, ...newFiles]);
      setFiles([...images, ...newFiles]);
      setContent(Array.from(targetFiles).map((file: File) => fileToPostImage(file)));
      setShow(true);
      fileDragHover(e);
    }
  }

  const removeFileByName = (nameToRemove) => {
    const updatedContent = content.filter((file) => file.FileName !== nameToRemove);
    setContent(updatedContent);

    if (
      editImages !== undefined &&
      editImages !== null &&
      editImages.length > 0
    ) {
      setImages((prevImages) => [...prevImages, ...editImages]);
      
      const foundElement = editImages.find(
        (obj) => obj["FileName"] === nameToRemove
      );

      setDeleted([...deleted, foundElement.Id]);
    }

    const updatedFiles = images.filter((file) => file.name !== nameToRemove);
    setImages(updatedFiles);
    setFiles(updatedFiles);

    if (updatedFiles.length === 0 && updatedContent.length === 0) {
      setShow(false);
    }
  };
  
  return (
    <>
      {!show && (
        <ContainerDiv style={{ height: "100%" }}>
          <MainInput
            id="file-upload"
            type="file"
            name="fileUpload"
            accept="*"
            ref={fileInputRef}
            multiple
            onChange={fileSelectHandler}
          />
          <GeneralLabel
            htmlFor="file-upload"
            id="file-drag"
            onDragOver={(e) => fileDragHover(e)}
            onDragLeave={(e) => fileDragHover(e)}
            onDrop={(e) => fileSelectHandler(e)}
          >
            <EvenDiv id="start">
              <i className="fa fa-download" aria-hidden="true"></i>
              {isBrowser && (
                <div style={{ marginBottom: "5px",fontSize:"25px" }}>
                  Select a file or drag here
                </div>
              )}

              <div id="notimage" className="hidden">
                Please select an image
              </div>

              {isBrowser && (
                <Icons
                  value={"clip"}
                  height={20}
                  width={25}
                  color={"#ffffff"}
                />
              )}
            </EvenDiv>
          </GeneralLabel>
        </ContainerDiv>
      )}
      {show && (
        <ImgContainer isReadOnly={isReadOnly} content={content} removeFileByName={removeFileByName} />
      )}
    </>
  );
};

export default Uploader;
