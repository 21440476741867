import React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const ColorChange = ({colorChangeString, sideSize}) => {
    const UnparseColorChangeString = (colorsString, sideSize) => {
        let statuses = colorsString.split(' ')
        let c3 = "circle-stat " + statuses[0];
        let c2 = "circle-stat " + statuses[1];

        return (
            <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{width: `${sideSize}px`, height: `${sideSize}px`}} className={c3}></div>
                    <ArrowForwardIcon style={{ color: "white", width: sideSize }} />{" "}
                <div style={{width: `${sideSize}px`, height: `${sideSize}px`}} className={c2}></div>
            </div>
        )
    }

    return (
    UnparseColorChangeString(colorChangeString, sideSize)
    )
}

export default ColorChange;
