import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Title, NavLink, Content } from "../NavbarStyles";
import { IExcludedMachines } from "../../../global.typing";
import { AOO_API_HOST } from "../../../env";
import Swipeable from "./Swipeable";

function Availability() {
  const navigate = useNavigate();
  let [fetched, setFetched] = useState<Array<IExcludedMachines>>([]);

  const fetchData = async (url) => {
    try {
      const response = await axios.get(AOO_API_HOST + url);
      setFetched(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData("/aoo-api/Productions/GetExcludedMachines?isAvailable=false");
  }, []);

  const updateList = async () => {
    fetchData("/aoo-api/Productions/GetExcludedMachines?isAvailable=false");
  };

  return (
    <>
      <NavLink
        onClick={() => {
          navigate("/main");
        }}
        style={{ marginRight: 30 }}
      >
        Back to menu
      </NavLink>
      <NavLink
        onClick={() => {
          navigate("/main/availability/manage");
        }}
        style={{ marginRight: 30 }}
      >
      Exclude machine
      </NavLink>
      <Title>Excluded machines</Title>
      <Content
        style={{
          height: 60 + "vh",
          width: "100%",
          paddingLeft: "30px",
          paddingRight: "30px",
        }}
      >
        {fetched?.map((item: IExcludedMachines, index: number) => (
          <Swipeable machine={item} key={item.Id} updateList={updateList} />
        ))}
      </Content>
    </>
  );
}

export default Availability;
