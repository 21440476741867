import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { MachineGroup, Title, GroupFamily, NavLink } from "../../NavbarStyles";
import { AOO_API_HOST } from "../../../../env";

function GroupOption() {
  const navigate = useNavigate();
  const [result, setResult] = useState([]);

  useEffect(() => {
    const url = `${AOO_API_HOST}/aoo-api/Productions/machine-groups`;

    const fetchMachines = async () => {
      try {
        const response = await axios.get(url);
        setResult(response.data);
      } catch (error) {
        // Handle error
        console.error(error);
      }
    };

    fetchMachines();
  }, []);

  const handleClick = (event, group) => {
    const stat = {
      group: group,
    };
    navigate("/main/setflag/machine", { state: stat });
  };

  return (
    <GroupFamily>
      <NavLink
        onClick={() => {
          navigate("/main/inspection");
        }}
        style={{ marginRight: 30 }}
      >
        Back to menu
      </NavLink>

      <Title>Choose machine group</Title>
      <MachineGroup onClick={(e) => handleClick(e, " ")}>all</MachineGroup>
      {result.map((group, key) => (
        <MachineGroup
          key={key}
          onClick={(e) => handleClick(e, group.Id)}
        >
          {group.GroupName.length !== 1 ? group.GroupName : "(none)"}
        </MachineGroup>
      ))}
    </GroupFamily>
  );
}

export default GroupOption;
