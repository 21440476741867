import { Machine } from "./KPI_style.js";

function MachineTab({machine,isOrange,type}) {
  return (
    <Machine isOrange={isOrange}>
      <div className="machineCode">{machine.MachineCode}</div>
      <div className="percent">{machine.Metric} {type === "R"? "%" : ""}</div>
    </Machine>
  );
}
export { MachineTab };
