import React, { useEffect, useState, useContext } from "react";
import { GlobalContext } from "../../../context/GlobalContext";
import axios from "axios";
import { AOO_API_HOST } from "../../../env";
import { NavLink, MenuSubList, NavLinkMain } from "../MobileOptionsStyles";
import {
  postWaitAction,
  postAction,
  UrgencyOption,
} from "../../Widgets/WidgetActions";
import DisplayIcon from "../../Widgets/FailureIcon";
export default function Status({ id, status, machine, setNav }) {
  var response;
  const [stat, setStat] = useState("");
  const [reason, setReason] = useState("");
  const [reasonlist, setReasonlist] = useState([]);
  let options = UrgencyOption(status);
  const { setIsActive, setLog, setEventOption } = useContext(GlobalContext);
  useEffect(() => {
    const url = AOO_API_HOST + "/aoo-api/Productions/get-failure-reasons";
    axios.get(url).then((response) => setReasonlist(response.data));
  }, []);

  function AllowReason(urgent, color) {
    if (!urgent && color != "B") {
      setReason(true);
    }
  }
  return (
    <MenuSubList>
      {(() => {
        if (!reason) {
          return options === undefined
            ? null
            : options.map((option, key) => (
                <li key={option.Title}>
                  <NavLink
                    onClick={() => {
                      setEventOption({
                        color: option.color,
                        isUrgent: option.isUrgent,
                      });
                      setLog(true);
                      setIsActive(false);
                      // response = postWaitAction(
                      //   AOO_API_HOST +
                      //     `/aoo-api/Productions/post-status-urgency`,
                      //   {
                      //     MachineId: id,
                      //     Status: option.color,
                      //     IsUrgent: option.isUrgent,
                      //   }
                      // );
                      // response.then((response) => {
                      //   const responseData = response.data;
                      //   setStat(responseData.Id);
                      // });
                      // AllowReason(option.isUrgent, option.color);
                    }}
                  >
                    {option.Title}
                  </NavLink>
                </li>
              ));
        } else {
          return (
            <li>
              <NavLinkMain>
                Current reason : {machine.MachineReason}
              </NavLinkMain>
              <NavLinkMain>Please assign a reason to machine :</NavLinkMain>
              <MenuSubList>
                {reasonlist === undefined
                  ? null
                  : reasonlist.map((res, key) => (
                      <li key={res.Id}>
                        <NavLink
                          onClick={() => {
                            postAction(
                              AOO_API_HOST +
                                `/aoo-api/Productions/post-failure-reason`,
                              { EventId: stat, ReasonId: res.Id }
                            );
                          }}
                        >
                          {res.Name}
                          <DisplayIcon
                            value={res.Name}
                            height={20}
                            width={25}
                            color={"#ffffff"}
                          />
                        </NavLink>
                      </li>
                    ))}
              </MenuSubList>
            </li>
          );
        }
      })()}
    </MenuSubList>
  );
}
