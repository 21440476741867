import React, {useContext, useMemo} from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { GlobalContext } from "../../../../context/GlobalContext";
import { postWaitAction } from "../../WidgetActions";
import { AOO_API_HOST } from "../../../../env";
import ColorChange from "./ColorChange";
export default function ActiveStatusDialog({
  option,
  setStatusOpen,
  statusOpen,
  setEvent,
  setLog,
  setPostData,
  setPosts,
  setReset,
  closeConnection,
  setMachineId,
  initialPostData,
}) {
  const { machine, setEventOption } = useContext(GlobalContext);

  const handleClose = () => {
    setStatusOpen(false);
  };


  const MachineColorChange = useMemo(() => {
    const c3 = machine?.IsUrgent ? machine?.EventState + "-U" : machine?.EventState;
    const c2 = option.isUrgent ? option.color + "-U" : option.color;
    return c3 + " " + c2;
  }, [machine, option]);

  const setEventStatus = () => {
    const response = postWaitAction(
      AOO_API_HOST + `/aoo-api/Productions/post-status-urgency`,
      {
        MachineId: machine.Id,
        Status: option.color,
        IsUrgent: option.isUrgent,
      }
    );
  };

  const closeLogActions = () => {
    setEvent(false);
    setLog(false);
    setStatusOpen(false);
    setEventOption((prevOption) => ({
      ...prevOption,
      color: null, // Set the 'color' property to null
    }));

    setPostData(initialPostData);
    setPosts([]);
    setReset(true);
    closeConnection();
    setMachineId("");
  };

  return (
    <Dialog
      open={statusOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" style={{ background: "gray" }}>
        You are exiting the color change log, choose your outcome:"
      </DialogTitle>
      <DialogContent style={{ background: "gray" }}>
        <DialogContentText id="alert-dialog-description">
         <ColorChange sideSize={24} colorChangeString={MachineColorChange}/>
        </DialogContentText>
      </DialogContent>

      <ButtonGroup variant="contained" aria-label="Basic button group">
        <Button
          style={{ background: "#5d6165", borderColor: "#5d6165" }}
          onClick={() => {
            closeLogActions()
          }}
        >
          Exit Log, no change of color
        </Button>
        <Button
          style={{ background: "#5d6165", borderColor: "#5d6165" }}
          onClick={() => {
            setEventStatus();
            closeLogActions()
          }}
        >
          Change color without log entry
        </Button>
        <Button
          style={{ background: "#5d6165", borderColor: "#5d6165" }}
          onClick={() => {
            setStatusOpen(false);
          }}
        >
          Back (Stay in the Log)
        </Button>
      </ButtonGroup>
    </Dialog>
  );
}
