import styled from "styled-components";

export const CloseButton = styled.a`
  position: absolute;
  padding: 1.5px 7px;
  right: 0px;
  margin-top: -25px;
  border-radius: 50%;
  color: rgb(69 77 81 / 65%);
  cursor: pointer;
  font-size: 31px;
  top: 23px;
  height: 100%;
`;
export const Title = styled.div`
  font-size: 26px;
  color: #3e4146;
  line-height: 130%;
  letter-spacing: 0.07rem;
  margin-bottom: 5px;
  margin-bottom: 10px;
  span {
    color: orange;
  }

  @media (max-width: 560px) {
    font-size: 18px;
  }
  @media (max-width: 480px) {
    font-size: 15px;
  }
  @media (max-width: 360px) {
    font-size: 13px;
  }
`;
export const MainInput = styled.input`
  display: none;
`;

export const Form = styled.form`
  display: block;
  clear: both;
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
`;

export const Label = styled.label`
  width: 100%;
  padding: 2rem 1.5rem;
  text-align: center;
  background: #fff;
  border-radius: 7px;
  border: 3px solid white;
  transition: all 0.2s ease;
  user-select: none;
  height: 100%;
  &:hover {
    border-color: orange;
    border: 3px solid orange;
    box-shadow: inset 0 0 0 6px #eee;
  }

  .file-image {
    width: auto;
    height: 150px;
    padding: 5px;
  }
`;

export const GeneralLabel = styled.label`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 15px 20px;
  text-align: center;
  background: #1a1d21;
  border-radius: 7px;
  border: 1px solid #80808026;
  transition: all 0.2s ease;
  user-select: none;
  @media (max-width: 748px) {
    padding: 0;
    border: none;
    color: white;
    background: none;
  }
`;

export const EvenDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
`;

export const General = styled.div`
  width: 100%;
  padding: 2rem 1.5rem;
  text-align: center;
  background: #1a1d21;
  border-radius: 7px;
  border: 1px solid #80808026;
  transition: all 0.2s ease;
  user-select: none;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ContainerDiv = styled.div`
  transition: 0.2s;
  margin-left: 20px;
  width: 100%;
  height: 100%;
  // border: 1px solid #eee;

  &:hover {
    border: 3px solid orange;
  }
  @media (max-width: 768px) {
    margin-left: 0px;
    &:hover {
      border: none;
    }
  }
`;

export const ImageContainer = styled.div`
  background: url(${(props) => props.background});
  background-size: cover;
  background-position: center;
  position: relative;
  width: 150px;
  height: 150px;
  margin: 0 10px;

  &.cover {
    border: 5px solid orange;
  }
  &.cover:before {
    content: "✓";
    position: absolute;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: orange;
    text-align: center;
    line-height: 25px;
    color: white;
    left: -13px;
    top: -10px;
  }
`;

export const FileImage = styled.img`
  width: auto;
  height: 150px;
  border: 5px solid orange;
`;

export const Btn = styled.span`
  display: inline-block;
  clear: both;
  font-family: inherit;
  font-weight: 700;
  font-size: 14px;
  text-decoration: none;
  text-transform: initial;
  border: none;
  border-radius: 0.2rem;
  outline: none;
  padding: 0 1rem;
  height: 36px;
  line-height: 36px;
  color: #fff;
  transition: all 0.2s ease-in-out;
  background: orange;
  border-color: orange;
  cursor: pointer;
  margin-top: 10px;
  @media (max-width: 768px) {
    margin-top: 10px;
    width: 100%;
  }
`;

export const ActionBtn = styled.button`
  display: inline-block;
  margin: 10px;
  width: 100px;
  border: none;
  font-size: 20px;
  border-radius: 0.2rem;
  outline: none;
  padding: 0 1rem;
  height: 40px;
  line-height: 40px;
  color: #fff;
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;
  background-color: ${(props) => props.background};
  border-color: orange;
  cursor: pointer;
`;

export const PostImageContainer = styled.div`
  height: 300px;
`;

export const PostImage = styled.div`
  height: 300px;
`;
