import React, {useCallback, useContext} from "react";
import { UrgencyOption, postWaitAction } from "../WidgetActions";
import { GlobalContext } from "../../../context/GlobalContext";
import { LogContext } from "../../../context/LogContext";

import { AOO_API_HOST } from "../../../env";
const EventMenuItem = ({
  setsubStatusRoot,
  setEventOption
}) => {
  const { globalStatus,setLog } = useContext(GlobalContext);
  const options = UrgencyOption(globalStatus);
  return (
    <li className={"menu-item"}>
      Change event status
      <ul
        className={"menu-sub-list status"}
        ref={(ref) => {
          setsubStatusRoot(ref);
        }}
      >
        {options.map((option, key) => (
          <li
            key={key}
            className={"menu-button"}
            onClick={() => {
              setLog(true);
              setEventOption(option);
            }}
          >
            {option.Title}
          </li>
        ))}
      </ul>
    </li>
  );
};

export default EventMenuItem;
