import React, { useEffect, useState } from "react";
import axios from "axios";

import { NavLink, MenuSubList } from "../MobileOptionsStyles";
import DisplayIcon from "../../Widgets/FailureIcon";
import { AOO_API_HOST } from "../../../env";
import { postAction } from "../../Widgets/WidgetActions";
export default function Reason({ event }) {
  const [reasonlist, setReasonlist] = useState([]);
  useEffect(() => {
    const url = AOO_API_HOST + "/aoo-api/Productions/get-failure-reasons";
    axios.get(url).then((response) => setReasonlist(response.data));
  }, []);
  return (
    <MenuSubList>
      {reasonlist === undefined
        ? null
        : reasonlist.map((res, key) => (
            <li key={res.Id}>
              <NavLink
                onClick={() => {
                  postAction(
                    AOO_API_HOST + `/aoo-api/Productions/post-failure-reason`,
                    { EventId: event, ReasonId: res.Id }
                  );
                }}
              >
                {res.Name}
                <DisplayIcon
                  value={res.Name}
                  height={20}
                  width={25}
                  color={"#ffffff"}
                />
              </NavLink>
            </li>
          ))}
    </MenuSubList>
  );
}
