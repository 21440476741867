import React, { useContext } from "react";
import Avatar from "@mui/material/Avatar";
import { LogContext } from "../../../../context/LogContext";

function stringToColor(string: string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}

function stringAvatar(name: string) {
  const fullNameSplit = name.split("");
  const userName = fullNameSplit?.[0] ?? "";
  const userSurname = fullNameSplit?.[1] ?? "";
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${userName + userSurname}`,
  };
}

export default function BackgroundLetterAvatars({ fullName }) {
  return (
    
    <Avatar
      {...stringAvatar(fullName)}
  
      style={{ cursor: "pointer" }}
    />
  );
}
