import React from "react"; // Import React

import { IExcludedMachines } from "../../../global.typing";

interface WidgetProps {
  data: IExcludedMachines; 
  onevent: OneventFunction;
}

type OneventFunction = (e: React.MouseEvent, machine: string) => void;
function Widget({ data, onevent }: WidgetProps) {
  return (
    <div
      className="resItem-border"
      style={{ width: "100%" }}
      onClick={(event) => onevent(event, data.Id)}
    >
      <div className={"resExclItem"}>
        <div className="resExclItem-top">
          <div className="resExclItem-machineCode">{data.MachineCode}</div>
          <p
            className={
              data.MachineName.length < 40
                ? "resItem-machineName"
                : "resItem-machineName resItem-machineName-small"
            }
          >
            {data.MachineName}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Widget;
