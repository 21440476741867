import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Title, NavLink, Content, Confirm } from "../NavbarStyles";
import { IExcludedMachines } from "../../../global.typing";
import SearchBar from "../Components/SearchBar";
import { AOO_API_HOST } from "../../../env";
import Widget from "./AvailableMachine";
import _ from "lodash";

function ManageAvailability() {
  const navigate = useNavigate();
  const [machine, setMachine] = useState([]);
  const [select, setSelect] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [fetched, setFetched] = useState<Array<IExcludedMachines>>([]);
  let handleClick = (e: React.MouseEvent, machine: string) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    e.currentTarget.classList.toggle("machine-active");
    selectSingle(machine);
  };
  const selectSingle = (item) => {
    let machineIds = Object.assign([], machine);

    if (_.findIndex(machineIds, { id: item.id }) === -1) {
      machineIds.push(item);
    } else {
      machineIds.splice(_.findIndex(machineIds, { id: item.id }), 1);
    }
    if (!select) {
      setSelect(false);
    }
    setMachine(machineIds);
  };

  const fetchData = async (url) => {
    try {
      const response = await axios.get(AOO_API_HOST + url);
      setFetched(response.data);
      setSearchResults(response.data);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData("/aoo-api/Productions/GetExcludedMachines?isAvailable=true");
  }, []);

  let url = AOO_API_HOST + `/aoo-api/Productions/AddExcludedMachine`;

  const fetchAverageData = async (url, postData) => {
    try {
      await axios.post(url, postData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    let active = document.querySelectorAll(".machine-active");
    active.forEach((element) => {
      element.classList.remove("machine-active");
    });


    fetchData("/aoo-api/Productions/GetExcludedMachines?isAvailable=true");
  };

  return (
    <>
      <NavLink
        onClick={() => {
          navigate("/main/availability");
        }}
        style={{ marginRight: 30 }}
      >
        Back to excluded
      </NavLink>

      <Title>Included machines</Title>
      <SearchBar machines={fetched} setSearchResults={setSearchResults} />

      <Content
        style={{
          height: 56 + "vh",
          width: "100%",
          paddingLeft: "30px",
          paddingRight: "30px",
        }}
      >
        {searchResults?.map((item: any, index: number) => (
          <Widget data={item} key={item.Id} onevent={handleClick} />
        ))}
      </Content>
      <div style={{ width: "100%" }}>
        <Confirm onClick={(event) => fetchAverageData(url, machine)}>
          Ok
        </Confirm>
      </div>
    </>
  );
}

export default ManageAvailability;
