import styled from "styled-components";
export const StyledPostImage = styled.img`
        
        height: 200px;
        margin: 10px 10px 0;
        border: 1px solid #bbbbbb;
        border-radius: 5px;

        @media (max-width: 768px) {
            height: 100px;
        }
`

export const StyledPostVideo = styled.video`
    height: 200px;
    margin: 10px 10px 0;
    border: 1px solid #bbbbbb;
    border-radius: 5px;

    @media (max-width: 768px) {
        height: 100px;
    }
`

export const StyledVideoContainer = styled.div`
    cursor: pointer;
    position: relative;
    display: inline-block;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const PlayButton = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 50px;
    color: white;
    cursor: pointer;
    pointer-events: none;

    padding-left: 3px;
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px; 
    border: 4px solid white; /* Add gray border */
    border-radius: 50%; 
    background-color: rgba(0, 0, 0, 0.5);
`;
