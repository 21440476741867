import React from "react";


function Done() {
    return (
        <svg
        className="check"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 2C2.89543 2 2 2.89543 2 4V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20V4C22 2.89543 21.1046 2 20 2H4ZM18.8384 7.54499C19.1394 7.08193 19.008 6.46255 18.545 6.16156C18.0819 5.86057 17.4625 5.99195 17.1616 6.45501L11.368 15.3682L7.23994 10.8273C6.86843 10.4187 6.23598 10.3886 5.82733 10.7601C5.41867 11.1316 5.38855 11.764 5.76006 12.1727L10.7601 17.6727C10.9686 17.902 11.2717 18.0217 11.5806 17.9967C11.8896 17.9717 12.1695 17.8049 12.3384 17.545L18.8384 7.54499Z"
          fill="#43A747"
        />
      </svg>
        
    )
}

export default Done;