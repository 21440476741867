import React from "react";
import { useCookies } from "react-cookie";

import { Filter, State } from "./Machine/MachineStyles";
function FilterState() {
  const [cookies, setCookie] = useCookies();

  let activeFilterState = () => {
    var cookie;
    if (cookies.preferredState != null) {
      cookie = document.querySelector(
        `.c-${cookies.preferredState.toLowerCase()}`
      )?.parentElement;
      cookie?.classList.add("state-active");
    } else {
      cookie = document.querySelector(`.state-all`);
      cookie?.classList.add("state-active");
    }
  };
  activeFilterState();
  function filterState(e, state) {
    if (document.querySelector(".state-active")) {
      document.querySelector(".state-active").classList.remove("state-active");
    }

    switch (state) {
      case "Y":
        setCookie("preferredState", "Y");
        break;
      case "R":
        setCookie("preferredState", "R");
        break;
      case "B":
        setCookie("preferredState", "B");
        break;
      default:
        setCookie("preferredState", "A");
        break;
    }

    e.currentTarget.classList.toggle("state-active");
  }
  return (
    <Filter>
      <State onClick={(e) => filterState(e, "Y")}>
        Yellow <span className={`circle c-y `}></span>
      </State>
      <State onClick={(e) => filterState(e, "R")}>
        Red <span className={`circle c-r `}></span>
      </State>
      <State onClick={(e) => filterState(e, "B")}>
        Blue <span className={`circle c-b `}></span>
      </State>
      <State
        onClick={(e) => filterState(e, "A")}
        className="state-all"
        style={{ justifyContent: "center" }}
      >
        All
        <span className={`circle c-a `}></span>
      </State>
    </Filter>
  );
}

export default FilterState;
