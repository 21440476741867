import {useCallback} from "react";

const SearchBar = ({ machines, setSearchResults, onSearchChange }) => {

  const handleSubmit = (e) => e.preventDefault();

  const handleSearchChange = useCallback((e) => {
      const inputValue = e.target.value;
      if (onSearchChange) // Notify parent of the input value change
      {
        onSearchChange(inputValue);
      }

      if (!inputValue) {
        return setSearchResults(machines);
      }

      let resultsArray = machines?.filter(
          (machine) =>
              machine.MachineCode?.includes(e.target.value.toLowerCase()) ||
              machine.MachineCode?.includes(e.target.value.toUpperCase()) ||
              machine.MachineName.includes(e.target.value.toLowerCase()) ||
              machine.MachineName.includes(e.target.value.toUpperCase())
      );

      setSearchResults(resultsArray);
  }, [machines, onSearchChange, setSearchResults]);

  return (
    <form className="search" onSubmit={handleSubmit}  >
      <input style={{marginTop:"0" , padding: "18px 33px"}}
        className="search__input"
        type="text"
        id="search"
        placeholder="Search for machine"
        onChange={handleSearchChange}
      />
    </form>
  );
};
export default SearchBar;
