import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;
export const Row = styled.div`
  //   width: 60%;
  height: 60vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  //   @media (max-width: 560px) {
  //     width: 100%;
  //   }
`;
export const Inner = styled.div`
  @media (max-width: 560px) {
    width: 90%;
  }
`;
export const Machine = styled.div`
  margin-bottom: 15px;
  padding: 6px 15px;
  width: 100%;
  position: relative;
  border-radius: 20px;
  border: 3.4px solid #161616;
  position: relative;
  height: 120px;
  margin: 0 50px;
  margin-bottom: 5px;
  @media (max-width: 720px) {
    margin: 0 0px;
    height: 80px;
  }
`;
export const MachineFamily = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  @media (max-width: 720px) {
    padding-bottom: 206px;
  }
`;

export const MachineName = styled.div`
  display: flex;
  border-bottom: 1px solid #161616;
  align-items: center;
  margin-bottom: 5px;
  width: "100%";
  display: "flex";
  font-weight: 600;

  p {
    justify-content: space-between;
    font-size: 30px;
  }
  @media (max-width: 720px) {
    p {
      font-size: 15px;
    }
  }
`;
export const Title = styled.div`
  color: #ffffff;
  padding: 20px 30px;
  font-size: 35px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 720px) {
    padding: 10px;
    font-size: 22px;
  }

  input{ 
    padding 20px 0!Important;
  }

  #switch{
padding : 0;
  }
`;
export const MachineCode = styled.span`
  color: black;
  font-size: 34px;
  border: 1px solid #161616;
  padding: 5px 5px;
  text-align: center;
  margin-right: 10px;
  margin-bottom: 5px;
  min-width: 100px;
  height: 50px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 720px) {
    min-width: 40px;
    height: 25px;
    font-size: 14px;
  }
`;

export const PinMachineIconContainer = styled.span`

  margin-left: auto;
  cursor: pointer;
  transition: all 0.3s ease;

  margin-right: 2px;
  opacity: 0;
  transform: rotate(45deg);

  &:hover {
    opacity: 0.9;
  }
  @media (max-width: 720px) {
    opacity: 0.9;
  }

  ${(props) =>
    props.isPinned
      ? css`
          margin-right: 0px;
          transform: rotate(0);
          opacity: 0.9;
        `
      : css`
      @media (max-width: 720px) {
      }
    `};
`;

export const BottomInfo = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  @media (max-width: 720px) {
    height: 25px;
  }
`;

export const Switch = styled.div`
  color: #ffffff;
  padding: 20px 30px 0 30px;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 360px) {
    padding: 10px;
  }
`;
