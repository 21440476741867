import styled, { css } from "styled-components";

export const Title = styled.div`
  color: #ffffff;
  padding: 20px 30px;
  font-size: 35px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 720px) {
    padding: 10px;
    font-size: 22px;
  }

  input{ 
    padding 20px 0!Important;
  }

  #switch{
    padding : 0;
  }
`;

export const DownloadBtn = styled.button`
  width: 535px;
  height: 56px;
  background: #1a5e9e;
  border: 1px solid #28dfdf;
  border-radius: 9px;
  font-size: 25px;
  text-transform: uppercase;
  font-weight: 600;
  color: #ffffff;
  top: 85%;
  margin: 10px 10px 10px 0px;

  @media (max-width: 720px) {
    width: 300px;
  }
`;

export const ReportInfoForm = styled.div`
  margin-left: 10px;
`;

export const ReportLabel = styled.label`
color: #ffffff;
padding: 20px 30px;
font-size: 28px;
justify-content: space-between;
@media (max-width: 720px) {
  padding: 20px 10px 0px 0px;
  font-size: 16px;
}

input{ 
  padding 20px 0!Important;
}

#switch{
  padding : 10px;
}
`;

export const DatePickerLabel = styled.label`
color: #ffffff;
padding: 20px 30px;
font-size: 28px;
width: 220px;
justify-content: space-between;
@media (max-width: 720px) {
  padding: 20px 10px 0px 0px;
  font-size: 16px;
  width: 100px;
  margin-bottom: 4px;
}

input{ 
  padding 20px 0!Important;
}

#switch{
  padding : 10px;
}
`;

export const DatePickerDiv = styled.div`
display: flex;
align-items: center;

@media (max-width: 720px) {
    align-items: flex-end;
  }
`;

export const DatePickerBlock = styled.div`
margin-bottom: 10px;

@media (max-width: 720px) {
  }
`;

export const CheckboxInput = styled.input`
width: 20px;
height: 20px;
margin-right: 6px;

@media (max-width: 720px) {
    width: 15px;
    height: 15px;
    margin-right: 4px;
  }
`;

export const ButtonsDiv = styled.div`
padding: 10px 30px;
@media (max-width: 720px) {
    margin-top: 20px;
    padding: 0px 30px 0px 0px;
  }
`;

export const ToggleSwitchDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 30px;
  @media (max-width: 720px) {
    padding: 20px 30px 0px 0px;
  }
`;

export const ToggleLabel = styled.label`
  color: #ffffff;
  font-size: 28px;
  margin-right: 15px;
  @media (max-width: 720px) {
    font-size: 16px;
  }
`;

//40, 223, 223 / #28dfdf; / #1a5e9e;
export const SwitchInput = styled.input`
  width: 50px;
  height: 25px;
  background-color: #fff;
  border-radius: 12px;
  cursor: pointer;
  position: relative;
  appearance: none;
  outline: none;
  transition: background-color 0.3s ease;

  &:checked {
    background-color: #1a5e9e;
  }

  &:before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #1a5e9e;
    border-radius: 50%;
    top: 50%;
    left: 4px;
    transform: translateY(-50%);
    transition: left 0.3s ease;
  }

  &:checked:before {
    left: calc(100% - 24px);
    background-color: #fff;
  }
`;
