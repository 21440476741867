import React, { useMemo, useEffect, useContext } from "react";
import { StyledPostImage, StyledPostVideo, StyledVideoContainer, PlayButton } from "./PostItemStyles/PostItemStyled";
import { PostImage } from "../../Log/log.typing";
import FileType, { createFileUrl, downloadFileByUrl, fileTypeEnum } from "../../Log/api/FileTypeEnum";
import { Document, Page, pdfjs } from "react-pdf";
import { isMobile } from "react-device-detect";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { LogContext } from "../../../../context/LogContext";

type PostImageProps = {
    postImage: PostImage,
    onImageClick: (postImage: PostImage) => void,
    onFileTypeChange?: (fileType: FileType) => void
}

const PostImageItem: React.FC<PostImageProps> = ({ postImage, onImageClick, onFileTypeChange }): React.ReactElement => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

    const { getImageThumbnailUrl, getVideoStreamUrl } = useContext(LogContext);

    const fileType = useMemo(() => {
        return fileTypeEnum(postImage.Content);
    }, [postImage]);

    const postImageData = useMemo(() => {
        if (postImage.ImageData) {
            return createFileUrl(postImage.ImageData, postImage.Content)
        }
        else {
            if (fileType === FileType.Video) {
                return getVideoStreamUrl(postImage.Id)
            }
            else {
                return getImageThumbnailUrl(postImage.Id)
            }
        }
    }, [postImage.Content, postImage.ImageData, fileType]);

    useEffect(() => {
        if (onFileTypeChange) {
            onFileTypeChange(fileType);
        }
    }, [fileType, onFileTypeChange]);

    return (
        <>
            {fileType === FileType.Image && (
                <StyledPostImage onClick={() => onImageClick(postImage)} src={postImageData} />
            )}
            {fileType === FileType.Video && (
                <StyledVideoContainer onClick={() => onImageClick(postImage)}>
                    <StyledPostVideo>
                        <source src={postImageData} type={postImage.Content} />
                        Your browser does not support the video tag.
                    </StyledPostVideo>
                    <PlayButton>▶</PlayButton>
                </StyledVideoContainer>
            )}
            {fileType === FileType.Pdf && (
                <div style={{ cursor: "pointer" }}>
                    <Document className={'mypdf-container-pages'} onClick={() => onImageClick(postImage)} file={postImageData}>
                        {<Page scale={isMobile ? 0.3 : 0.6} pageNumber={1} />}
                    </Document>
                </div>
            )}
            {fileType === FileType.Other && (
                <div style={{ width: '50px', cursor: 'pointer' }} onClick={() => downloadFileByUrl(postImageData, postImage.FileName)}>
                    <InsertDriveFileIcon style={{ color: 'white', width: '100%', height: '100%' }} />
                </div>
            )}
        </>
    )
}

export default PostImageItem;