import React from "react";
import { Pop, Title } from "./popupstyle";
import { AOO_API_HOST } from "../../../env";
import { postAction } from "../WidgetActions";
import DisplayIcon from "../FailureIcon";

function PopUp({ event, machine, setAlert, reason }) {
  function handleClick() {
    setAlert(false);
  }
  return (
    <Pop>
      <Title>Please assign a reason to machine :</Title>

      <Title>
        <span>[{machine.MachineCode}]</span> {machine.Name}
      </Title>
      <Title>
        Current reason: <span>[{machine.MachineReason}]</span>
      </Title>

      <ul className={"menu-sub-list reason"}>
        {reason.map((reason, key) => (
          <li
            className={"menu-button"}
            key={[reason.Id]}
            onClick={() => {
              postAction(
                AOO_API_HOST + `/aoo-api/Productions/post-failure-reason`,
                {
                  EventId: event.Id,
                  ReasonId: reason.Id,
                  Status: event.EventState,
                }
              );
              handleClick();
            }}
          >
            {reason.Name}
            <DisplayIcon
              value={reason.Name}
              height={30}
              width={35}
              color={"#363636"}
            />
          </li>
        ))}
      </ul>
    </Pop>
  );
}

export default PopUp;
