import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import "swiper/css";
import LeftCancelled from "../InspectionFlags/icons/LeftCancell";
import RightCompleted from "../InspectionFlags/icons/RightComplete";
import { IExcludedMachines } from "../../../global.typing";
import {
  General,
  ListOfMachines,
  Confirm,
  ButtonOk,
  ButtonCancel,
  TextConfirm,
  WordOk,
  WordCancel,
} from "../InspectionFlags/InspectionFlags_styles";
import axios from "axios";
import { AOO_API_HOST } from "../../../env";

function Swipeable({
  machine,
  updateList,
}: {
  machine: IExcludedMachines;
  updateList: any;
}) {
  const [swiper, setSwiper] = useState(null);

  const swipe = (swiperCore) => {
    const { activeIndex } = swiperCore;
    if (activeIndex === 1) return;

    const machineId = swiperCore.el.id;

    document.querySelectorAll(".swiper").forEach((el) => {
      if (el.id !== machineId) el.classList.add("hidden");
    });

    let t = document.querySelector("#confirm_" + machineId);
    if (t) t.setAttribute("style", "display:block;");
  };

  async function removeExcludedMachine(machineId) {
    try {
      const response = await axios.post(
        AOO_API_HOST +
          "/aoo-api/Productions/RemoveExcludedMachine?id=" +
          machineId
      );

      swiper.slideTo(1, 50);
      document.querySelectorAll(".swiper").forEach((el) => {
        el.classList.remove("hidden");
      });
      await updateList();
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const okConfirm = (e) => {
    const machineId = e.target.id.substring(e.target.id.lastIndexOf("_") + 1);

    document
      .querySelector("#confirm_" + machineId)
      .setAttribute("style", "display:none;");

    removeExcludedMachine(machineId);
    document.querySelectorAll(".swiper").forEach((el) => {
      el.classList.remove("hidden");
    });
  };

  const cancelConfirm = (e) => {
    document.querySelectorAll(".swiper").forEach((el) => {
      el.classList.remove("hidden");
    });

    const machineId = e.target.id.substring(e.target.id.lastIndexOf("_") + 1);
    let t = document.querySelector("#confirm_" + machineId);
    if (t) {
      document
        .querySelector("#confirm_" + machineId)
        .setAttribute("style", "display:none;");
      swiper.slideTo(1, 50);

      document.querySelectorAll(".swiper").forEach((el) => {
        if (el.id !== machineId) el.classList.remove("hidden");
      });
    }
  };

  return (
    <div>
      <General>
        <ListOfMachines>
          <Swiper
            onSwiper={setSwiper}
            id={machine.Id}
            modules={[Navigation]}
            navigation={true}
            speed={500}
            slidesPerView={"auto"}
            initialSlide={1}
            spaceBetween={25}
            centeredSlides={true}
            onSlideChange={swipe}
          >
            <SwiperSlide>
              <div className="completeAc">
                <span className="action"></span>
                <RightCompleted />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="resItem-border" style={{ width: "100%" }}>
                <div className={"resExclItem"} style={{ width: "500px" }}>
                  <div className="resExclItem-top">
                    <div className="resExclItem-machineCode">
                      {machine.MachineCode}
                    </div>
                    <p
                      className={
                        machine.MachineName.length < 40
                          ? "resItem-machineName"
                          : "resItem-machineName resItem-machineName-small"
                      }
                    >
                      {machine.MachineName}
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="cancelAc">
                <LeftCancelled />
                <span className="action"></span>
              </div>
            </SwiperSlide>
          </Swiper>
        </ListOfMachines>
      </General>
      <Confirm id={"confirm_" + machine.Id}>
        <TextConfirm>
          Click <WordOk>OK</WordOk> to confirm your selection, or Press
          <WordCancel>Cancel</WordCancel> to correct it.
        </TextConfirm>
        <ButtonOk onClick={okConfirm} id={"ok_" + machine.Id}>
          Ok
        </ButtonOk>
        <ButtonCancel onClick={cancelConfirm} id={"cancel_" + machine.Id}>
          Cancel
        </ButtonCancel>
      </Confirm>
    </div>
  );
}

export default Swipeable;
